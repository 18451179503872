/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Factoid = ({
  className,
  overlapGroupClassName,
  divClassName,
  rectangleClassName,
  elementClassName,
  text = "8 ЛЕТ",
  text1 = "опыта",
  star = "/img/star-11.svg",
}) => {
  return (
    <div className={`factoid ${className}`}>
      <div className={`overlap-group-2 ${overlapGroupClassName}`}>
        <div className={`text-wrapper-5 ${divClassName}`}>{text1}</div>
        <div className={`rectangle-3 ${rectangleClassName}`} />
        <img className="star" alt="Star" src={star} />
        <div className={`element-3 ${elementClassName}`}>{text}</div>
      </div>
    </div>
  );
};

Factoid.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  star: PropTypes.string,
};
