import React, { useState, useEffect } from "react";
import { useWindowWidth } from "../../breakpoints";
import { Bullet } from "../../components/Bullet";
import { EducationCell } from "../../components/EducationCell";
import { EducationCellWrapper } from "../../components/EducationCellWrapper";
import { Factoid } from "../../components/Factoid";
import { Header } from "../../components/Header";
import { HierarchySecondary } from "../../components/HierarchySecondary";
import { HierarchyTertiary } from "../../components/HierarchyTertiary";
import { MenuIcon } from "../../components/MenuIcon";
import "./style.css";
import { Key } from "../../../static/img/Key";
import { AlchemySchemeOpen } from "../../../static/img/AlchemySchemeOpen";
import { AlchemySchemeClosed } from "../../../static/img/AlchemySchemeClosed";
import { GoldenRatio } from "../../../static/img/GoldenRatio";

import ReactPlayer from 'react-player'
import { GoldenRatio_960 } from "../../../static/img/GoldenRatio_960.jsx";
import { GoldenRatio_768 } from "../../../static/img/GoldenRatio_768.jsx";
import { GoldenRatio_480 } from "../../../static/img/GoldenRatio_480.jsx";
import { Key_960 } from "../../../static/img/Key_960.jsx";
import { Key_768 } from "../../../static/img/Key_768.jsx";
import { Key_480 } from "../../../static/img/Key_480.jsx";
import { Key_360 } from "../../../static/img/Key_360.jsx";

export const Element = () => {
  const screenWidth = useWindowWidth();
  const [isVisible, setIsVisible] = useState(true);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isAlchemyOpen, setIsAlchemyOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const toggleDetailsDropdown = () => setIsDetailsOpen(!isDetailsOpen);
  const toggleAlchemyDropdown = () => setIsAlchemyOpen(!isAlchemyOpen);
  const toggleMenuDropdown = () => setIsMenuOpen(!isMenuOpen)


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // Log the values to debug
      console.log('ScrollTop:', scrollTop);
      console.log('WindowHeight:', windowHeight);
      console.log('DocumentHeight:', documentHeight);

      // Check if the user is within 10 pixels of the bottom of the page
      const isNearBottom = scrollTop + windowHeight >= documentHeight - 1;
      setIsVisible(!isNearBottom);
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    if (sectionId === 'contacts') {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
        block: 'start'
      });
    } else if (sectionId === 'about') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
        block: 'start'
      });
    } else if (sectionId === 'education') {
      window.scrollTo({
        top: 2050,
        behavior: 'smooth',
        block: 'start'
      });
    } else if (sectionId === 'alchemy') {
      window.scrollTo({
        top: 5735,
        behavior: 'smooth',
        block: 'start'
      });
    } else if (sectionId === 'materials') {
      window.scrollTo({
        top: 8235,
        behavior: 'smooth',
        block: 'start'
      });
    } else if (sectionId === 'formats') {
      window.scrollTo({
        top: 7030,
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  return (
    <div className="element">
      {screenWidth >= 1280 && <Header
        style={{
          backgroundColor: '#ffffff0d',
          left: '50%',
          transform: 'translateX(-50%)',
          position: 'fixed',
          top: 0,
          width: '1280px',
          zIndex: 1000,
          display: isVisible ? 'flex' : 'none',
        }}
        onTabClick={scrollToSection}
        />}
      <div
        className="div-2"
        style={{
          height:
            screenWidth < 480 && isDetailsOpen && isAlchemyOpen ? "8689px"
            : screenWidth < 480 && !isDetailsOpen && isAlchemyOpen ? "7489px"
            : screenWidth < 480 && isDetailsOpen && !isAlchemyOpen ? "7740px"
            : screenWidth < 480 && !isDetailsOpen && !isAlchemyOpen ? "6600px"
              : screenWidth >= 480 && screenWidth < 768
              ? (isDetailsOpen && isAlchemyOpen ? "9681px" : !isDetailsOpen && isAlchemyOpen ? "8435px" : isDetailsOpen && !isAlchemyOpen ? "8835px" : "7575px")
              : screenWidth >= 768 && screenWidth < 960
              ? (isDetailsOpen && isAlchemyOpen ? "8541px" : !isDetailsOpen && isAlchemyOpen ? "7020px" : isDetailsOpen && !isAlchemyOpen ? "7915px" : "6380px")
              : screenWidth >= 960 && screenWidth < 1280
              ? (isDetailsOpen && isAlchemyOpen ? "9970px" : !isDetailsOpen && isAlchemyOpen ? "8215px" : isDetailsOpen && !isAlchemyOpen ? "9255px" : "7530px")
              : screenWidth >= 1280
              ? (isDetailsOpen && isAlchemyOpen ? "9837px" : !isDetailsOpen && isAlchemyOpen ? "8150px" : isDetailsOpen && !isAlchemyOpen ? "9305px" : "7500px")
              : "9837px",
          width:
            screenWidth < 480
              ? "360px"
              : screenWidth >= 480 && screenWidth < 768
              ? "480px"
              : screenWidth >= 768 && screenWidth < 960
              ? "768px"
              : screenWidth >= 960 && screenWidth < 1280
              ? "960px"
              : screenWidth >= 1280
              ? "1280px"
              : undefined,
        }}
      >
        <div
          className="overlap"
          style={{
            height:
              screenWidth >= 480 && screenWidth < 768
                ? "865px"
                : screenWidth >= 960 && screenWidth < 1280
                ? "611px"
                : screenWidth >= 1280
                ? "1223px"
                : screenWidth >= 768 && screenWidth < 960
                ? "466px"
                : screenWidth < 480
                ? "631px"
                : undefined,
            left:
              (screenWidth >= 480 && screenWidth < 768) ||
              (screenWidth >= 768 && screenWidth < 960) ||
              (screenWidth >= 960 && screenWidth < 1280)
                ? "20px"
                : screenWidth >= 1280
                ? "50px"
                : screenWidth < 480
                ? "12px"
                : undefined,
            top:
              screenWidth >= 480 && screenWidth < 768
                ? "-32px"
                : screenWidth >= 960 && screenWidth < 1280
                ? "-94px"
                : screenWidth >= 1280
                ? (isDetailsOpen ? "4506px" : "2726px")
                : screenWidth >= 768 && screenWidth < 960
                ? "-35px"
                : screenWidth < 480
                ? "-8px"
                : undefined,
            width:
              screenWidth >= 480 && screenWidth < 768
                ? "442px"
                : screenWidth >= 960 && screenWidth < 1280
                ? "920px"
                : screenWidth >= 1280
                ? "1230px"
                : screenWidth >= 768 && screenWidth < 960
                ? "728px"
                : screenWidth < 480
                ? "337px"
                : undefined,
          }}
        >
          {screenWidth >= 1280 && <img className="vector" alt="Vector" src="/img/vector-25.svg" />}

          <div
            className="main"
            style={{
              height:
                screenWidth >= 960 && screenWidth < 1280
                  ? "611px"
                  : screenWidth >= 768 && screenWidth < 960
                  ? "466px"
                  : screenWidth >= 1280
                  ? "184px"
                  : screenWidth >= 480 && screenWidth < 768
                  ? "280px"
                  : screenWidth < 480
                  ? "145px"
                  : undefined,
              top:
                (screenWidth >= 480 && screenWidth < 768) ||
                (screenWidth >= 768 && screenWidth < 960) ||
                (screenWidth >= 960 && screenWidth < 1280) ||
                screenWidth < 480
                  ? "0"
                  : screenWidth >= 1280
                  ? "692px"
                  : undefined,
              width:
                screenWidth >= 960 && screenWidth < 1280
                  ? "889px"
                  : screenWidth >= 768 && screenWidth < 960
                  ? "714px"
                  : screenWidth >= 1280
                  ? "180px"
                  : screenWidth >= 480 && screenWidth < 768
                  ? "280px"
                  : screenWidth < 480
                  ? "145px"
                  : undefined,
            }}
          >
            {(screenWidth >= 1280 ||
              (screenWidth >= 768 && screenWidth < 960) ||
              (screenWidth >= 960 && screenWidth < 1280)) && (
              <div
                className="overlap-2"
                style={{
                  height:
                    screenWidth >= 960 && screenWidth < 1280
                      ? "611px"
                      : screenWidth >= 768 && screenWidth < 960
                      ? "466px"
                      : screenWidth >= 1280
                      ? "184px"
                      : undefined,
                  left: screenWidth >= 1280 ? "12px" : undefined,
                  width:
                    screenWidth >= 960 && screenWidth < 1280
                      ? "887px"
                      : screenWidth >= 768 && screenWidth < 960
                      ? "712px"
                      : screenWidth >= 1280
                      ? "156px"
                      : undefined,
                }}
              >
                {((screenWidth >= 768 && screenWidth < 960) || (screenWidth >= 960 && screenWidth < 1280)) && (
                  <div
                    className="overlap-3"
                    style={{
                      height:
                        screenWidth >= 768 && screenWidth < 960
                          ? "466px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "611px"
                          : undefined,
                      width:
                        screenWidth >= 768 && screenWidth < 960
                          ? "712px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "887px"
                          : undefined,
                    }}
                  >
                    <div
                      className="pattern"
                      style={{
                        height:
                          screenWidth >= 960 && screenWidth < 1280
                            ? "388px"
                            : screenWidth >= 768 && screenWidth < 960
                            ? "265px"
                            : undefined,
                        left:
                          screenWidth >= 960 && screenWidth < 1280
                            ? "417px"
                            : screenWidth >= 768 && screenWidth < 960
                            ? "375px"
                            : undefined,
                        width:
                          screenWidth >= 960 && screenWidth < 1280
                            ? "388px"
                            : screenWidth >= 768 && screenWidth < 960
                            ? "265px"
                            : undefined,
                      }}
                    >
                      <img
                        className="union-2"
                        style={{
                          height:
                            screenWidth >= 960 && screenWidth < 1280
                              ? "294px"
                              : screenWidth >= 768 && screenWidth < 960
                              ? "230px"
                              : undefined,
                          top:
                            screenWidth >= 960 && screenWidth < 1280
                              ? "94px"
                              : screenWidth >= 768 && screenWidth < 960
                              ? "35px"
                              : undefined,
                          width:
                            screenWidth >= 960 && screenWidth < 1280
                              ? "388px"
                              : screenWidth >= 768 && screenWidth < 960
                              ? "265px"
                              : undefined,
                        }}
                        alt="Union"
                        src={
                          screenWidth >= 960 && screenWidth < 1280
                            ? "/img/union-22.svg"
                            : screenWidth >= 768 && screenWidth < 960
                            ? "/img/union-43.svg"
                            : undefined
                        }
                      />
                    </div>
                    {screenWidth >= 960 && screenWidth < 1280 && (
                      <>
                        <div className="frame-13">
                          <div className="frame-14">
                            <img className="star-2" alt="Star" src="/img/star-14.svg" />
                            <div className="text-wrapper-14">психолог, нейрофизиолог</div>
                          </div>
                          <div className="frame-14">
                            <img className="star-2" alt="Star" src="/img/star-14.svg" />
                            <div className="text-wrapper-14">сертифицированный IFS-терапевт</div>
                          </div>
                          <div className="frame-14">
                            <img className="star-2" alt="Star" src="/img/star-14.svg" />
                            <p className="text-wrapper-14">супервизор и ментор для психологов</p>
                          </div>
                          <div className="frame-14">
                            <img className="star-2" alt="Star" src="/img/star-14.svg" />
                            <p className="text-wrapper-14">преподаватель Psychodemia (при Институте психологии РАН)</p>
                          </div>
                          <div className="frame-14">
                            <img className="star-2" alt="Star" src="/img/star-14.svg" />
                            <p className="text-wrapper-14">клиенты из списка Forbes, Yandex, Tinkoff, Facebook</p>
                          </div>
                        </div>
                        <div className="photo">
                          <div className="overlap-4">
                            <div className="group" />
                            <div className="arc">
                              <div className="overlap-group-3">
                                <img className="rectangle-4" alt="Rectangle" src="/img/rectangle-44-6.svg" />
                                <img className="line-6" alt="Line" src="/img/line-9-6.svg" />
                                <img className="star-3" alt="Star" src="/img/star-15-8.svg" />
                                <img className="rectangle-5" alt="Rectangle" src="/img/rectangle-56-6.svg" />
                                <img className="star-4" alt="Star" src="/img/star-15-9.svg" />
                                <img className="star-5" alt="Star" src="/img/star-15-10.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div
                      className="text-wrapper-15"
                      style={{
                        fontSize:
                          screenWidth >= 768 && screenWidth < 960
                            ? "42px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "60px"
                            : undefined,
                        lineHeight:
                          screenWidth >= 768 && screenWidth < 960
                            ? "50.4px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "72.0px"
                            : undefined,
                        top:
                          screenWidth >= 768 && screenWidth < 960
                            ? "65px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "154px"
                            : undefined,
                      }}
                    >
                      КЛАРА ЖИХАРЕВА
                    </div>
                    {screenWidth >= 768 && screenWidth < 960 && (
                      <div className="overlap-wrapper">
                        <div className="overlap-5">
                          <div className="group-2" />
                          <div className="overlap-group-wrapper">
                            <div className="overlap-group-4">
                              <img className="rectangle-6" alt="Rectangle" src="/img/rectangle-44-12.svg" />
                              <img className="line-7" alt="Line" src="/img/line-9-12.svg" />
                              <img className="star-6" alt="Star" src="/img/star-15-16.svg" />
                              <img className="rectangle-7" alt="Rectangle" src="/img/rectangle-56-12.svg" />
                              <img className="star-7" alt="Star" src="/img/star-15-17.svg" />
                              <img className="star-8" alt="Star" src="/img/star-15-18.svg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {screenWidth >= 768 && screenWidth < 960 && (
                  <div className="frame-15">
                    <div className="frame-16">
                      <img className="star-9" alt="Star" src="/img/star-14-9.svg" />
                      <div className="text-wrapper-16">психолог, нейрофизиолог</div>
                    </div>
                    <div className="frame-16">
                      <img className="star-9" alt="Star" src="/img/star-14-9.svg" />
                      <div className="text-wrapper-16">сертифицированный IFS-терапевт</div>
                    </div>
                    <div className="frame-14">
                      <img className="star-9" alt="Star" src="/img/star-14-9.svg" />
                      <p className="text-wrapper-16">супервизор и ментор для психологов</p>
                    </div>
                    <div className="frame-14">
                      <img className="star-9" alt="Star" src="/img/star-14-12.svg" />
                      <p className="p">преподаватель Psychodemia (при Институте психологии РАН)</p>
                    </div>
                    <div className="frame-14">
                      <img className="star-9" alt="Star" src="/img/star-14-12.svg" />
                      <p className="p">клиенты из списка Forbes, Yandex, Tinkoff, Facebook</p>
                    </div>
                  </div>
                )}
                <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                  <img
                    className="button"
                    style={{
                      height:
                        screenWidth >= 960 && screenWidth < 1280
                          ? "68px"
                          : screenWidth >= 768 && screenWidth < 960
                          ? "64px"
                          : screenWidth >= 1280
                          ? "0px"
                          : undefined,
                      top:
                        screenWidth >= 960 && screenWidth < 1280
                          ? "540px"
                          : screenWidth >= 768 && screenWidth < 960
                          ? "401px"
                          : screenWidth >= 1280
                          ? "0"
                          : undefined,
                      width:
                        screenWidth >= 960 && screenWidth < 1280
                          ? "412px"
                          : screenWidth >= 768 && screenWidth < 960
                          ? "365px"
                          : screenWidth >= 1280
                          ? "156px"
                          : undefined,
                    }}
                    alt="Button"
                    src={
                      screenWidth >= 960 && screenWidth < 1280
                        ? "/img/button-10.svg"
                        : screenWidth >= 768 && screenWidth < 960
                        ? "/img/button-20.svg"
                        : screenWidth >= 1280
                        ? "/img/polygon-3.svg"
                        : undefined
                    }
                  />
                </a>
                {screenWidth >= 1280 && (
                  <>
                    <svg width="180" height="184" viewBox="0 0 180 184" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.9152 137.227L89.9999 1.01519L167.085 137.227H12.9152Z" stroke="white"/>
                      <path d="M52.0112 114.129L90.0006 47.2062L127.99 114.129H52.0112Z" stroke="white"/>
                      <path d="M167.085 46.41L90.0001 182.622L12.9153 46.41L167.085 46.41Z" stroke="white"/>
                      <path d="M90 1.63672L90.1973 91.6803" stroke="white"/>
                      <path d="M90.1973 91.6787L167.116 137.253" stroke="white"/>
                      <path d="M90.1973 91.6787L13.2788 137.253" stroke="white"/>
                    </svg>
                  </>
                )}
              </div>
            )}

            {((screenWidth >= 480 && screenWidth < 768) || screenWidth < 480) && (
              <img
                className="union-3"
                style={{
                  height: screenWidth >= 480 && screenWidth < 768 ? "248px" : screenWidth < 480 ? "137px" : undefined,
                  top: screenWidth >= 480 && screenWidth < 768 ? "32px" : screenWidth < 480 ? "8px" : undefined,
                  width: screenWidth >= 480 && screenWidth < 768 ? "280px" : screenWidth < 480 ? "145px" : undefined,
                }}
                alt="Union"
                src={
                  screenWidth >= 480 && screenWidth < 768
                    ? "/img/union-33.svg"
                    : screenWidth < 480
                    ? "/img/union-13.svg"
                    : undefined
                }
              />
            )}
          </div>
          {(screenWidth >= 1280 || (screenWidth >= 480 && screenWidth < 768) || screenWidth < 480) && (
            <div
              className="main-2"
              style={{
                height:
                  screenWidth >= 480 && screenWidth < 768
                    ? "783px"
                    : screenWidth < 480
                    ? "573px"
                    : screenWidth >= 1280
                    ? "1213px"
                    : undefined,
                left:
                  screenWidth >= 480 && screenWidth < 768
                    ? "0"
                    : screenWidth < 480
                    ? "8px"
                    : screenWidth >= 1280
                    ? "100px"
                    : undefined,
                top:
                  screenWidth >= 480 && screenWidth < 768
                    ? "82px"
                    : screenWidth < 480
                    ? "58px"
                    : screenWidth >= 1280
                    ? "10px"
                    : undefined,
                width:
                  screenWidth >= 480 && screenWidth < 768
                    ? "442px"
                    : screenWidth < 480
                    ? "322px"
                    : screenWidth >= 1280
                    ? "1004px"
                    : undefined,
              }}
            >
              {((screenWidth >= 480 && screenWidth < 768) || screenWidth < 480) && (
                <>
                  <div
                    className="text-wrapper-17"
                    style={{
                      fontSize:
                        screenWidth >= 480 && screenWidth < 768 ? "36px" : screenWidth < 480 ? "24px" : undefined,
                      lineHeight:
                        screenWidth >= 480 && screenWidth < 768 ? "43.2px" : screenWidth < 480 ? "28.8px" : undefined,
                      top: screenWidth >= 480 && screenWidth < 768 ? "426px" : screenWidth < 480 ? "271px" : undefined,
                    }}
                  >
                    КЛАРА ЖИХАРЕВА
                  </div>
                  <div
                    className="frame-17"
                    style={{
                      gap: screenWidth >= 480 && screenWidth < 768 ? "20px" : screenWidth < 480 ? "16px" : undefined,
                      top: screenWidth >= 480 && screenWidth < 768 ? "499px" : screenWidth < 480 ? "316px" : undefined,
                      width:
                        screenWidth >= 480 && screenWidth < 768 ? "440px" : screenWidth < 480 ? "320px" : undefined,
                    }}
                  >
                    <div className="frame-14">
                      <img className="star-9" alt="Star" src="/img/star-14-9.svg" />
                      <div
                        className="text-wrapper-18"
                        style={{
                          fontSize:
                            screenWidth >= 480 && screenWidth < 768 ? "16px" : screenWidth < 480 ? "14px" : undefined,
                          lineHeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "19.2px"
                              : screenWidth < 480
                              ? "16.8px"
                              : undefined,
                        }}
                      >
                        психолог, нейрофизиолог
                      </div>
                    </div>
                    <div className="frame-14">
                      <img className="star-9" alt="Star" src="/img/star-14-9.svg" />
                      <div
                        className="text-wrapper-19"
                        style={{
                          fontSize:
                            screenWidth >= 480 && screenWidth < 768 ? "16px" : screenWidth < 480 ? "14px" : undefined,
                          lineHeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "19.2px"
                              : screenWidth < 480
                              ? "16.8px"
                              : undefined,
                        }}
                      >
                        сертифицированный IFS-терапевт
                      </div>
                    </div>
                    <div className="frame-14">
                      <img className="star-9" alt="Star" src="/img/star-14-9.svg" />
                      <div
                        className="div-3"
                        style={{
                          fontSize:
                            screenWidth >= 480 && screenWidth < 768 ? "16px" : screenWidth < 480 ? "14px" : undefined,
                          lineHeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "19.2px"
                              : screenWidth < 480
                              ? "16.8px"
                              : undefined,
                          whiteSpace: screenWidth >= 480 && screenWidth < 768 ? "nowrap" : undefined,
                          width:
                            screenWidth >= 480 && screenWidth < 768
                              ? "fit-content"
                              : screenWidth < 480
                              ? "286px"
                              : undefined,
                        }}
                      >
                        {screenWidth >= 480 && screenWidth < 768 && (
                          <p className="text-wrapper-164">супервизор и ментор для психологов</p>
                        )}

                        {screenWidth < 480 && <>супервизор и ментор для психологов</>}
                      </div>
                    </div>
                    <div className="frame-14">
                      <img className="star-9" alt="Star" src="/img/star-14-12.svg" />
                      <p
                        className="text-wrapper-20"
                        style={{
                          fontSize:
                            screenWidth >= 480 && screenWidth < 768 ? "16px" : screenWidth < 480 ? "14px" : undefined,
                          lineHeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "19.2px"
                              : screenWidth < 480
                              ? "16.8px"
                              : undefined,
                        }}
                      >
                        преподаватель Psychodemia (при Институте психологии РАН)
                      </p>
                    </div>
                    <div className="frame-14">
                      <img
                        className="star-9"
                        alt="Star"
                        src={
                          screenWidth >= 480 && screenWidth < 768
                            ? "/img/star-14-9.svg"
                            : screenWidth < 480
                            ? "/img/star-14-12.svg"
                            : undefined
                        }
                      />
                      <p
                        className="text-wrapper-21"
                        style={{
                          fontSize:
                            screenWidth >= 480 && screenWidth < 768 ? "16px" : screenWidth < 480 ? "14px" : undefined,
                          lineHeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "19.2px"
                              : screenWidth < 480
                              ? "16.8px"
                              : undefined,
                        }}
                      >
                        клиенты из списка Forbes, Yandex, Tinkoff, Facebook
                      </p>
                    </div>
                  </div>
                </>
              )}

              {screenWidth >= 1280 && (
                <>
                  <div className="text-wrapper-22">
                    НАЙДЁМ ВЫХОД <br />
                    ИЗ ЛАБИРИНТА СТРАДАНИЙ
                  </div>
                  <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                    <img className="button-2" alt="Button" src="/img/button-2.svg" />
                  </a>
                  <p className="text-wrapper-23">
                    Я сопровождаю вас в процессе ваших глубинных изменений.&nbsp;&nbsp;Вместе мы идем в исследование
                    механизмов психики и слой за слоем снимаем пелену иллюзий, отделяющую вас от себя настоящего.
                  </p>
                </>
              )}

              <div
                className="photo-2"
                style={{
                  height:
                    screenWidth >= 480 && screenWidth < 768
                      ? "396px"
                      : screenWidth < 480
                      ? "241px"
                      : screenWidth >= 1280
                      ? "32px"
                      : undefined,
                  left:
                    screenWidth >= 480 && screenWidth < 768
                      ? "67px"
                      : screenWidth < 480
                      ? "70px"
                      : screenWidth >= 1280
                      ? "36px"
                      : undefined,
                  top:
                    (screenWidth >= 480 && screenWidth < 768) || screenWidth < 480
                      ? "0"
                      : screenWidth >= 1280
                      ? "936px"
                      : undefined,
                  width:
                    screenWidth >= 480 && screenWidth < 768
                      ? "307px"
                      : screenWidth < 480
                      ? "187px"
                      : screenWidth >= 1280
                      ? "44px"
                      : undefined,
                }}
              >
                {((screenWidth >= 480 && screenWidth < 768) || screenWidth < 480) && (
                  <div
                    className="overlap-6"
                    style={{
                      height:
                        screenWidth >= 480 && screenWidth < 768 ? "396px" : screenWidth < 480 ? "241px" : undefined,
                    }}
                  >
                    <div
                      className="group-3"
                      style={{
                        backgroundImage:
                          screenWidth >= 480 && screenWidth < 768
                            ? "url(/img/img-5.svg)"
                            : screenWidth < 480
                            ? "url(/img/img-1.svg)"
                            : undefined,
                        height:
                          screenWidth >= 480 && screenWidth < 768 ? "388px" : screenWidth < 480 ? "236px" : undefined,
                        left: screenWidth >= 480 && screenWidth < 768 ? "4px" : screenWidth < 480 ? "2px" : undefined,
                        top: screenWidth >= 480 && screenWidth < 768 ? "4px" : screenWidth < 480 ? "3px" : undefined,
                        width:
                          screenWidth >= 480 && screenWidth < 768 ? "301px" : screenWidth < 480 ? "183px" : undefined,
                      }}
                    />
                    <div
                      className="div-wrapper"
                      style={{
                        height:
                          screenWidth >= 480 && screenWidth < 768 ? "396px" : screenWidth < 480 ? "241px" : undefined,
                        width:
                          screenWidth >= 480 && screenWidth < 768 ? "307px" : screenWidth < 480 ? "187px" : undefined,
                      }}
                    >
                      <div
                        className="overlap-group-5"
                        style={{
                          height:
                            screenWidth >= 480 && screenWidth < 768 ? "394px" : screenWidth < 480 ? "240px" : undefined,
                          width:
                            screenWidth >= 480 && screenWidth < 768 ? "306px" : screenWidth < 480 ? "186px" : undefined,
                        }}
                      >
                        <img
                          className="rectangle-8"
                          style={{
                            height:
                              screenWidth >= 480 && screenWidth < 768
                                ? "387px"
                                : screenWidth < 480
                                ? "236px"
                                : undefined,
                            left:
                              screenWidth >= 480 && screenWidth < 768 ? "2px" : screenWidth < 480 ? "1px" : undefined,
                            top:
                              screenWidth >= 480 && screenWidth < 768 ? "3px" : screenWidth < 480 ? "2px" : undefined,
                            width:
                              screenWidth >= 480 && screenWidth < 768
                                ? "153px"
                                : screenWidth < 480
                                ? "94px"
                                : undefined,
                          }}
                          alt="Rectangle"
                          src={
                            screenWidth >= 480 && screenWidth < 768
                              ? "/img/rectangle-44-9.svg"
                              : screenWidth < 480
                              ? "/img/rectangle-44-3.svg"
                              : undefined
                          }
                        />
                        <img
                          className="line-8"
                          style={{
                            left:
                              screenWidth >= 480 && screenWidth < 768 ? "3px" : screenWidth < 480 ? "2px" : undefined,
                            top:
                              screenWidth >= 480 && screenWidth < 768
                                ? "391px"
                                : screenWidth < 480
                                ? "238px"
                                : undefined,
                            width:
                              screenWidth >= 480 && screenWidth < 768
                                ? "301px"
                                : screenWidth < 480
                                ? "183px"
                                : undefined,
                          }}
                          alt="Line"
                          src={
                            screenWidth >= 480 && screenWidth < 768
                              ? "/img/line-9-9.svg"
                              : screenWidth < 480
                              ? "/img/line-9-3.svg"
                              : undefined
                          }
                        />
                        <img
                          className="star-10"
                          style={{
                            height:
                              screenWidth >= 480 && screenWidth < 768 ? "7px" : screenWidth < 480 ? "4px" : undefined,
                            top:
                              screenWidth >= 480 && screenWidth < 768
                                ? "387px"
                                : screenWidth < 480
                                ? "235px"
                                : undefined,
                            width:
                              screenWidth >= 480 && screenWidth < 768 ? "7px" : screenWidth < 480 ? "4px" : undefined,
                          }}
                          alt="Star"
                          src={
                            screenWidth >= 480 && screenWidth < 768
                              ? "/img/star-15-12.svg"
                              : screenWidth < 480
                              ? "/img/star-15-4.svg"
                              : undefined
                          }
                        />
                        <img
                          className="rectangle-9"
                          style={{
                            height:
                              screenWidth >= 480 && screenWidth < 768
                                ? "387px"
                                : screenWidth < 480
                                ? "236px"
                                : undefined,
                            left:
                              screenWidth >= 480 && screenWidth < 768
                                ? "156px"
                                : screenWidth < 480
                                ? "95px"
                                : undefined,
                            top:
                              screenWidth >= 480 && screenWidth < 768 ? "3px" : screenWidth < 480 ? "2px" : undefined,
                            width:
                              screenWidth >= 480 && screenWidth < 768
                                ? "148px"
                                : screenWidth < 480
                                ? "91px"
                                : undefined,
                          }}
                          alt="Rectangle"
                          src={
                            screenWidth >= 480 && screenWidth < 768
                              ? "/img/rectangle-56-9.svg"
                              : screenWidth < 480
                              ? "/img/rectangle-56-3.svg"
                              : undefined
                          }
                        />
                        <img
                          className="star-11"
                          style={{
                            height:
                              screenWidth >= 480 && screenWidth < 768 ? "7px" : screenWidth < 480 ? "4px" : undefined,
                            left:
                              screenWidth >= 480 && screenWidth < 768
                                ? "298px"
                                : screenWidth < 480
                                ? "182px"
                                : undefined,
                            top:
                              screenWidth >= 480 && screenWidth < 768
                                ? "387px"
                                : screenWidth < 480
                                ? "235px"
                                : undefined,
                            width:
                              screenWidth >= 480 && screenWidth < 768 ? "7px" : screenWidth < 480 ? "4px" : undefined,
                          }}
                          alt="Star"
                          src={
                            screenWidth >= 480 && screenWidth < 768
                              ? "/img/star-15-13.svg"
                              : screenWidth < 480
                              ? "/img/star-15-5.svg"
                              : undefined
                          }
                        />
                        <img
                          className="star-12"
                          style={{
                            height:
                              screenWidth >= 480 && screenWidth < 768 ? "7px" : screenWidth < 480 ? "4px" : undefined,
                            left:
                              screenWidth >= 480 && screenWidth < 768
                                ? "152px"
                                : screenWidth < 480
                                ? "93px"
                                : undefined,
                            width:
                              screenWidth >= 480 && screenWidth < 768 ? "7px" : screenWidth < 480 ? "4px" : undefined,
                          }}
                          alt="Star"
                          src={
                            screenWidth >= 480 && screenWidth < 768
                              ? "/img/star-15-14.svg"
                              : screenWidth < 480
                              ? "/img/star-15-6.svg"
                              : undefined
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                {screenWidth >= 1280 && (
                  <>
                    <img className="union-4" alt="Union" src="/img/union-3.svg" />
                    <img className="union-5" alt="Union" src="/img/union-3.svg" />
                  </>
                )}
              </div>
              {screenWidth >= 1280 && (
                <>
                  <div className="view">
                    <div className="frame-18">
                      <div className="text-wrapper-24">Построение отношений</div>
                      <img className="star-13" alt="Star" src="/img/star-17.svg" />
                    </div>
                    <p className="text-wrapper-25">
                      Вы научитесь создавать гармоничные отношения (в том числе сексуальные), выходить из эмоциональной
                      зависимости и экологично проживать кризисы и расставания.
                    </p>
                  </div>
                  <div className="view-2">
                    <div className="frame-19">
                      <div className="text-wrapper-24">Глубинное самоисследование</div>
                      <img className="star-13" alt="Star" src="/img/star-17.svg" />
                    </div>
                    <p className="div-4">
                      Вы сможете встать на свой путь в жизни, найти ответ на вопрос «Кто Я?», выйти из кризиса смыслов,
                      глубже познакомиться с собой истинным и ощутить подлинную связь с миром.
                    </p>
                  </div>
                  <div className="view-3">
                    <div className="frame-20">
                      <div className="text-wrapper-26">Эмоциональная регуляция</div>
                      <img className="star-13" alt="Star" src="/img/star-17.svg" />
                    </div>
                    <p className="div-4">
                      Вы постепенно выстроите экологичные отношения со своими эмоциями: исцелите депрессию, апатию,
                      страхи или фоновую тревогу и вернёте себе душевный покой.
                    </p>
                  </div>
                  <div className="view-4">
                    <div className="frame-21">
                      <div className="text-wrapper-27">Самоценность</div>
                      <img className="star-13" alt="Star" src="/img/star-17.svg" />
                    </div>
                    <p className="text-wrapper-28">
                      Вы попрощаетесь с синдромом самозванца, исцелите чувства вины и стыда, одиночества и ненужности,
                      приведёте в баланс самооценку и выстроите целостное ощущение себя в этом мире.
                    </p>
                  </div>
                </>
              )}

              {((screenWidth >= 480 && screenWidth < 768) || screenWidth < 480) && (
                <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                  <img
                    className="button-3"
                    style={{
                      height: screenWidth >= 480 && screenWidth < 768 ? "60px" : screenWidth < 480 ? "54px" : undefined,
                      left: screenWidth >= 480 && screenWidth < 768 ? "38px" : screenWidth < 480 ? "0" : undefined,
                      top: screenWidth >= 480 && screenWidth < 768 ? "723px" : screenWidth < 480 ? "519px" : undefined,
                      width: screenWidth >= 480 && screenWidth < 768 ? "364px" : screenWidth < 480 ? "320px" : undefined,
                    }}
                    alt="Button"
                    src={
                      screenWidth >= 480 && screenWidth < 768
                        ? "/img/button-15.svg"
                        : screenWidth < 480
                        ? "/img/button-5.svg"
                        : undefined
                    }
                  />
                </a>
              )}
            </div>
          )}

          {screenWidth >= 1280 && <div className="pattern-2" />}

          {((screenWidth >= 480 && screenWidth < 768) || screenWidth < 480) && (
            <MenuIcon
              className={`${screenWidth >= 480 && screenWidth < 768 && "class"} ${screenWidth < 480 && "class-2"}`}
              divClassName="instance-node"
              property1="menu-burger"
              rectangleClassName="menu-icon-2"
              rectangleClassNameOverride="menu-icon-instance"
              onClick={toggleMenuDropdown}
            />
          )}

          {((screenWidth >= 768 && screenWidth < 960) || (screenWidth >= 960 && screenWidth < 1280)) && (
            <MenuIcon
              className={`${screenWidth >= 960 && screenWidth < 1280 && "class-3"} ${
                screenWidth >= 768 && screenWidth < 960 && "class-4"
              }`}
              property1="menu-burger"
              onClick={toggleMenuDropdown}
            />
          )}
        </div>
        <div
          className="overlap-7"
          style={{
            height:
              screenWidth < 480
                ? "528px"
                : screenWidth >= 480 && screenWidth < 768
                ? "901px"
                : screenWidth >= 960 && screenWidth < 1280
                ? "1156px"
                : screenWidth >= 1280
                ? "1359px"
                : screenWidth >= 768 && screenWidth < 960
                ? "1000px"
                : undefined,
            left:
              screenWidth < 480
                ? "20px"
                : screenWidth >= 480 && screenWidth < 768
                ? "-11px"
                : screenWidth >= 1280 ||
                  (screenWidth >= 768 && screenWidth < 960) ||
                  (screenWidth >= 960 && screenWidth < 1280)
                ? "0"
                : undefined,
            top:
              screenWidth < 480
                ? "663px"
                : screenWidth >= 480 && screenWidth < 768
                ? "873px"
                : screenWidth >= 960 && screenWidth < 1280
                ? "584px"
                : screenWidth >= 1280
                ? "792px"
                : screenWidth >= 768 && screenWidth < 960
                ? "501px"
                : undefined,
            width:
              screenWidth < 480
                ? "340px"
                : screenWidth >= 480 && screenWidth < 768
                ? "491px"
                : screenWidth >= 960 && screenWidth < 1280
                ? "960px"
                : screenWidth >= 1280
                ? "1280px"
                : screenWidth >= 768 && screenWidth < 960
                ? "768px"
                : undefined,
          }}
        >
          {((screenWidth >= 480 && screenWidth < 768) ||
            (screenWidth >= 768 && screenWidth < 960) ||
            (screenWidth >= 960 && screenWidth < 1280)) && (
            <div
              className="golden-ratio-circles"
              style={{
                top:
                  screenWidth >= 480 && screenWidth < 768
                    ? "359px"
                    : screenWidth >= 960 && screenWidth < 1280
                    ? "325px"
                    : screenWidth >= 768 && screenWidth < 960
                    ? "320px"
                    : undefined,
                width:
                  screenWidth >= 480 && screenWidth < 768
                    ? "84px"
                    : screenWidth >= 960 && screenWidth < 1280
                    ? "182px"
                    : screenWidth >= 768 && screenWidth < 960
                    ? "160px"
                    : undefined,
                left: 
                  screenWidth >= 480 && screenWidth < 768 && "20px"
              }}
            >
              {screenWidth >= 960 && screenWidth < 1280 && <GoldenRatio_960 />}
              {screenWidth >= 768 && screenWidth < 960 && <GoldenRatio_768 />}
              {screenWidth >= 480 && screenWidth < 768 && <GoldenRatio_480 />}
            </div>
          )}

          {((screenWidth >= 480 && screenWidth < 768) ||
            (screenWidth >= 768 && screenWidth < 960) ||
            (screenWidth >= 960 && screenWidth < 1280) ||
            screenWidth < 480) && (
            <div
              className="factoids"
              style={{
                height:
                  screenWidth >= 480 && screenWidth < 768
                    ? "536px"
                    : screenWidth >= 960 && screenWidth < 1280
                    ? "502px"
                    : screenWidth >= 768 && screenWidth < 960
                    ? "458px"
                    : screenWidth < 480
                    ? "517px"
                    : undefined,
                left:
                  screenWidth >= 480 && screenWidth < 768
                    ? "31px"
                    : (screenWidth >= 768 && screenWidth < 960) || (screenWidth >= 960 && screenWidth < 1280)
                    ? "20px"
                    : screenWidth < 480
                    ? "0"
                    : undefined,
                width:
                  screenWidth >= 480 && screenWidth < 768
                    ? "440px"
                    : screenWidth >= 960 && screenWidth < 1280
                    ? "920px"
                    : screenWidth >= 768 && screenWidth < 960
                    ? "728px"
                    : screenWidth < 480
                    ? "320px"
                    : undefined,
              }}
            >
              {((screenWidth >= 480 && screenWidth < 768) || screenWidth < 480) && (
                <div
                  className="frame-22"
                  style={{
                    alignItems: screenWidth >= 480 && screenWidth < 768 ? "center" : undefined,
                    display: screenWidth >= 480 && screenWidth < 768 ? "flex" : undefined,
                    flexWrap: screenWidth >= 480 && screenWidth < 768 ? "wrap" : undefined,
                    gap: screenWidth >= 480 && screenWidth < 768 ? "20px 20px" : undefined,
                    height: screenWidth < 480 ? "196px" : undefined,
                    width: screenWidth >= 480 && screenWidth < 768 ? "440px" : screenWidth < 480 ? "320px" : undefined,
                  }}
                >
                  {screenWidth >= 480 && screenWidth < 768 && (
                    <>
                      <Factoid
                        className="component"
                        divClassName="component-2"
                        elementClassName="component-4"
                        overlapGroupClassName="factoid-instance"
                        rectangleClassName="component-3"
                        text="&gt;8 ЛЕТ"
                      />
                      <Factoid
                        className="component"
                        divClassName="component-5"
                        elementClassName="component-6"
                        overlapGroupClassName="factoid-instance"
                        rectangleClassName="component-3"
                        text="&gt;5000"
                        text1="консультаций"
                      />
                      <Factoid
                        className="component-7"
                        divClassName="component-8"
                        elementClassName="component-10"
                        overlapGroupClassName="factoid-instance"
                        rectangleClassName="component-9"
                        text="200+"
                        text1="часов личной терапии"
                      />
                    </>
                  )}

                  {screenWidth < 480 && (
                    <>
                      <div className="frame-23">
                        <div className="component-11">
                          <div className="overlap-group-6">
                            <div className="text-wrapper-29">опыта</div>
                            <div className="rectangle-17" />
                            <img className="star-14" alt="Star" src="/img/star-11-4.svg" />
                            <div className="text-wrapper-30">&gt;8 ЛЕТ</div>
                          </div>
                        </div>
                        <div className="component-11">
                          <div className="overlap-group-6">
                            <div className="text-wrapper-31">консультаций</div>
                            <div className="rectangle-17" />
                            <img className="star-15" alt="Star" src="/img/star-11-4.svg" />
                            <div className="text-wrapper-32">&gt;5000</div>
                          </div>
                        </div>
                        <div className="component-12">
                          <div className="overlap-9">
                            <div className="text-wrapper-33">
                              часов <br />
                              личной терапии
                            </div>
                            <div className="rectangle-18" />
                            <img className="star-14" alt="Star" src="/img/star-11-4.svg" />
                            <div className="text-wrapper-34">200+</div>
                          </div>
                        </div>
                      </div>
                      <div className="group-wrapper">
                        <Key_360 />
                      </div>
                    </>
                  )}
                </div>
              )}

              {screenWidth >= 960 && screenWidth < 1280 && (
                <>
                  <div className="overlap-10">
                    <div className="overlap-11">
                      <div className="frame-24">
                        <Factoid
                          className="component-13"
                          divClassName="component-15"
                          elementClassName="component-16"
                          overlapGroupClassName="component-14"
                          rectangleClassName="component-14"
                          star="/img/star-11.svg"
                          text="&gt;8 ЛЕТ"
                          text1="опыта"
                        />
                        <Factoid
                          className="component-13"
                          divClassName="component-17"
                          elementClassName="component-18"
                          overlapGroupClassName="component-14"
                          rectangleClassName="component-14"
                          star="/img/star-11-8.svg"
                          text="&gt;5000"
                          text1="консультаций"
                        />
                        <Factoid
                          className="component-13"
                          divClassName="component-19"
                          elementClassName="component-20"
                          overlapGroupClassName="component-14"
                          rectangleClassName="component-14"
                          star="/img/star-11-9.svg"
                          text="200+"
                          text1="часов личной терапии"
                        />
                      </div>
                      <div className="key-2">
                        <Key_960 />
                      </div>
                    </div>
                    <div className="quote">
                      <p className="text-wrapper-35">
                        Я помогаю людям осмелиться жить. Восстановить связь с собой и свободно выражать в мир свою
                        истинную природу.
                      </p>
                      <p className="text-wrapper-36">
                        Я искренне верю в то, что возвращение к своей истине — это путь, требующий смелости, но
                        исцеляющий всё.
                      </p>
                      <div className="quote-2">
                        <img className="union-4" alt="Union" src="/img/union-3.svg" />
                        <img className="union-5" alt="Union" src="/img/union-3.svg" />
                      </div>
                    </div>
                  </div>
                  <HierarchySecondary
                    className="hierarchy-secondary-instance"
                    frameClassName="hierarchy-secondary-state-default"
                    text="НАЧАТЬ ИССЛЕДОВАНИЕ"
                    visible={false}
                    onClick={() => scrollToSection('alchemy')}
                    isPreElementVisible={false}
                  />
                </>
              )}

              {screenWidth >= 768 && screenWidth < 960 && (
                <div className="frame-25">
                  <Factoid
                    className="component-21"
                    divClassName="component-23"
                    elementClassName="component-24"
                    overlapGroupClassName="component-22"
                    rectangleClassName="component-22"
                    text="&gt;8 ЛЕТ"
                    text1="опыта"
                  />
                  <Factoid
                    className="component-21"
                    divClassName="component-25"
                    elementClassName="component-26"
                    overlapGroupClassName="component-22"
                    rectangleClassName="component-22"
                    star="/img/star-11-8.svg"
                    text="&gt;5000"
                    text1="консультаций"
                  />
                  <Factoid
                    className="component-21"
                    divClassName="component-27"
                    elementClassName="component-28"
                    overlapGroupClassName="component-22"
                    rectangleClassName="component-22"
                    star="/img/star-11-9.svg"
                    text="200+"
                    text1="часов личной терапии"
                  />
                </div>
              )}

              {((screenWidth >= 480 && screenWidth < 768) ||
                (screenWidth >= 768 && screenWidth < 960) ||
                screenWidth < 480) && (
                <HierarchySecondary
                  className={`${screenWidth >= 480 && screenWidth < 768 && "class-7"} ${
                    screenWidth < 480 && "class-8"
                  } ${screenWidth >= 768 && screenWidth < 960 && "class-9"}`}
                  divClassName={`${
                    ((screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 768 && screenWidth < 960)) &&
                    "class-5"
                  } ${screenWidth < 480 && "class-6"}`}
                  text={
                    (screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 768 && screenWidth < 960)
                      ? "НАЧАТЬ ИССЛЕДОВАНИЕ"
                      : undefined
                  }
                  visible={false}
                  onClick={() => scrollToSection('alchemy')}
                  isPreElementVisible={false}
                />
              )}

              {screenWidth >= 768 && screenWidth < 960 && (
                <div className="key-3">
                  <Key_768 />
                </div>
              )}

              {((screenWidth >= 480 && screenWidth < 768) ||
                (screenWidth >= 768 && screenWidth < 960) ||
                screenWidth < 480) && (
                <div
                  className="quote-3"
                  style={{
                    height:
                      screenWidth >= 768 && screenWidth < 960
                        ? "181px"
                        : screenWidth >= 480 && screenWidth < 768
                        ? "152px"
                        : screenWidth < 480
                        ? "207px"
                        : undefined,
                    left:
                      screenWidth >= 768 && screenWidth < 960
                        ? "1px"
                        : screenWidth >= 480 && screenWidth < 768
                        ? "2px"
                        : screenWidth < 480
                        ? "0"
                        : undefined,
                    top:
                      screenWidth >= 768 && screenWidth < 960
                        ? "173px"
                        : screenWidth >= 480 && screenWidth < 768
                        ? "290px"
                        : screenWidth < 480
                        ? "226px"
                        : undefined,
                    width:
                      screenWidth >= 768 && screenWidth < 960
                        ? "566px"
                        : screenWidth >= 480 && screenWidth < 768
                        ? "442px"
                        : screenWidth < 480
                        ? "324px"
                        : undefined,
                  }}
                >
                  <p
                    className="text-wrapper-37"
                    style={{
                      fontSize:
                        screenWidth >= 768 && screenWidth < 960
                          ? "20px"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "18px"
                          : screenWidth < 480
                          ? "17px"
                          : undefined,
                      left:
                        screenWidth >= 768 && screenWidth < 960
                          ? "48px"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "40px"
                          : screenWidth < 480
                          ? "0"
                          : undefined,
                      lineHeight:
                        screenWidth >= 768 && screenWidth < 960
                          ? "24.0px"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "21.6px"
                          : screenWidth < 480
                          ? "20.4px"
                          : undefined,
                      top:
                        screenWidth >= 768 && screenWidth < 960
                          ? "17px"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "0"
                          : screenWidth < 480
                          ? "27px"
                          : undefined,
                      width:
                        screenWidth >= 768 && screenWidth < 960
                          ? "414px"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "398px"
                          : screenWidth < 480
                          ? "320px"
                          : undefined,
                    }}
                  >
                    Я помогаю людям осмелиться жить. Восстановить связь с собой и свободно выражать в мир свою истинную
                    природу.
                  </p>
                  <p
                    className="text-wrapper-38"
                    style={{
                      fontSize:
                        screenWidth >= 768 && screenWidth < 960
                          ? "20px"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "18px"
                          : screenWidth < 480
                          ? "17px"
                          : undefined,
                      left:
                        screenWidth >= 768 && screenWidth < 960
                          ? "148px"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "40px"
                          : screenWidth < 480
                          ? "0"
                          : undefined,
                      lineHeight:
                        screenWidth >= 768 && screenWidth < 960
                          ? "24.0px"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "21.6px"
                          : screenWidth < 480
                          ? "20.4px"
                          : undefined,
                      top:
                        screenWidth >= 768 && screenWidth < 960
                          ? "109px"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "86px"
                          : screenWidth < 480
                          ? "127px"
                          : undefined,
                      width:
                        screenWidth >= 768 && screenWidth < 960
                          ? "414px"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "398px"
                          : screenWidth < 480
                          ? "320px"
                          : undefined,
                    }}
                  >
                    Я искренне верю в то, что возвращение к своей истине — это путь, требующий смелости, но исцеляющий
                    всё.
                  </p>
                  <div
                    className="quote-4"
                    style={{
                      height:
                        screenWidth >= 768 && screenWidth < 960
                          ? "23px"
                          : (screenWidth >= 480 && screenWidth < 768) || screenWidth < 480
                          ? "17px"
                          : undefined,
                      width:
                        screenWidth >= 768 && screenWidth < 960
                          ? "32px"
                          : (screenWidth >= 480 && screenWidth < 768) || screenWidth < 480
                          ? "24px"
                          : undefined,
                    }}
                  >
                    <img
                      className="union-7"
                      style={{
                        height:
                          screenWidth >= 768 && screenWidth < 960
                            ? "23px"
                            : (screenWidth >= 480 && screenWidth < 768) || screenWidth < 480
                            ? "17px"
                            : undefined,
                        left:
                          screenWidth >= 768 && screenWidth < 960
                            ? "17px"
                            : (screenWidth >= 480 && screenWidth < 768) || screenWidth < 480
                            ? "13px"
                            : undefined,
                        width:
                          screenWidth >= 768 && screenWidth < 960
                            ? "15px"
                            : (screenWidth >= 480 && screenWidth < 768) || screenWidth < 480
                            ? "11px"
                            : undefined,
                      }}
                      alt="Union"
                      src={
                        screenWidth >= 768 && screenWidth < 960
                          ? "/img/union-46.svg"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "/img/union-35.svg"
                          : screenWidth < 480
                          ? "/img/union-14.svg"
                          : undefined
                      }
                    />
                    <img
                      className="union-8"
                      style={{
                        height:
                          screenWidth >= 768 && screenWidth < 960
                            ? "23px"
                            : (screenWidth >= 480 && screenWidth < 768) || screenWidth < 480
                            ? "17px"
                            : undefined,
                        width:
                          screenWidth >= 768 && screenWidth < 960
                            ? "15px"
                            : (screenWidth >= 480 && screenWidth < 768) || screenWidth < 480
                            ? "11px"
                            : undefined,
                      }}
                      alt="Union"
                      src={
                        screenWidth >= 768 && screenWidth < 960
                          ? "/img/union-47.svg"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "/img/union-36.svg"
                          : screenWidth < 480
                          ? "/img/union-15.svg"
                          : undefined
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {screenWidth >= 480 && screenWidth < 768 && (
            <div className="key-4">
              <Key_480 />
            </div>
          )}

          {((screenWidth >= 480 && screenWidth < 768) ||
            (screenWidth >= 768 && screenWidth < 960) ||
            (screenWidth >= 960 && screenWidth < 1280)) && (
            <div
              className="view-5"
              style={{
                height:
                  screenWidth >= 480 && screenWidth < 768
                    ? "325px"
                    : screenWidth >= 960 && screenWidth < 1280
                    ? "584px"
                    : screenWidth >= 768 && screenWidth < 960
                    ? "472px"
                    : undefined,
                left:
                  screenWidth >= 480 && screenWidth < 768
                    ? "33px"
                    : (screenWidth >= 768 && screenWidth < 960) || (screenWidth >= 960 && screenWidth < 1280)
                    ? "21px"
                    : undefined,
                top:
                  screenWidth >= 480 && screenWidth < 768
                    ? "576px"
                    : screenWidth >= 960 && screenWidth < 1280
                    ? "572px"
                    : screenWidth >= 768 && screenWidth < 960
                    ? "528px"
                    : undefined,
                width:
                  screenWidth >= 480 && screenWidth < 768
                    ? "440px"
                    : screenWidth >= 960 && screenWidth < 1280
                    ? "921px"
                    : screenWidth >= 768 && screenWidth < 960
                    ? "729px"
                    : undefined,
              }}
            >
              <div
                className="text-wrapper-39"
                style={{
                  fontSize:
                    screenWidth >= 480 && screenWidth < 768
                      ? "24px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "28px"
                      : screenWidth >= 768 && screenWidth < 960
                      ? "26px"
                      : undefined,
                  lineHeight:
                    screenWidth >= 480 && screenWidth < 768
                      ? "28.8px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "33.6px"
                      : screenWidth >= 768 && screenWidth < 960
                      ? "31.2px"
                      : undefined,
                  whiteSpace:
                    (screenWidth >= 768 && screenWidth < 960) || (screenWidth >= 960 && screenWidth < 1280)
                      ? "nowrap"
                      : undefined,
                  width: screenWidth >= 480 && screenWidth < 768 ? "274px" : undefined,
                }}
              >
                Посмотрите короткое приветственное видео
              </div>
              <div
                className="player"
                style={{
                  height:
                    screenWidth >= 480 && screenWidth < 768
                      ? "247px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "520px"
                      : screenWidth >= 768 && screenWidth < 960
                      ? "411px"
                      : undefined,
                  top:
                    screenWidth >= 480 && screenWidth < 768
                      ? "78px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "64px"
                      : screenWidth >= 768 && screenWidth < 960
                      ? "61px"
                      : undefined,
                  width:
                    screenWidth >= 480 && screenWidth < 768
                      ? "438px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "919px"
                      : screenWidth >= 768 && screenWidth < 960
                      ? "727px"
                      : undefined,
                }}
              >
                <ReactPlayer 
                  light
                  url='https://youtu.be/L77qR3M1SfM'
                  width='100%'
                  height='100%'
                  playing
                  // playIcon={
                  //   <div
                  //     className="play"
                  //     style={{
                  //         borderRadius:
                  //         screenWidth >= 480 && screenWidth < 768
                  //             ? "32px"
                  //             : screenWidth >= 960 && screenWidth < 1280
                  //             ? "66px"
                  //             : screenWidth >= 768 && screenWidth < 960
                  //             ? "47px"
                  //             : undefined,
                  //         height:
                  //         screenWidth >= 480 && screenWidth < 768
                  //             ? "64px"
                  //             : screenWidth >= 960 && screenWidth < 1280
                  //             ? "132px"
                  //             : screenWidth >= 768 && screenWidth < 960
                  //             ? "94px"
                  //             : undefined,
                  //         left:
                  //         screenWidth >= 480 && screenWidth < 768
                  //             ? "187px"
                  //             : screenWidth >= 960 && screenWidth < 1280
                  //             ? "393px"
                  //             : screenWidth >= 768 && screenWidth < 960
                  //             ? "316px"
                  //             : undefined,
                  //         top:
                  //         screenWidth >= 480 && screenWidth < 768
                  //             ? "95px"
                  //             : screenWidth >= 960 && screenWidth < 1280
                  //             ? "211px"
                  //             : screenWidth >= 768 && screenWidth < 960
                  //             ? "159px"
                  //             : undefined,
                  //         width:
                  //         screenWidth >= 480 && screenWidth < 768
                  //             ? "64px"
                  //             : screenWidth >= 960 && screenWidth < 1280
                  //             ? "132px"
                  //             : screenWidth >= 768 && screenWidth < 960
                  //             ? "94px"
                  //             : undefined,
                  //     }}
                  //     >
                  //     <img
                  //         className="polygon-3"
                  //         style={{
                  //         height:
                  //             screenWidth >= 480 && screenWidth < 768
                  //             ? "19px"
                  //             : screenWidth >= 960 && screenWidth < 1280
                  //             ? "46px"
                  //             : screenWidth >= 768 && screenWidth < 960
                  //             ? "31px"
                  //             : undefined,
                  //         left:
                  //             screenWidth >= 480 && screenWidth < 768
                  //             ? "25px"
                  //             : screenWidth >= 960 && screenWidth < 1280
                  //             ? "51px"
                  //             : screenWidth >= 768 && screenWidth < 960
                  //             ? "36px"
                  //             : undefined,
                  //         top:
                  //             screenWidth >= 480 && screenWidth < 768
                  //             ? "22px"
                  //             : screenWidth >= 960 && screenWidth < 1280
                  //             ? "43px"
                  //             : screenWidth >= 768 && screenWidth < 960
                  //             ? "31px"
                  //             : undefined,
                  //         width:
                  //             screenWidth >= 480 && screenWidth < 768
                  //             ? "18px"
                  //             : screenWidth >= 960 && screenWidth < 1280
                  //             ? "41px"
                  //             : screenWidth >= 768 && screenWidth < 960
                  //             ? "28px"
                  //             : undefined,
                  //         }}
                  //         alt="Polygon"
                  //         src={
                  //         screenWidth >= 480 && screenWidth < 768
                  //             ? "/img/polygon-2-3.svg"
                  //             : screenWidth >= 960 && screenWidth < 1280
                  //             ? "/img/polygon-2-2.svg"
                  //             : screenWidth >= 768 && screenWidth < 960
                  //             ? "/img/polygon-2-4.svg"
                  //             : undefined
                  //         }
                  //     />
                  // </div>
                  // }
                />
              </div>
              <img
                className="vector-5"
                style={{
                  height:
                    screenWidth >= 480 && screenWidth < 768
                      ? "41px"
                      : (screenWidth >= 768 && screenWidth < 960) || (screenWidth >= 960 && screenWidth < 1280)
                      ? "30px"
                      : undefined,
                  left:
                    screenWidth >= 480 && screenWidth < 768
                      ? "309px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "645px"
                      : screenWidth >= 768 && screenWidth < 960
                      ? "600px"
                      : undefined,
                  top:
                    screenWidth >= 480 && screenWidth < 768
                      ? "12px"
                      : (screenWidth >= 768 && screenWidth < 960) || (screenWidth >= 960 && screenWidth < 1280)
                      ? "16px"
                      : undefined,
                  width:
                    screenWidth >= 480 && screenWidth < 768
                      ? "50px"
                      : (screenWidth >= 768 && screenWidth < 960) || (screenWidth >= 960 && screenWidth < 1280)
                      ? "61px"
                      : undefined,
                }}
                alt="Vector"
                src={
                  screenWidth >= 480 && screenWidth < 768
                    ? "/img/vector-38-3.svg"
                    : (screenWidth >= 768 && screenWidth < 960) || (screenWidth >= 960 && screenWidth < 1280)
                    ? "/img/vector-38-2.svg"
                    : undefined
                }
              />
            </div>
          )}

          <img
            className="vector-6"
            style={{
              height:
                (screenWidth >= 480 && screenWidth < 768) ||
                (screenWidth >= 768 && screenWidth < 960) ||
                screenWidth < 480
                  ? "350px"
                  : screenWidth >= 960 && screenWidth < 1280
                  ? "346px"
                  : screenWidth >= 1280
                  ? "1077px"
                  : undefined,
              left:
                screenWidth < 480
                  ? "259px"
                  : screenWidth >= 960 && screenWidth < 1280
                  ? "779px"
                  : screenWidth >= 768 && screenWidth < 960
                  ? "650px"
                  : screenWidth >= 480 && screenWidth < 768
                  ? "391px"
                  : screenWidth >= 1280
                  ? "966px"
                  : undefined,
              top:
                screenWidth < 480
                  ? "178px"
                  : screenWidth >= 1280 || (screenWidth >= 960 && screenWidth < 1280)
                  ? "282px"
                  : screenWidth >= 768 && screenWidth < 960
                  ? "247px"
                  : screenWidth >= 480 && screenWidth < 768
                  ? "236px"
                  : undefined,
              width:
                screenWidth < 480
                  ? "81px"
                  : screenWidth >= 960 && screenWidth < 1280
                  ? "181px"
                  : screenWidth >= 768 && screenWidth < 960
                  ? "118px"
                  : screenWidth >= 480 && screenWidth < 768
                  ? "100px"
                  : screenWidth >= 1280
                  ? "314px"
                  : undefined,
            }}
            alt="Vector"
            src={
              screenWidth < 480
                ? "/img/vector-47.svg"
                : screenWidth >= 960 && screenWidth < 1280
                ? "/img/vector-47-1.svg"
                : screenWidth >= 768 && screenWidth < 960
                ? "/img/vector-47-3.svg"
                : screenWidth >= 480 && screenWidth < 768
                ? "/img/vector-47-2.svg"
                : screenWidth >= 1280
                ? "/img/vector-26.svg"
                : undefined
            }
          />
          {screenWidth >= 1280 && (
            <>
              <div className="view-6">
                <div className="text-wrapper-40">Посмотрите короткое приветственное видео</div>
                <div className="play-wrapper">
                  <ReactPlayer 
                    light
                    url='https://youtu.be/L77qR3M1SfM'
                    width='100%'
                    height='100%'
                    playing
                  />
                </div>
                <img className="vector-7" alt="Vector" src="/img/vector-38.svg" />
              </div>
              <div className="golden-ratio-circles-2">
               <GoldenRatio />
              </div>
              <div className="factoids-2">
                <div className="overlap-13">
                  <Factoid
                    className="component-29"
                    divClassName="component-30"
                    elementClassName="component-31"
                    star="/img/star-11.svg"
                    text="200+"
                    text1="часов личной терапии"
                  />
                  <div className="key-5">
                    <Key className="key-5"/>
                  </div>
                </div>
                <Factoid
                  className="component-32"
                  divClassName="component-33"
                  elementClassName="component-34"
                  star="/img/star-11.svg"
                  text="&gt;5000"
                  text1="консультаций"
                />
                <Factoid
                  className="component-35"
                  elementClassName="component-30"
                  star="/img/star-11.svg"
                  text="&gt;8 ЛЕТ"
                  text1="опыта"
                />
                <HierarchySecondary
                  className="hierarchy-secondary-state-default-instance"
                  frameClassName="hierarchy-secondary-state-default"
                  text="НАЧАТЬ ИССЛЕДОВАНИЕ"
                  visible={true}
                  onClick={(section) => scrollToSection(section)}
                  isPreElementVisible={false}
                />
                <div className="quote-5">
                  <p className="text-wrapper-35">
                    Я помогаю людям осмелиться жить. Восстановить связь с собой и свободно выражать в мир свою истинную
                    природу.
                  </p>
                  <p className="text-wrapper-41">
                    Я искренне верю в то, что возвращение к своей истине — это путь, требующий смелости, но исцеляющий
                    всё.
                  </p>
                  <div className="quote-2">
                    <img className="union-4" alt="Union" src="/img/union-3.svg" />
                    <img className="union-5" alt="Union" src="/img/union-3.svg" />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {(screenWidth >= 1280 ||
          (screenWidth >= 480 && screenWidth < 768) ||
          (screenWidth >= 960 && screenWidth < 1280)) && (
          <div
            className="view-7"
            style={{
              alignItems:
                screenWidth >= 480 && screenWidth < 768
                  ? "center"
                  : screenWidth >= 960 && screenWidth < 1280
                  ? "flex-start"
                  : undefined,
              display:
                (screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)
                  ? "flex"
                  : undefined,
              flexDirection:
                (screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)
                  ? "column"
                  : undefined,
              gap:
                screenWidth >= 480 && screenWidth < 768
                  ? "20px"
                  : screenWidth >= 960 && screenWidth < 1280
                  ? "40px"
                  : undefined,
              height: screenWidth >= 1280 ? "1336px" : undefined,
              left:
                (screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)
                  ? "20px"
                  : screenWidth >= 1280
                  ? "0"
                  : undefined,
              top:
                screenWidth >= 480 && screenWidth < 768
                  ? "1814px"
                  : screenWidth >= 960 && screenWidth < 1280
                  ? "1810px"
                  : screenWidth >= 1280
                  ? (isDetailsOpen && isAlchemyOpen ? "8685px" : isDetailsOpen && !isAlchemyOpen ? "8155px" : !isDetailsOpen && isAlchemyOpen ? "7000px" : "6348px")
                  : undefined,
              width:
                screenWidth >= 480 && screenWidth < 768
                  ? "440px"
                  : screenWidth >= 960 && screenWidth < 1280
                  ? "920px"
                  : screenWidth >= 1280
                  ? "2097px"
                  : undefined,
            }}
          >
            {((screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)) && (
              <>
                <div
                  className="frame-26"
                  style={{
                    gap:
                      screenWidth >= 480 && screenWidth < 768
                        ? "20px"
                        : screenWidth >= 960 && screenWidth < 1280
                        ? "40px"
                        : undefined,
                  }}
                >
                  <div
                    className="text-wrapper-42"
                    style={{
                      fontSize:
                        screenWidth >= 480 && screenWidth < 768
                          ? "32px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "48px"
                          : undefined,
                      lineHeight:
                        screenWidth >= 480 && screenWidth < 768
                          ? "38.4px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "57.6px"
                          : undefined,
                    }}
                  >
                    ОБРАЗОВАНИЕ И ОПЫТ
                  </div>
                  <div className="text">
                    <p
                      className="text-wrapper-43"
                      style={{
                        fontFamily:
                          screenWidth >= 480 && screenWidth < 768
                            ? "'DM Sans', Helvetica"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-text-font-family)"
                            : undefined,
                        fontSize:
                          screenWidth >= 480 && screenWidth < 768
                            ? "16px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-text-font-size)"
                            : undefined,
                        fontStyle: screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                        fontWeight:
                          screenWidth >= 480 && screenWidth < 768
                            ? "400"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-text-font-weight)"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 480 && screenWidth < 768
                            ? "0"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          screenWidth >= 480 && screenWidth < 768
                            ? "19.2px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-text-line-height)"
                            : undefined,
                      }}
                    >
                      Окончила МГУ и Вышку по специальностям нейрофизиология и психология, а так же несколько
                      многолетних программ по современным подходам психотерапии. Провела более 5000 консультаций, имею
                      за спиной около 200 часов личной терапии и регулярно работаю с супервизором.
                    </p>
                    <p
                      className="text-wrapper-44"
                      style={{
                        fontFamily:
                          screenWidth >= 480 && screenWidth < 768
                            ? "'DM Sans', Helvetica"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-text-font-family)"
                            : undefined,
                        fontSize:
                          screenWidth >= 480 && screenWidth < 768
                            ? "16px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-text-font-size)"
                            : undefined,
                        fontStyle: screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                        fontWeight:
                          screenWidth >= 480 && screenWidth < 768
                            ? "400"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-text-font-weight)"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 480 && screenWidth < 768
                            ? "0"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          screenWidth >= 480 && screenWidth < 768
                            ? "19.2px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-text-line-height)"
                            : undefined,
                      }}
                    >
                      Преподаватель в онлайн-школе психологических профессий Psychodemia (при Институте психологии РАН),
                      автор лекций на базе исследований в сферах физиологии, нейронаук, эволюционной психологии и
                      майндфулнесс. Program Assistant на сертификационном обучении IFS-терапевтов. Супервизор и ментор
                      для психологов.
                    </p>
                  </div>
                </div>
                <img
                  className="line-9"
                  alt="Line"
                  src={
                    screenWidth >= 480 && screenWidth < 768
                      ? "/img/line-6-3.svg"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "/img/line-6-2.svg"
                      : undefined
                  }
                />
                <div className="view-8">
                  <div
                    className="text-wrapper-45"
                    style={{
                      fontFamily:
                        screenWidth >= 480 && screenWidth < 768
                          ? "'Lora', Helvetica"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h3-small-font-family)"
                          : undefined,
                      fontSize:
                        screenWidth >= 480 && screenWidth < 768
                          ? "24px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h3-small-font-size)"
                          : undefined,
                      fontStyle:
                        screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-h3-small-font-style)" : undefined,
                      fontWeight:
                        screenWidth >= 480 && screenWidth < 768
                          ? "600"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h3-small-font-weight)"
                          : undefined,
                      letterSpacing:
                        screenWidth >= 480 && screenWidth < 768
                          ? "0"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h3-small-letter-spacing)"
                          : undefined,
                      lineHeight:
                        screenWidth >= 480 && screenWidth < 768
                          ? "28.8px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h3-small-line-height)"
                          : undefined,
                    }}
                  >
                    Подробнее
                  </div>
                  <img
                    className="frame-27"
                    style={{
                      height:
                        screenWidth >= 480 && screenWidth < 768
                          ? "24px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "32px"
                          : undefined,
                      width:
                        screenWidth >= 480 && screenWidth < 768
                          ? "24px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "32px"
                          : undefined,
                    }}
                    alt="Frame"
                    src={isDetailsOpen ? "/img/frame-83.svg" : "/img/dropdownArrow.svg"}
                    onClick={toggleDetailsDropdown}
                  />
                </div>
                {isDetailsOpen && <div
                  className="frame-28"
                  style={{
                    gap:
                      screenWidth >= 480 && screenWidth < 768
                        ? "20px"
                        : screenWidth >= 960 && screenWidth < 1280
                        ? "40px"
                        : undefined,
                  }}
                >
                  {screenWidth >= 480 && screenWidth < 768 && (
                    <>
                      <EducationCellWrapper
                        className="instance-node-2"
                        text="НИУ ВШЭ"
                        text1="Психоанализ и психоаналитическая психотерапия"
                        text2={
                          <>
                            магистр, <br />
                            2017–2019 гг.
                          </>
                        }
                      />
                      <EducationCellWrapper
                        className="instance-node-2"
                        text="МГИ"
                        text1="Базовая программа по гештальт-терапии"
                        text2="2016–2020 гг."
                        text3="600 часов"
                      />
                      <EducationCellWrapper
                        className="instance-node-2"
                        text="ИПКиРТ"
                        text1="Психодрама"
                        text2="2016–2019 гг."
                        text3="900 часов"
                      />
                      <EducationCellWrapper
                        className="instance-node-2"
                        text="Heril Formations"
                        text1="французская программа «Сексотерапия и терапия пар»"
                        text2="2019&nbsp;&nbsp;г."
                        text3=" 75 часов"
                      />
                      <EducationCellWrapper
                        className="instance-node-2"
                        text="The International Focusing Institute"
                        text1="Focusing 1−3"
                        text2="2021–2022 гг."
                        text3="52 часа"
                      />
                      <EducationCellWrapper
                        className="instance-node-2"
                        text="Психодемия"
                        text1="Терапия внутренних семейных систем (IFS)"
                        text2="2022-2023 гг."
                        text3="78 часов"
                      />
                      <EducationCellWrapper
                        className="instance-node-2"
                        text="Психодемия"
                        text1="Эмоционально-фокусированная терапия в работе с парами"
                        text2="2023 г."
                        text3="162 часа"
                      />
                      <EducationCellWrapper
                        className="instance-node-2"
                        text="Психодемия"
                        text1="Обучение супервизии"
                        text2="2024 г."
                        text3="190 часов"
                      />
                      <EducationCellWrapper
                        className="instance-node-2"
                        text="IFS Institute"
                        text1="IFS Level 1 Training"
                        text2="2023-2024 гг."
                        text3="90 часов"
                      />
                      <EducationCellWrapper
                        className="instance-node-2"
                        text="Loch Kelly"
                        text1="продвинутые семинары в Effortless Mindfulness и IFS по темам «Effortless Mindfulness and Self-Leadership», «How To Shift Out of Ego Into Awake Self»"
                        text2="2023-2024 гг."
                        text3="90 часов"
                      />
                      <EducationCellWrapper
                        className="instance-node-2"
                        text="Bruce Hersey"
                        text1="IFS-informed EMDR 1−3"
                        text2="2024 г."
                        text3=" 6 часов"
                      />
                    </>
                  )}

                  {screenWidth >= 960 && screenWidth < 1280 && (
                    <>
                      <Bullet
                        className="bullet-instance"
                        line="/img/line-8-28.svg"
                        text="МГУ им. М.В. Ломоносова"
                        text1="Нейрофизиология"
                        text2="бакалавр, 2013–2017 гг."
                        text3="красный диплом"
                      />
                      <Bullet
                        className="bullet-instance"
                        line="/img/line-8-28.svg"
                        text="НИУ ВШЭ"
                        text1="Психоанализ и психоаналитическая психотерапия"
                        text2="магистр, 2017–2019 гг."
                        text3="красный диплом"
                      />
                      <Bullet
                        className="bullet-instance"
                        line="/img/line-8-28.svg"
                        text="МГИ"
                        text1="Базовая программа по гештальт-терапии"
                        text2="2016–2020 гг."
                        text3="600 часов"
                      />
                      <Bullet
                        className="bullet-instance"
                        line="/img/line-8-28.svg"
                        text="ИПКиРТ"
                        text1="Психодрама"
                        text2="2016–2019 гг."
                        text3="900 часов"
                      />
                      <Bullet
                        className="bullet-instance"
                        line="/img/line-8-28.svg"
                        text="Heril Formations"
                        text1="французская программа «Сексотерапия и терапия пар»"
                        text2="2019&nbsp;&nbsp;г."
                        text3=" 75 часов"
                      />
                      <Bullet
                        className="bullet-instance"
                        divClassName="frame-29"
                        line="/img/line-8-33.svg"
                        text="The International Focusing Institute"
                        text1="Focusing 1−3"
                        text2="2021–2022 гг."
                        text3="52 часа"
                      />
                      <Bullet
                        className="bullet-instance"
                        line="/img/line-8-33.svg"
                        text="Психодемия"
                        text1="Терапия внутренних семейных систем (IFS)"
                        text2="2022-2023 гг."
                        text3="78 часов"
                      />
                      <Bullet
                        className="bullet-instance"
                        line="/img/line-8-33.svg"
                        text="Психодемия"
                        text1="Эмоционально-фокусированная терапия в работе с парами"
                        text2="2023 г."
                        text3="162 часа"
                      />
                      <Bullet
                        className="bullet-instance"
                        line="/img/line-8-33.svg"
                        text="Психодемия"
                        text1="Обучение супервизии"
                        text2="2024 г."
                        text3="190 часов"
                      />
                      <Bullet
                        className="bullet-instance"
                        line="/img/line-8-33.svg"
                        text="IFS Institute"
                        text1="IFS Level 1 Training"
                        text2="2023-2024 гг."
                        text3="90 часов"
                      />
                      <div className="frame-30">
                        <div className="frame-31">
                          <div className="frame-32">
                            <div className="text-wrapper-46">Loch Kelly</div>
                            <p className="text-wrapper-47">
                              продвинутые семинары в Effortless Mindfulness и IFS по темам «Effortless Mindfulness and
                              Self-Leadership», «How To Shift Out of Ego Into Awake Self»
                            </p>
                          </div>
                          <div className="frame-33">
                            <div className="text-wrapper-48">2023-2024 гг.</div>
                            <div className="text-wrapper-49">90 часов</div>
                          </div>
                        </div>
                        <img className="line-10" alt="Line" src="/img/line-8-33.svg" />
                      </div>
                      <Bullet
                        className="bullet-instance"
                        line="/img/line-8-39.svg"
                        text="Bruce Hersey"
                        text1="IFS-informed EMDR 1−3"
                        text2="2024 г."
                        text3=" 6 часов"
                      />
                    </>
                  )}
                </div>}
                <div
                  className="button-wrapper"
                  style={{
                    alignItems:
                      screenWidth >= 480 && screenWidth < 768
                        ? "center"
                        : screenWidth >= 960 && screenWidth < 1280
                        ? "flex-start"
                        : undefined,
                    justifyContent: screenWidth >= 480 && screenWidth < 768 ? "center" : undefined,
                  }}
                >
                  <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                    <img
                      className="button-4"
                      style={{
                        flex: screenWidth >= 480 && screenWidth < 768 ? "0 0 auto" : undefined,
                        width: screenWidth >= 960 && screenWidth < 1280 ? "412px" : undefined,
                      }}
                      alt="Button"
                      src={
                        screenWidth >= 480 && screenWidth < 768
                          ? "/img/button-18.svg"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "/img/button-13.svg"
                          : undefined
                      }
                    />
                  </a>
                </div>
              </>
            )}

            {screenWidth >= 1280 && (
              <>
                <div className="overlap-14">
                  <div className="pattern-3">
                    <div className="overlap-group-12">
                      <img className="union-10" alt="Union" src="/img/union.svg" />
                      <div 
                        className="scroll-up" 
                        onClick={() => window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                          block: 'start'
                      })}>
                        <img className="rectangle-36" alt="Rectangle" src="/img/rectangle-83.svg" />
                      </div>
                    </div>
                  </div>
                  <Header className="header-instance" onTabClick={scrollToSection} />
                </div>
                <div className="view-9" id="contacts">
                  <div className="view-10">
                    <div className="text-wrapper-50">ПОЗНАКОМИТЬСЯ ПОБЛИЖЕ</div>
                  </div>
                  <div className="btns">
                    <a href="https://t.me/wakeuptoyourlife" target="_blank" rel="noopener noreferrer">
                      <HierarchySecondary text="МОЙ ТЕЛЕГРАМ-КАНАЛ" visible={false} preElement="telega"/>
                    </a>
                  <a href="https://www.instagram.com/clara.zhikhareva/" target="_blank" rel="noopener noreferrer">
                    <HierarchySecondary text="МОЙ ИНСТАГРАМ" visible={false} preElement="insta"/>
                  </a>
                  </div>
                </div>
                <div className="view-11">
                  <div className="text-wrapper-50">МОИ СТАТЬИ</div>
                  <div className="view-12">
                    <div className="frame-34">
                      <div className="frame-31">
                        <div className="text-wrapper-51">Придираться к себе</div>
                        <a href="https://teletype.in/@clara.zhikhareva/lFm-z3jy0cI" target="_blank" rel="noopener noreferrer">
                          <HierarchyTertiary
                            className="instance-node-2"
                            frame="/img/frame-84-1.svg"
                            text="ЧИТАТЬ СТАТЬЮ"
                          />
                        </a>
                      </div>
                      <div className="flexcontainer">
                        <p className="div-4">
                          <span className="span">
                            «Лучшая версия себя» — это личная эволюция или способ оправдывать чужие ожидания?
                            <br />
                          </span>
                        </p>
                        <p className="div-4">
                          <span className="span">
                            Поделилась своим видением того, как мы ломаем сами себе жизнь с помощью освоенного
                            в совершенстве навыка придираться к себе.
                          </span>
                        </p>
                      </div>
                    </div>
                    <img className="line-11" alt="Line" src="/img/line-52.svg" />
                    <div className="frame-34">
                      <div className="frame-31">
                        <div className="text-wrapper-51">Отложенная жизнь</div>
                        <a href="https://teletype.in/@clara.zhikhareva/2KjbRVfpOMk" target="_blank" rel="noopener noreferrer">
                          <HierarchyTertiary
                            className="instance-node-2"
                            frame="/img/frame-84-1.svg"
                            text="ЧИТАТЬ СТАТЬЮ"
                          />
                        </a>
                      </div>
                      <div className="flexcontainer-2">
                        <p className="div-4">
                          <span className="span">
                            Как двигаются по жизни по-настоящему счастливые люди? <br />
                          </span>
                        </p>
                        <p className="div-4">
                          <span className="span">
                            Стремятся ли они поскорее попасть на конечную станцию, не замечая ничего вокруг? Летят ли
                            вперед в погоне за иллюзорными целями? Или же наслаждаются опытом проживания истинной живой
                            Жизни, видя красоту в каждом моменте?
                          </span>
                        </p>
                      </div>
                    </div>
                    <img className="line-11" alt="Line" src="/img/line-52.svg" />
                    <div className="frame-34">
                      <div className="frame-31">
                        <p className="text-wrapper-52">Мир людей, у которых горят глаза</p>
                        <a href="https://teletype.in/@clara.zhikhareva/RBdi14WjP1z" target="_blank" rel="noopener noreferrer">
                          <HierarchyTertiary
                            className="instance-node-2"
                            frame="/img/frame-84-1.svg"
                            text="ЧИТАТЬ СТАТЬЮ"
                          />
                        </a>
                      </div>
                      <p className="div-4">
                        Здесь о мире, в котором я хочу жить. И о том, какие качество отличает Живых людей от биороботов,
                        механически двигающихся от задачи к задаче по чужим рельсам.
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {(screenWidth >= 1280 ||
          (screenWidth >= 480 && screenWidth < 768) ||
          (screenWidth >= 960 && screenWidth < 1280) ||
          screenWidth < 480) && (
          <div
            id="materials"
            className="view-13"
            style={{
              height:
                screenWidth < 480
                  ? "244px"
                  : screenWidth >= 480 && screenWidth < 768
                  ? "1352px"
                  : screenWidth >= 960 && screenWidth < 1280
                  ? "1179px"
                  : screenWidth >= 1280
                  ? "437px"
                  : undefined,
              left:
                screenWidth < 480
                  ? "22px"
                  : (screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)
                  ? "20px"
                  : screenWidth >= 1280
                  ? "50px"
                  : undefined,
              top:
                screenWidth < 480
                  ? "1220px"
                  : screenWidth >= 480 && screenWidth < 768
                  ? (isDetailsOpen && isAlchemyOpen ? "3576px" : !isDetailsOpen && isAlchemyOpen ? "2321px" : isDetailsOpen && !isAlchemyOpen ? "3570px" : "2321px")
                  : screenWidth >= 960 && screenWidth < 1280
                  ? (isDetailsOpen && isAlchemyOpen ? "4156px" : isDetailsOpen && !isAlchemyOpen ? "4138px" : !isDetailsOpen && isAlchemyOpen ? "2400px" : "2400px")
                  : screenWidth >= 1280
                  ? (isDetailsOpen && isAlchemyOpen ? "8178px" : isDetailsOpen && !isAlchemyOpen ? "7638px" : !isDetailsOpen && isAlchemyOpen ? "6478px" : "5848px")
                  : undefined,
              width:
                screenWidth < 480
                  ? "320px"
                  : screenWidth >= 480 && screenWidth < 768
                  ? "460px"
                  : screenWidth >= 960 && screenWidth < 1280
                  ? "940px"
                  : screenWidth >= 1280
                  ? "1279px"
                  : undefined,
            }}
          >
            {screenWidth < 480 && (
              <>
                <div className="text-wrapper-53">Посмотрите короткое приветственное видео</div>
                <div className="player-2">
                  <ReactPlayer 
                    light
                    url='https://youtu.be/L77qR3M1SfM'
                    width='100%'
                    height='100%'
                    playing
                  />
                </div>
              </>
            )}

            {screenWidth >= 1280 && (
              <div className="union-wrapper">
                <img className="union-11" alt="Union" src="/img/union-1.svg" />
              </div>
            )}

            {(screenWidth >= 1280 ||
              (screenWidth >= 480 && screenWidth < 768) ||
              (screenWidth >= 960 && screenWidth < 1280)) && (
              <div
                className="view-14"
                style={{
                  height:
                    screenWidth >= 480 && screenWidth < 768
                      ? "1352px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "1179px"
                      : screenWidth >= 1280
                      ? "411px"
                      : undefined,
                  top:
                    (screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)
                      ? "0"
                      : screenWidth >= 1280
                      ? "26px"
                      : undefined,
                  width:
                    screenWidth >= 480 && screenWidth < 768
                      ? "445px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "924px"
                      : screenWidth >= 1280
                      ? "1182px"
                      : undefined,
                }}
              >
                {((screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)) && (
                  <>
                    <div
                      className="text-wrapper-54"
                      style={{
                        fontSize:
                          screenWidth >= 480 && screenWidth < 768
                            ? "32px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "48px"
                            : undefined,
                        lineHeight:
                          screenWidth >= 480 && screenWidth < 768
                            ? "38.4px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "57.6px"
                            : undefined,
                        width: screenWidth >= 480 && screenWidth < 768 ? "285px" : undefined,
                      }}
                    >
                      НАЙДЁМ ВЫХОД <br />
                      ИЗ ЛАБИРИНТА СТРАДАНИЙ
                    </div>
                    <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                      <img
                        className="button-5"
                        style={{
                          height:
                            screenWidth >= 480 && screenWidth < 768
                              ? "64px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "68px"
                              : undefined,
                          left:
                            screenWidth >= 480 && screenWidth < 768
                              ? "38px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "470px"
                              : undefined,
                          top:
                            screenWidth >= 480 && screenWidth < 768
                              ? "1288px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "1111px"
                              : undefined,
                          width:
                            screenWidth >= 480 && screenWidth < 768
                              ? "363px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "400px"
                              : undefined,
                        }}
                        alt="Button"
                        src={
                          screenWidth >= 480 && screenWidth < 768
                            ? "/img/button-19.svg"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "/img/button-14.svg"
                            : undefined
                        }
                      />
                    </a>
                    <div
                      className="pattern-4"
                      style={{
                        left:
                          screenWidth >= 480 && screenWidth < 768
                            ? "148px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "154px"
                            : undefined,
                        top:
                          screenWidth >= 480 && screenWidth < 768
                            ? "991px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "776px"
                            : undefined,
                      }}
                    >
                      <div className="overlap-group-13">
                        <img className="polygon-6" alt="Polygon" src="/img/polygon-3-2.svg" />
                        <img
                          className="polygon-7"
                          alt="Polygon"
                          src={
                            screenWidth >= 480 && screenWidth < 768
                              ? "/img/polygon-5-3.svg"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "/img/polygon-5-2.svg"
                              : undefined
                          }
                        />
                        <img className="polygon-8" alt="Polygon" src="/img/polygon-4-2.svg" />
                        <img className="vector-8" alt="Vector" src="/img/vector-32-2.svg" />
                        <img className="vector-9" alt="Vector" src="/img/vector-33-2.svg" />
                        <img className="vector-10" alt="Vector" src="/img/vector-34-2.svg" />
                      </div>
                    </div>
                    <p
                      className="text-wrapper-55"
                      style={{
                        fontFamily:
                          screenWidth >= 480 && screenWidth < 768
                            ? "'DM Sans', Helvetica"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-quotes-font-family)"
                            : undefined,
                        fontSize:
                          screenWidth >= 480 && screenWidth < 768
                            ? "18px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-quotes-font-size)"
                            : undefined,
                        fontStyle:
                          screenWidth >= 480 && screenWidth < 768
                            ? "italic"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-quotes-font-style)"
                            : undefined,
                        fontWeight:
                          screenWidth >= 480 && screenWidth < 768
                            ? "400"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-quotes-font-weight)"
                            : undefined,
                        left:
                          screenWidth >= 480 && screenWidth < 768
                            ? "44px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "64px"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 480 && screenWidth < 768
                            ? "0"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-quotes-letter-spacing)"
                            : undefined,
                        lineHeight:
                          screenWidth >= 480 && screenWidth < 768
                            ? "21.6px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "var(--1280-quotes-line-height)"
                            : undefined,
                        top:
                          screenWidth >= 480 && screenWidth < 768
                            ? "1158px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "959px"
                            : undefined,
                        width:
                          screenWidth >= 480 && screenWidth < 768
                            ? "396px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "580px"
                            : undefined,
                      }}
                    >
                      Я сопровождаю вас в процессе ваших глубинных изменений.&nbsp;&nbsp;Вместе мы идем в исследование
                      механизмов психики и слой за слоем снимаем пелену иллюзий, отделяющую вас от себя настоящего.
                    </p>
                    <div
                      className="quote-6"
                      style={{
                        height:
                          screenWidth >= 480 && screenWidth < 768
                            ? "18px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "32px"
                            : undefined,
                        top:
                          screenWidth >= 480 && screenWidth < 768
                            ? "1145px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "932px"
                            : undefined,
                        width:
                          screenWidth >= 480 && screenWidth < 768
                            ? "24px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "44px"
                            : undefined,
                      }}
                    >
                      <img
                        className="union-12"
                        style={{
                          height:
                            screenWidth >= 480 && screenWidth < 768
                              ? "18px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "32px"
                              : undefined,
                          left:
                            screenWidth >= 480 && screenWidth < 768
                              ? "13px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "24px"
                              : undefined,
                          width:
                            screenWidth >= 480 && screenWidth < 768
                              ? "11px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "20px"
                              : undefined,
                        }}
                        alt="Union"
                        src={
                          screenWidth >= 480 && screenWidth < 768
                            ? "/img/union-37.svg"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "/img/union-3.svg"
                            : undefined
                        }
                      />
                      <img
                        className="union-13"
                        style={{
                          height:
                            screenWidth >= 480 && screenWidth < 768
                              ? "18px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "32px"
                              : undefined,
                          width:
                            screenWidth >= 480 && screenWidth < 768
                              ? "11px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "20px"
                              : undefined,
                        }}
                        alt="Union"
                        src={
                          screenWidth >= 480 && screenWidth < 768
                            ? "/img/union-38.svg"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "/img/union-3.svg"
                            : undefined
                        }
                      />
                    </div>
                    <div
                      className="view-15"
                      style={{
                        left:
                          screenWidth >= 480 && screenWidth < 768
                            ? "20px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "470px"
                            : undefined,
                        padding:
                          screenWidth >= 480 && screenWidth < 768
                            ? "30px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "40px"
                            : undefined,
                        top:
                          screenWidth >= 480 && screenWidth < 768
                            ? "772px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "651px"
                            : undefined,
                        width:
                          screenWidth >= 480 && screenWidth < 768
                            ? "420px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "450px"
                            : undefined,
                      }}
                    >
                      <div className="frame-31">
                        <div
                          className="text-wrapper-56"
                          style={{
                            fontFamily:
                              screenWidth >= 480 && screenWidth < 768
                                ? "'Lora', Helvetica"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-font-family)"
                                : undefined,
                            fontSize:
                              screenWidth >= 480 && screenWidth < 768
                                ? "20px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-font-size)"
                                : undefined,
                            fontStyle:
                              screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-h5-font-style)" : undefined,
                            fontWeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "600"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-font-weight)"
                                : undefined,
                            letterSpacing:
                              screenWidth >= 480 && screenWidth < 768
                                ? "0"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-letter-spacing)"
                                : undefined,
                            lineHeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "24.0px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-line-height)"
                                : undefined,
                          }}
                        >
                          Построение отношений
                        </div>
                        <img className="star-13" alt="Star" src="/img/star-17.svg" />
                      </div>
                      <p
                        className="text-wrapper-57"
                        style={{
                          fontFamily:
                            screenWidth >= 480 && screenWidth < 768
                              ? "'DM Sans', Helvetica"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-font-family)"
                              : undefined,
                          fontSize:
                            screenWidth >= 480 && screenWidth < 768
                              ? "16px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-font-size)"
                              : undefined,
                          fontStyle:
                            screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "400"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-font-weight)"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 480 && screenWidth < 768
                              ? "0"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-letter-spacing)"
                              : undefined,
                          lineHeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "19.2px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-line-height)"
                              : undefined,
                        }}
                      >
                        Вы научитесь создавать гармоничные отношения (в том числе сексуальные), выходить из
                        эмоциональной зависимости и экологично проживать кризисы и расставания.
                      </p>
                    </div>
                    <div
                      className="view-16"
                      style={{
                        padding:
                          screenWidth >= 480 && screenWidth < 768
                            ? "30px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "40px"
                            : undefined,
                        top:
                          screenWidth >= 480 && screenWidth < 768
                            ? "134px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "156px"
                            : undefined,
                        width:
                          screenWidth >= 480 && screenWidth < 768
                            ? "420px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "480px"
                            : undefined,
                      }}
                    >
                      <div className="frame-31">
                        <div
                          className="text-wrapper-58"
                          style={{
                            fontFamily:
                              screenWidth >= 480 && screenWidth < 768
                                ? "'Lora', Helvetica"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-font-family)"
                                : undefined,
                            fontSize:
                              screenWidth >= 480 && screenWidth < 768
                                ? "20px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-font-size)"
                                : undefined,
                            fontStyle:
                              screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-h5-font-style)" : undefined,
                            fontWeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "600"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-font-weight)"
                                : undefined,
                            letterSpacing:
                              screenWidth >= 480 && screenWidth < 768
                                ? "0"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-letter-spacing)"
                                : undefined,
                            lineHeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "24.0px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-line-height)"
                                : undefined,
                          }}
                        >
                          Глубинное самоисследование
                        </div>
                        <img className="star-13" alt="Star" src="/img/star-17.svg" />
                      </div>
                      <p
                        className="text-wrapper-59"
                        style={{
                          fontFamily:
                            screenWidth >= 480 && screenWidth < 768
                              ? "'DM Sans', Helvetica"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-font-family)"
                              : undefined,
                          fontSize:
                            screenWidth >= 480 && screenWidth < 768
                              ? "16px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-font-size)"
                              : undefined,
                          fontStyle:
                            screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "400"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-font-weight)"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 480 && screenWidth < 768
                              ? "0"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-letter-spacing)"
                              : undefined,
                          lineHeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "19.2px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-line-height)"
                              : undefined,
                        }}
                      >
                        Вы сможете встать на свой путь в жизни, найти ответ на вопрос «Кто Я?», выйти из кризиса
                        смыслов, глубже познакомиться с собой истинным и ощутить подлинную связь с миром.
                      </p>
                    </div>
                    <div
                      className="view-17"
                      style={{
                        left:
                          screenWidth >= 480 && screenWidth < 768
                            ? "0"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "64px"
                            : undefined,
                        padding:
                          screenWidth >= 480 && screenWidth < 768
                            ? "30px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "40px"
                            : undefined,
                        top:
                          screenWidth >= 480 && screenWidth < 768
                            ? "572px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "464px"
                            : undefined,
                        width:
                          screenWidth >= 480 && screenWidth < 768
                            ? "420px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "380px"
                            : undefined,
                      }}
                    >
                      <div
                        className="frame-35"
                        style={{
                          gap: screenWidth >= 960 && screenWidth < 1280 ? "73px" : undefined,
                          justifyContent: screenWidth >= 480 && screenWidth < 768 ? "space-between" : undefined,
                        }}
                      >
                        <div
                          className="text-wrapper-60"
                          style={{
                            fontFamily:
                              screenWidth >= 480 && screenWidth < 768
                                ? "'Lora', Helvetica"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-font-family)"
                                : undefined,
                            fontSize:
                              screenWidth >= 480 && screenWidth < 768
                                ? "20px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-font-size)"
                                : undefined,
                            fontStyle:
                              screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-h5-font-style)" : undefined,
                            fontWeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "600"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-font-weight)"
                                : undefined,
                            letterSpacing:
                              screenWidth >= 480 && screenWidth < 768
                                ? "0"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-letter-spacing)"
                                : undefined,
                            lineHeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "24.0px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-line-height)"
                                : undefined,
                            whiteSpace: screenWidth >= 480 && screenWidth < 768 ? "nowrap" : undefined,
                            width:
                              screenWidth >= 480 && screenWidth < 768
                                ? "fit-content"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "203px"
                                : undefined,
                          }}
                        >
                          Эмоциональная регуляция
                        </div>
                        <img className="star-13" alt="Star" src="/img/star-17.svg" />
                      </div>
                      <p
                        className="text-wrapper-61"
                        style={{
                          fontFamily:
                            screenWidth >= 480 && screenWidth < 768
                              ? "'DM Sans', Helvetica"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-font-family)"
                              : undefined,
                          fontSize:
                            screenWidth >= 480 && screenWidth < 768
                              ? "16px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-font-size)"
                              : undefined,
                          fontStyle:
                            screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "400"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-font-weight)"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 480 && screenWidth < 768
                              ? "0"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-letter-spacing)"
                              : undefined,
                          lineHeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "19.2px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-line-height)"
                              : undefined,
                        }}
                      >
                        Вы постепенно выстроите экологичные отношения со своими эмоциями: исцелите депрессию, апатию,
                        страхи или фоновую тревогу и вернёте себе душевный покой.
                      </p>
                    </div>
                    <div
                      className="view-18"
                      style={{
                        left:
                          screenWidth >= 480 && screenWidth < 768
                            ? "20px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "500px"
                            : undefined,
                        padding:
                          screenWidth >= 480 && screenWidth < 768
                            ? "30px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "40px"
                            : undefined,
                        top:
                          screenWidth >= 480 && screenWidth < 768
                            ? "353px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "299px"
                            : undefined,
                        width:
                          screenWidth >= 480 && screenWidth < 768
                            ? "420px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "367px"
                            : undefined,
                      }}
                    >
                      <div
                        className="frame-36"
                        style={{
                          gap: screenWidth >= 960 && screenWidth < 1280 ? "65px" : undefined,
                          justifyContent: screenWidth >= 480 && screenWidth < 768 ? "space-between" : undefined,
                        }}
                      >
                        <div
                          className="text-wrapper-62"
                          style={{
                            fontFamily:
                              screenWidth >= 480 && screenWidth < 768
                                ? "'Lora', Helvetica"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-font-family)"
                                : undefined,
                            fontSize:
                              screenWidth >= 480 && screenWidth < 768
                                ? "20px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-font-size)"
                                : undefined,
                            fontStyle:
                              screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-h5-font-style)" : undefined,
                            fontWeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "600"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-font-weight)"
                                : undefined,
                            letterSpacing:
                              screenWidth >= 480 && screenWidth < 768
                                ? "0"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-letter-spacing)"
                                : undefined,
                            lineHeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "24.0px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-h5-line-height)"
                                : undefined,
                          }}
                        >
                          Самоценность
                        </div>
                        <img className="star-13" alt="Star" src="/img/star-17.svg" />
                      </div>
                      <p
                        className="text-wrapper-63"
                        style={{
                          alignSelf: screenWidth >= 480 && screenWidth < 768 ? "stretch" : undefined,
                          fontFamily:
                            screenWidth >= 480 && screenWidth < 768
                              ? "'DM Sans', Helvetica"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-font-family)"
                              : undefined,
                          fontSize:
                            screenWidth >= 480 && screenWidth < 768
                              ? "16px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-font-size)"
                              : undefined,
                          fontStyle:
                            screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "400"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-font-weight)"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 480 && screenWidth < 768
                              ? "0"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-letter-spacing)"
                              : undefined,
                          lineHeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "19.2px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-line-height)"
                              : undefined,
                          width: screenWidth >= 960 && screenWidth < 1280 ? "287px" : undefined,
                        }}
                      >
                        Вы попрощаетесь с синдромом самозванца, исцелите чувства вины и стыда, одиночества и ненужности,
                        приведёте в баланс самооценку и выстроите целостное ощущение себя в этом мире.
                      </p>
                    </div>
                    <div
                      className="pattern-5"
                      style={{
                        backgroundImage:
                          screenWidth >= 480 && screenWidth < 768
                            ? "url(/img/union-42.svg)"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "url(/img/union-31.svg)"
                            : undefined,
                        height:
                          screenWidth >= 480 && screenWidth < 768
                            ? "107px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "170px"
                            : undefined,
                        left:
                          screenWidth >= 480 && screenWidth < 768
                            ? "315px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "705px"
                            : undefined,
                        top:
                          screenWidth >= 480 && screenWidth < 768
                            ? "0"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "52px"
                            : undefined,
                        width:
                          screenWidth >= 480 && screenWidth < 768
                            ? "126px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "200px"
                            : undefined,
                      }}
                    />
                  </>
                )}

                {screenWidth >= 1280 && (
                  <>
                    <div className="text-2">
                      <p className="text-wrapper-64">Как расти в легкости через работу с подсознанием?</p>
                      <div className="frame-37">
                        <div className="frame-38">
                          <img className="star-2" alt="Star" src="/img/star-14.svg" />
                          <p className="text-wrapper-65">
                            формула квантовых изменений, которая избавит вас от хождения по кругу в постоянной тревоге
                            и неудовлетворённости жизнью
                          </p>
                        </div>
                        <div className="frame-38">
                          <img className="star-2" alt="Star" src="/img/star-14.svg" />
                          <p className="text-wrapper-65">
                            главный секрет лёгкого и комфортного роста без надрыва, достигаторства и откатов
                          </p>
                        </div>
                        <div className="frame-38">
                          <img className="star-2" alt="Star" src="/img/star-14.svg" />
                          <p className="text-wrapper-65">
                            моя технология выхода на новый уровень через трансформацию архитектуры своей внутренней
                            системы
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="video">
                      <ReactPlayer 
                        light
                        url='https://youtu.be/5440yZcZnEg'
                        width='100%'
                        height='100%'
                        playing
                      />
                    </div>
                    <div className="text-wrapper-50">ПОГРУЗИТЬСЯ ГЛУБЖЕ</div>
                  </>
                )}
              </div>
            )}

            {((screenWidth >= 480 && screenWidth < 768) ||
              (screenWidth >= 960 && screenWidth < 1280) ||
              screenWidth < 480) && (
              <img
                className="vector-11"
                style={{
                  height:
                    screenWidth >= 480 && screenWidth < 768
                      ? "270px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "991px"
                      : screenWidth < 480
                      ? "28px"
                      : undefined,
                  left:
                    screenWidth >= 480 && screenWidth < 768
                      ? "382px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "722px"
                      : screenWidth < 480
                      ? "242px"
                      : undefined,
                  top:
                    screenWidth >= 480 && screenWidth < 768
                      ? "1012px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "86px"
                      : screenWidth < 480
                      ? "10px"
                      : undefined,
                  width:
                    screenWidth >= 480 && screenWidth < 768
                      ? "78px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "218px"
                      : screenWidth < 480
                      ? "35px"
                      : undefined,
                }}
                alt="Vector"
                src={
                  screenWidth >= 480 && screenWidth < 768
                    ? "/img/vector-25-3.svg"
                    : screenWidth >= 960 && screenWidth < 1280
                    ? "/img/vector-25-2.svg"
                    : screenWidth < 480
                    ? "/img/vector-38-1.svg"
                    : undefined
                }
              />
            )}
          </div>
        )}

        {(screenWidth >= 1280 ||
          (screenWidth >= 480 && screenWidth < 768) ||
          (screenWidth >= 960 && screenWidth < 1280)) && (
          <>
            <div
              id="alchemy"
              className="overlap-15"
              style={{
                height:
                  screenWidth >= 480 && screenWidth < 768
                    ? "3807px"
                    : screenWidth >= 960 && screenWidth < 1280
                    ? "3473px"
                    : screenWidth >= 1280
                    ? "2415px"
                    : undefined,
                left:
                  screenWidth >= 480 && screenWidth < 768
                    ? "-23px"
                    : screenWidth >= 960 && screenWidth < 1280
                    ? "-155px"
                    : screenWidth >= 1280
                    ? "-127px"
                    : undefined,
                top:
                  screenWidth >= 480 && screenWidth < 768
                    ? (isDetailsOpen ? "4978px" : "3720px")
                    : screenWidth >= 960 && screenWidth < 1280
                    ? (isDetailsOpen ? "5405px" : "3688px")
                    : screenWidth >= 1280
                    ? (isDetailsOpen? "5809px" : "4000px")
                    : undefined,
                width:
                  screenWidth >= 480 && screenWidth < 768
                    ? "576px"
                    : screenWidth >= 1280 || (screenWidth >= 960 && screenWidth < 1280)
                    ? "1469px"
                    : undefined,
              }}
            >
              <div
                id="formats"
                className="view-19"
                style={{
                  height:
                    screenWidth >= 1280
                      ? "1282px"
                      : screenWidth >= 480 && screenWidth < 768
                      ? "1643px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "1432px"
                      : undefined,
                  left:
                    screenWidth >= 1280
                      ? "0"
                      : screenWidth >= 480 && screenWidth < 768
                      ? "43px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "175px"
                      : undefined,
                  top:
                    screenWidth >= 1280
                      ? isAlchemyOpen ? "1033px" : "503px"
                      : (screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)
                      ? "0"
                      : undefined,
                  width:
                    screenWidth >= 1280
                      ? "1469px"
                      : screenWidth >= 480 && screenWidth < 768
                      ? "442px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "922px"
                      : undefined,
                }}
              >
                {((screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)) && (
                  <div
                    className="text-wrapper-66"
                    style={{
                      fontFamily:
                        screenWidth >= 480 && screenWidth < 768
                          ? "'Lora', Helvetica"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h2-medium-font-family)"
                          : undefined,
                      fontSize:
                        screenWidth >= 480 && screenWidth < 768
                          ? "32px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h2-medium-font-size)"
                          : undefined,
                      fontStyle:
                        screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-h2-medium-font-style)" : undefined,
                      fontWeight:
                        screenWidth >= 480 && screenWidth < 768
                          ? "500"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h2-medium-font-weight)"
                          : undefined,
                      letterSpacing:
                        screenWidth >= 480 && screenWidth < 768
                          ? "0"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h2-medium-letter-spacing)"
                          : undefined,
                      lineHeight:
                        screenWidth >= 480 && screenWidth < 768
                          ? "38.4px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h2-medium-line-height)"
                          : undefined,
                      whiteSpace: screenWidth >= 960 && screenWidth < 1280 ? "nowrap" : undefined,
                      width: screenWidth >= 480 && screenWidth < 768 ? "440px" : undefined,
                    }}
                  >
                    АЛХИМИЯ ТРАНСФОРМАЦИИ
                  </div>
                )}

                <div
                  className="view-51"
                  style={{
                    height:
                      screenWidth >= 480 && screenWidth < 768
                        ? "547px"
                        : screenWidth >= 960 && screenWidth < 1280
                        ? "476px"
                        : screenWidth >= 1280
                        ? "1119px"
                        : undefined,
                    left:
                      (screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)
                        ? "0"
                        : screenWidth >= 1280
                        ? "127px"
                        : undefined,
                    position:
                      (screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)
                        ? "absolute"
                        : screenWidth >= 1280
                        ? "relative"
                        : undefined,
                    top:
                      screenWidth >= 480 && screenWidth < 768
                        ? "96px"
                        : screenWidth >= 960 && screenWidth < 1280
                        ? "100px"
                        : screenWidth >= 1280
                        ? "163px"
                        : undefined,
                    width:
                      screenWidth >= 480 && screenWidth < 768
                        ? "442px"
                        : screenWidth >= 960 && screenWidth < 1280
                        ? "922px"
                        : screenWidth >= 1280
                        ? "1280px"
                        : undefined,
                  }}
                >
                  {((screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)) && (
                    <div
                      className="frame-39"
                      style={{
                        alignItems:
                          screenWidth >= 960 && screenWidth < 1280
                            ? "center"
                            : screenWidth >= 480 && screenWidth < 768
                            ? "flex-start"
                            : undefined,
                        gap:
                          screenWidth >= 960 && screenWidth < 1280
                            ? "34px"
                            : screenWidth >= 480 && screenWidth < 768
                            ? "24px"
                            : undefined,
                        padding:
                          screenWidth >= 960 && screenWidth < 1280
                            ? "24px 34px 24px 40px"
                            : screenWidth >= 480 && screenWidth < 768
                            ? "24px"
                            : undefined,
                        width:
                          screenWidth >= 960 && screenWidth < 1280
                            ? "920px"
                            : screenWidth >= 480 && screenWidth < 768
                            ? "440px"
                            : undefined,
                      }}
                    >
                      <img className="star-13" alt="Star" src="/img/star-17.svg" />
                      <div
                        className="IFS-EMDR"
                        style={{
                          fontSize:
                            screenWidth >= 960 && screenWidth < 1280
                              ? "23px"
                              : screenWidth >= 480 && screenWidth < 768
                              ? "18px"
                              : undefined,
                          lineHeight:
                            screenWidth >= 960 && screenWidth < 1280
                              ? "27.6px"
                              : screenWidth >= 480 && screenWidth < 768
                              ? "21.6px"
                              : undefined,
                        }}
                      >
                        {screenWidth >= 960 && screenWidth < 1280 && (
                          <p className="text-wrapper-164">
                            В работе с клиентами я использую IFS-терапию с элементами EMDR — это психотерапия,
                            приводящая к трансформационным изменениям путем реконсолидации памяти.
                          </p>
                        )}

                        {screenWidth >= 480 && screenWidth < 768 && (
                          <p className="text-wrapper-164">
                            В работе с клиентами я использую IFS-терапию с элементами EMDR — это психотерапия,
                            приводящая к трансформационным изменениям путем реконсолидации памяти.
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                  {screenWidth >= 480 && screenWidth < 768 && (
                    <>
                      <div className="div-5">
                        <img className="img-2" alt="Img" src="/img/2.svg" />
                        <div className="text-wrapper-67">Ре-активация памяти</div>
                      </div>
                      <div className="div-6">
                        <div className="img-2">
                          <div className="overlap-16">
                            <img className="rectangle-37" alt="Rectangle" src="/img/rectangle-74-3.svg" />
                            <img className="vector-12" alt="Vector" src="/img/vector-37-3.svg" />
                            <div className="group-10">
                              <div className="overlap-group-14">
                                <img className="vector-13" alt="Vector" src="/img/vector-27-3.svg" />
                                <div className="rectangle-38" />
                              </div>
                              <div className="rectangle-39" />
                            </div>
                            <img className="vector-14" alt="Vector" src="/img/vector-3.svg" />
                          </div>
                        </div>
                        <div className="text-wrapper-67">Не-подтверждение данных</div>
                      </div>
                      <div className="div-7">
                        <img className="img-2" alt="Img" src="/img/2-2.svg" />
                        <div className="text-wrapper-67">Ре-консолидация памяти</div>
                      </div>
                    </>
                  )}

                  {screenWidth >= 1280 && (
                    <>
                      <img className="union-14" alt="Union" src="/img/union-2.svg" />
                      <img className="vector-15" alt="Vector" src="/img/vector-23.svg" />
                      <div className="text-wrapper-68">
                        ФОРМАТЫ <br />
                        ВЗАИМОДЕЙСТВИЯ
                      </div>
                    </>
                  )}

                  {(screenWidth >= 1280 || (screenWidth >= 960 && screenWidth < 1280)) && (
                    <div
                      className="tarif"
                      style={{
                        height:
                          screenWidth >= 1280
                            ? "686px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "122px"
                            : undefined,
                        left: screenWidth >= 1280 ? "50px" : screenWidth >= 960 && screenWidth < 1280 ? "0" : undefined,
                        top:
                          screenWidth >= 1280
                            ? "207px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "186px"
                            : undefined,
                        width:
                          screenWidth >= 1280
                            ? "497px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "920px"
                            : undefined,
                      }}
                    >
                      <div
                        className="overlap-17"
                        style={{
                          alignItems: screenWidth >= 960 && screenWidth < 1280 ? "center" : undefined,
                          display: screenWidth >= 960 && screenWidth < 1280 ? "flex" : undefined,
                          flexWrap: screenWidth >= 960 && screenWidth < 1280 ? "wrap" : undefined,
                          height: screenWidth >= 1280 ? "686px" : undefined,
                          justifyContent: screenWidth >= 960 && screenWidth < 1280 ? "space-between" : undefined,
                          width: screenWidth >= 960 && screenWidth < 1280 ? "920px" : undefined,
                        }}
                      >
                        {screenWidth >= 1280 && (
                          <>
                            <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                              <img className="button-6" alt="Button" src="/img/button.svg" />
                            </a>
                            <div className="content">
                              <div className="text-wrapper-69">Для клиентов</div>
                              <div className="frame-40">
                                <div className="frame-41">
                                  <div className="star-wrapper">
                                    <img className="star-9" alt="Star" src="/img/star-22.svg" />
                                  </div>
                                  <p className="text-wrapper-70">вы найдёте ключи к своей внутренней системе</p>
                                </div>
                                <div className="frame-41">
                                  <div className="star-wrapper">
                                    <img className="star-9" alt="Star" src="/img/star-22.svg" />
                                  </div>
                                  <p className="text-wrapper-70">
                                    ослабите блоки, мешающие раскрытию потенциала, заложенного при рождении
                                  </p>
                                </div>
                                <div className="frame-41">
                                  <div className="star-wrapper">
                                    <img className="star-9" alt="Star" src="/img/star-22.svg" />
                                  </div>
                                  <p className="text-wrapper-70">
                                    научитесь понимать, что происходит у вас внутри, и как эффективно и бережно
                                    возвращаться в ресурсное состояние
                                  </p>
                                </div>
                                <div className="frame-41">
                                  <div className="star-wrapper">
                                    <img className="star-9" alt="Star" src="/img/star-22.svg" />
                                  </div>
                                  <p className="text-wrapper-70">
                                    получите навыки, которые позволят вам стать психотерапевтом самому себе
                                  </p>
                                </div>
                              </div>
                              <p className="div-8">8000 ₽ / 50 минут</p>
                            </div>
                            <div className="arc-2">
                              <div className="overlap-group-15">
                                <img className="rectangle-40" alt="Rectangle" src="/img/rectangle-44.svg" />
                                <img className="rectangle-41" alt="Rectangle" src="/img/rectangle-56.svg" />
                                <img className="line-12" alt="Line" src="/img/line-9.svg" />
                                <div className="ellipse-26" />
                                <div className="ellipse-27" />
                                <div className="ellipse-28" />
                              </div>
                            </div>
                            <div className="eye">
                              <div className="overlap-18">
                                <img className="subtract-11" alt="Subtract" src="/img/subtract-2.svg" />
                                <img className="subtract-12" alt="Subtract" src="/img/subtract-3.svg" />
                                <img className="rectangle-42" alt="Rectangle" src="/img/rectangle-63.svg" />
                                <img className="subtract-13" alt="Subtract" src="/img/subtract-4.svg" />
                              </div>
                            </div>
                          </>
                        )}

                        {screenWidth >= 960 && screenWidth < 1280 && (
                          <>
                            <div className="div-9">
                              <img className="img-3" alt="Img" src="/img/image.svg" />
                              <div className="text-wrapper-71">Ре-активация памяти</div>
                            </div>
                            <img className="line-13" alt="Line" src="/img/line-66-1.svg" />
                            <div className="div-9">
                              <div className="frame-42">
                                <div className="overlap-19">
                                  <img className="rectangle-43" alt="Rectangle" src="/img/rectangle-74-2.svg" />
                                  <img className="vector-16" alt="Vector" src="/img/vector-37-2.svg" />
                                  <div className="group-11">
                                    <div className="overlap-group-16">
                                      <img className="vector-17" alt="Vector" src="/img/vector-27-2.svg" />
                                      <div className="rectangle-44" />
                                    </div>
                                    <div className="rectangle-45" />
                                  </div>
                                  <img className="vector-18" alt="Vector" src="/img/vector-2.svg" />
                                </div>
                              </div>
                              <div className="text-wrapper-72">Не-подтверждение данных</div>
                            </div>
                            <img className="line-13" alt="Line" src="/img/line-67-1.svg" />
                            <div className="div-9">
                              <img className="img-3" alt="Img" src="/img/image-2.svg" />
                              <div className="text-wrapper-71">Ре-консолидация памяти</div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {screenWidth >= 1280 && (
                    <>
                      <div className="tarif-2">
                        <div className="overlap-group-15">
                          <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                            <img className="button-7" alt="Button" src="/img/button-1.svg" />
                          </a>
                          <div className="content-2">
                            <div className="text-wrapper-69">Для психологов</div>
                            <div className="frame-40">
                              <div className="frame-41">
                                <div className="star-wrapper">
                                  <img className="star-9" alt="Star" src="/img/star-22.svg" />
                                </div>
                                <p className="text-wrapper-70">
                                  я становлюсь вашим супервизором-наставником на пути развития как психотерапевта
                                </p>
                              </div>
                              <div className="frame-41">
                                <div className="star-wrapper">
                                  <img className="star-9" alt="Star" src="/img/star-22.svg" />
                                </div>
                                <p className="text-wrapper-70">
                                  вы проанализируете разные грани вашей работы с клиентами и выведете частную практику
                                  на новый уровень
                                </p>
                              </div>
                              <div className="frame-43">
                                <div className="frame-41">
                                  <div className="star-wrapper">
                                    <img className="star-9" alt="Star" src="/img/star-22-6.svg" />
                                  </div>
                                  <div className="text-wrapper-70">расширите терапевтическое мышление</div>
                                </div>
                                <div className="frame-41">
                                  <div className="star-wrapper">
                                    <img className="star-9" alt="Star" src="/img/star-22-6.svg" />
                                  </div>
                                  <div className="text-wrapper-70">повысите уровень компетенций</div>
                                </div>
                                <div className="frame-41">
                                  <div className="star-wrapper">
                                    <img className="star-9" alt="Star" src="/img/star-22-6.svg" />
                                  </div>
                                  <p className="text-wrapper-70">выявите слепые пятна и зоны роста</p>
                                </div>
                              </div>
                            </div>
                            <p className="div-8">6000 ₽ / 50 минут</p>
                          </div>
                          <div className="arc-2">
                            <div className="overlap-group-15">
                              <img className="rectangle-40" alt="Rectangle" src="/img/rectangle-44-1.svg" />
                              <img className="rectangle-41" alt="Rectangle" src="/img/rectangle-56-1.svg" />
                              <img className="line-14" alt="Line" src="/img/line-9-1.svg" />
                              <div className="ellipse-26" />
                              <div className="ellipse-27" />
                              <div className="ellipse-28" />
                            </div>
                          </div>
                          <div className="eye-2">
                            <div className="overlap-18">
                              <img className="subtract-11" alt="Subtract" src="/img/subtract-5.svg" />
                              <img className="subtract-12" alt="Subtract" src="/img/subtract-3.svg" />
                              <img className="rectangle-42" alt="Rectangle" src="/img/rectangle-63.svg" />
                              <img className="subtract-13" alt="Subtract" src="/img/subtract-4.svg" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="group-12">
                        <p className="text-wrapper-73">
                          Моя главная цель - сделать так, чтобы я вам больше не понадобилась.
                        </p>
                        <div className="quote-2">
                          <img className="union-4" alt="Union" src="/img/union-3.svg" />
                          <img className="union-5" alt="Union" src="/img/union-3.svg" />
                        </div>
                      </div>
                    </>
                  )}

                  {((screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)) && (
                    <p
                      className="text-wrapper-74"
                      style={{
                        fontSize:
                          screenWidth >= 960 && screenWidth < 1280
                            ? "18px"
                            : screenWidth >= 480 && screenWidth < 768
                            ? "16px"
                            : undefined,
                        lineHeight:
                          screenWidth >= 960 && screenWidth < 1280
                            ? "21.6px"
                            : screenWidth >= 480 && screenWidth < 768
                            ? "19.2px"
                            : undefined,
                        top:
                          screenWidth >= 960 && screenWidth < 1280
                            ? "344px"
                            : screenWidth >= 480 && screenWidth < 768
                            ? "357px"
                            : undefined,
                        width:
                          screenWidth >= 960 && screenWidth < 1280
                            ? "920px"
                            : screenWidth >= 480 && screenWidth < 768
                            ? "440px"
                            : undefined,
                      }}
                    >
                      Реконсолидация памяти - форма нейропластичности, которая изменяет существующую эмоциональную
                      память на синаптическом уровне (Ecker, 2012). Используя техники работы со вниманием, мы переводим
                      то, что записано у нас в бессознательной памяти, в открытое для редактирования состояние. Далее мы
                      подгружаем update: новый опыт или информацию, которая опровергает хранящиеся там убеждения. После
                      обновления нейронная сеть памяти вновь возвращается в исходное состояние.
                    </p>
                  )}

                  {screenWidth >= 480 && screenWidth < 768 && (
                    <>
                      <img className="vector-19" alt="Vector" src="/img/vector-43.svg" />
                      <img className="vector-20" alt="Vector" src="/img/vector-43.svg" />
                    </>
                  )}
                </div>
                {((screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)) && (
                  <div
                    className="view-20"
                    style={{
                      alignItems:
                        screenWidth >= 480 && screenWidth < 768
                          ? "center"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "flex-start"
                          : undefined,
                      gap:
                        screenWidth >= 480 && screenWidth < 768
                          ? "20px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "40px"
                          : undefined,
                      top:
                        screenWidth >= 480 && screenWidth < 768
                          ? "663px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "616px"
                          : undefined,
                      width:
                        screenWidth >= 480 && screenWidth < 768
                          ? "440px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "920px"
                          : undefined,
                    }}
                  >
                    <div
                      className="view-21"
                      style={{
                        alignSelf: screenWidth >= 480 && screenWidth < 768 ? "stretch" : undefined,
                        width:
                          screenWidth >= 480 && screenWidth < 768
                            ? "100%"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "918px"
                            : undefined,
                      }}
                    >
                      <div
                        className="text-wrapper-75"
                        style={{
                          fontFamily:
                            screenWidth >= 480 && screenWidth < 768
                              ? "'Lora', Helvetica"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-h3-small-font-family)"
                              : undefined,
                          fontSize:
                            screenWidth >= 480 && screenWidth < 768
                              ? "32px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-h3-small-font-size)"
                              : undefined,
                          fontStyle:
                            screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-h3-small-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "600"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-h3-small-font-weight)"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 480 && screenWidth < 768
                              ? "0"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-h3-small-letter-spacing)"
                              : undefined,
                          lineHeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "38.4px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-h3-small-line-height)"
                              : undefined,
                        }}
                      >
                        Узнать подробнее
                      </div>
                      <img className="frame-44" alt="Frame" src={isAlchemyOpen ? "/img/frame-83.svg" : "/img/dropdownArrow.svg"} onClick={toggleAlchemyDropdown}/>
                    </div>
                    {isAlchemyOpen && <>
                        <div
                        className="flexcontainer-3"
                        style={{
                          width: screenWidth >= 960 && screenWidth < 1280 ? "918px" : undefined,
                        }}
                      >
                        <div
                          className="span-wrapper"
                          style={{
                            fontFamily:
                              screenWidth >= 480 && screenWidth < 768
                                ? "'DM Sans', Helvetica"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-family)"
                                : undefined,
                            fontSize:
                              screenWidth >= 480 && screenWidth < 768
                                ? "16px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-size)"
                                : undefined,
                            fontStyle:
                              screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                            fontWeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "400"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-weight)"
                                : undefined,
                            letterSpacing:
                              screenWidth >= 480 && screenWidth < 768
                                ? "0"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-letter-spacing)"
                                : undefined,
                            lineHeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "19.2px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-line-height)"
                                : undefined,
                          }}
                        >
                          <span
                            className="span-2"
                            style={{
                              fontFamily:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "'DM Sans', Helvetica"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-family)"
                                  : undefined,
                              fontSize:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "16px"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-size)"
                                  : undefined,
                              fontStyle:
                                screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                              fontWeight:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "400"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-weight)"
                                  : undefined,
                              letterSpacing:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "0"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-letter-spacing)"
                                  : undefined,
                              lineHeight:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "19.2px"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-line-height)"
                                  : undefined,
                            }}
                          >
                            {screenWidth >= 480 && screenWidth < 768 && (
                              <>
                                Мы склонны считать, что сознательно решаем, как нам справляться с различными ситуациями и
                                проблемами, возникающими в повседневной жизни. Однако во многом наше поведение обусловлено
                                научением, хранящимся в бессознательной памяти. Когда вы едете на велосипеде, то вам не
                                нужно сознательно думать о том, как держать равновесие. Это происходит автоматически,
                                благодаря бессознательной памяти. В действительности, большинство способов, которыми мы
                                реагируем на вещи в течение дня, не являются когнитивными или сознательными. <br />
                              </>
                            )}

                            {screenWidth >= 960 && screenWidth < 1280 && (
                              <>
                                Мы склонны считать, что сознательно решаем, как нам справляться с различными ситуациями и
                                проблемами, возникающими в повседневной жизни. Однако во многом наше поведение обусловлено
                                научением, хранящимся в бессознательной памяти. Когда вы едете на велосипеде, то вам не
                                нужно сознательно думать о том, как держать равновесие. Это происходит автоматически,
                                благодаря бессознательной памяти. В действительности, большинство способов, которыми мы
                                реагируем на вещи в течение дня, не являются когнитивными или сознательными. <br />
                              </>
                            )}
                          </span>
                        </div>
                        <div
                          className="text-3"
                          style={{
                            fontFamily:
                              screenWidth >= 480 && screenWidth < 768
                                ? "'DM Sans', Helvetica"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-family)"
                                : undefined,
                            fontSize:
                              screenWidth >= 480 && screenWidth < 768
                                ? "16px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-size)"
                                : undefined,
                            fontStyle:
                              screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                            fontWeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "400"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-weight)"
                                : undefined,
                            letterSpacing:
                              screenWidth >= 480 && screenWidth < 768
                                ? "0"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-letter-spacing)"
                                : undefined,
                            lineHeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "19.2px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-line-height)"
                                : undefined,
                          }}
                        >
                          <span
                            className="span-3"
                            style={{
                              fontFamily:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "'DM Sans', Helvetica"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-family)"
                                  : undefined,
                              fontSize:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "16px"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-size)"
                                  : undefined,
                              fontStyle:
                                screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                              fontWeight:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "400"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-weight)"
                                  : undefined,
                              letterSpacing:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "0"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-letter-spacing)"
                                  : undefined,
                              lineHeight:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "19.2px"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-line-height)"
                                  : undefined,
                            }}
                          >
                            {screenWidth >= 480 && screenWidth < 768 && (
                              <>
                                Некоторые виды психотерапии в основном работают с сознательной памятью, взаимодействуя
                                с рациональным мышлением. Если вы когда-либо были разочарованы тем, что терапевтический
                                процесс, казалось бы, ходит по кругу — вы говорите о проблемах, вместо того чтобы на самом
                                деле что-то менять, то возможно, ваша терапия осталась на более сознательном уровне
                                обработки. Многие из этих видов психотерапии (например, КПТ-терапия) пытаются помочь вам
                                контролировать бессознательное научение и использовать логику и рациональность для выбора
                                нового поведения, противоречащего тому, что для вас привычно. <br />
                              </>
                            )}

                            {screenWidth >= 960 && screenWidth < 1280 && (
                              <>
                                Некоторые виды психотерапии в основном работают с сознательной памятью, взаимодействуя
                                с рациональным мышлением. Если вы когда-либо были разочарованы тем, что терапевтический
                                процесс, казалось бы, ходит по кругу — вы говорите о проблемах, вместо того чтобы на самом
                                деле что-то менять, то возможно, ваша терапия осталась на более сознательном уровне
                                обработки. Многие из этих видов психотерапии (например, КПТ-терапия) пытаются помочь вам
                                контролировать бессознательное научение и использовать логику и рациональность для выбора
                                нового поведения, противоречащего тому, что для вас привычно. <br />
                              </>
                            )}
                          </span>
                        </div>
                        <p
                          className="text-4"
                          style={{
                            fontFamily:
                              screenWidth >= 480 && screenWidth < 768
                                ? "'DM Sans', Helvetica"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-family)"
                                : undefined,
                            fontSize:
                              screenWidth >= 480 && screenWidth < 768
                                ? "16px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-size)"
                                : undefined,
                            fontStyle:
                              screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                            fontWeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "400"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-weight)"
                                : undefined,
                            letterSpacing:
                              screenWidth >= 480 && screenWidth < 768
                                ? "0"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-letter-spacing)"
                                : undefined,
                            lineHeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "19.2px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-line-height)"
                                : undefined,
                          }}
                        >
                          <span
                            className="text-wrapper-76"
                            style={{
                              fontFamily:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "'DM Sans', Helvetica"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-family)"
                                  : undefined,
                              fontSize:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "16px"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-size)"
                                  : undefined,
                              fontStyle:
                                screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                              fontWeight:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "400"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-weight)"
                                  : undefined,
                              letterSpacing:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "0"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-letter-spacing)"
                                  : undefined,
                              lineHeight:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "19.2px"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-line-height)"
                                  : undefined,
                            }}
                          >
                            IFS-терапия отличается — она предлагает способ взаимодействия с бессознательными паттернами,
                            к которым у нас обычно нет доступа. Здесь мы фактически реорганизуем память, поэтому люди
                            часто удивляются, насколько трансформационными могут быть IFS-сессии. Трудности или симптомы
                            нередко исчезают без необходимости постоянно поддерживать эти изменения, так как травма
                            исцеляется на глубинном уровне. Это может стать переломным моментом в жизни, особенно если
                            вы потратили годы на терапию, которая не приносила желаемых результатов.
                          </span>
                        </p>
                      </div>
                      <div
                        className="frame-45"
                        style={{
                          alignSelf: screenWidth >= 480 && screenWidth < 768 ? "stretch" : undefined,
                          gap:
                            screenWidth >= 480 && screenWidth < 768
                              ? "20px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "40px"
                              : undefined,
                          width:
                            screenWidth >= 480 && screenWidth < 768
                              ? "100%"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "918px"
                              : undefined,
                        }}
                      >
                        <div className="frame-31">
                          <div
                            className="frame-46"
                            style={{
                              marginRight: screenWidth >= 480 && screenWidth < 768 ? "-39.00px" : undefined,
                              width:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "479px"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "685px"
                                  : undefined,
                            }}
                          >
                            <div
                              className="text-wrapper-77"
                              style={{
                                fontFamily:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "'Lora', Helvetica"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "var(--1280-h4-font-family)"
                                    : undefined,
                                fontSize:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "22px"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "var(--1280-h4-font-size)"
                                    : undefined,
                                fontStyle:
                                  screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-h4-font-style)" : undefined,
                                fontWeight:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "600"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "var(--1280-h4-font-weight)"
                                    : undefined,
                                letterSpacing:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "0"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "var(--1280-h4-letter-spacing)"
                                    : undefined,
                                lineHeight:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "26.4px"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "var(--1280-h4-line-height)"
                                    : undefined,
                              }}
                            >
                              Ecker, B., Bridges, S.K.
                            </div>
                            <p
                              className="text-wrapper-78"
                              style={{
                                fontFamily:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "'DM Sans', Helvetica"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "var(--1280-text-font-family)"
                                    : undefined,
                                fontSize:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "16px"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "var(--1280-text-font-size)"
                                    : undefined,
                                fontStyle:
                                  screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                                fontWeight:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "400"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "var(--1280-text-font-weight)"
                                    : undefined,
                                letterSpacing:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "0"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "var(--1280-text-letter-spacing)"
                                    : undefined,
                                lineHeight:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "19.2px"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "var(--1280-text-line-height)"
                                    : undefined,
                              }}
                            >
                              How the Science of Memory Reconsolidation Advances the Effectiveness and Unification
                              of Psychotherapy
                            </p>
                          </div>
                          <div
                            className="frame-47"
                            style={{
                              marginRight: screenWidth >= 480 && screenWidth < 768 ? "-242.00px" : undefined,
                            }}
                          >
                            <div
                              className="text-wrapper-79"
                              style={{
                                fontFamily:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "'DM Sans', Helvetica"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "var(--1280-text-font-family)"
                                    : undefined,
                                fontSize:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "16px"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "var(--1280-text-font-size)"
                                    : undefined,
                                fontStyle:
                                  screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                                fontWeight:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "400"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "var(--1280-text-font-weight)"
                                    : undefined,
                                letterSpacing:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "0"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "var(--1280-text-letter-spacing)"
                                    : undefined,
                                lineHeight:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "19.2px"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "var(--1280-text-line-height)"
                                    : undefined,
                              }}
                            >
                              2020 г.
                            </div>
                            <div
                              className="clin-soc-work-j"
                              style={{
                                fontSize:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "16px"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "18px"
                                    : undefined,
                                lineHeight:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "19.2px"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "21.6px"
                                    : undefined,
                              }}
                            >
                              {screenWidth >= 480 && screenWidth < 768 && (
                                <p className="text-wrapper-164">
                                  Clin Soc Work J 48, <br />
                                  287–300
                                </p>
                              )}

                              {screenWidth >= 960 && screenWidth < 1280 && (
                                <p className="text-wrapper-164">Clin Soc Work J 48, 287–300</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <img
                          className="line-10"
                          alt="Line"
                          src={
                            screenWidth >= 480 && screenWidth < 768
                              ? "/img/line-6-3.svg"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "/img/line-8-40.svg"
                              : undefined
                          }
                        />
                      </div>
                    </>
                    }
                    {/* <HierarchySecondary
                      className={`${screenWidth >= 480 && screenWidth < 768 && "class-10"} ${
                        screenWidth >= 960 && screenWidth < 1280 && "instance-node-2"
                      }`}
                      divClassName={`${screenWidth >= 480 && screenWidth < 768 && "class-5"}`}
                      text="ИНФОРМАЦИЯ ПРО IFS"
                    /> */}
                  </div>
                )}
              </div>
              <div
                className="view-22"
                style={{
                  height:
                    screenWidth >= 480 && screenWidth < 768
                      ? "1638px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "1253px"
                      : screenWidth >= 1280
                      ? "1096px"
                      : undefined,
                  left:
                    (screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)
                      ? "0"
                      : screenWidth >= 1280
                      ? "177px"
                      : undefined,
                  top:
                    screenWidth >= 480 && screenWidth < 768
                      ? (isAlchemyOpen ? "1534px" : "700px")
                      : screenWidth >= 960 && screenWidth < 1280
                      ? (isAlchemyOpen ? "1239px" : "600px")
                      : screenWidth >= 1280
                      ? "0"
                      : undefined,
                  width:
                    screenWidth >= 480 && screenWidth < 768
                      ? "556px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "1469px"
                      : screenWidth >= 1280
                      ? "1182px"
                      : undefined,
                }}
              >
                {screenWidth >= 1280 && <div className="text-wrapper-50">АЛХИМИЯ ТРАНСФОРМАЦИИ</div>}

                <div
                  className="overlap-31"
                  style={{
                    height:
                      screenWidth >= 960 && screenWidth < 1280
                        ? "1090px"
                        : screenWidth >= 1280
                        ? "328px"
                        : screenWidth >= 480 && screenWidth < 768
                        ? "778px"
                        : undefined,
                    left:
                      screenWidth >= 960 && screenWidth < 1280
                        ? "155px"
                        : screenWidth >= 1280
                        ? "0"
                        : screenWidth >= 480 && screenWidth < 768
                        ? "23px"
                        : undefined,
                    position:
                      screenWidth >= 960 && screenWidth < 1280
                        ? "relative"
                        : screenWidth >= 1280 || (screenWidth >= 480 && screenWidth < 768)
                        ? "absolute"
                        : undefined,
                    top:
                      screenWidth >= 960 && screenWidth < 1280
                        ? "163px"
                        : screenWidth >= 1280
                        ? "100px"
                        : screenWidth >= 480 && screenWidth < 768
                        ? "785px"
                        : undefined,
                    width:
                      screenWidth >= 960 && screenWidth < 1280
                        ? "960px"
                        : screenWidth >= 1280
                        ? "1182px"
                        : screenWidth >= 480 && screenWidth < 768
                        ? "480px"
                        : undefined,
                  }}
                >
                  {((screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)) && (
                    <img
                      className="vector-21"
                      style={{
                        height:
                          screenWidth >= 480 && screenWidth < 768
                            ? "218px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "682px"
                            : undefined,
                        top:
                          screenWidth >= 480 && screenWidth < 768
                            ? "560px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "407px"
                            : undefined,
                        width:
                          screenWidth >= 480 && screenWidth < 768
                            ? "480px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "960px"
                            : undefined,
                      }}
                      alt="Vector"
                      src={
                        screenWidth >= 480 && screenWidth < 768
                          ? "/img/vector-26-2.svg"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "/img/union-23.svg"
                          : undefined
                      }
                    />
                  )}

                  {screenWidth >= 960 && screenWidth < 1280 && (
                    <>
                      <img className="vector-22" alt="Vector" src="/img/vector-23-2.svg" />
                      <div className="text-wrapper-80">
                        ФОРМАТЫ <br />
                        ВЗАИМОДЕЙСТВИЯ
                      </div>
                    </>
                  )}

                  {screenWidth >= 1280 && (
                    <div className="frame-48">
                      <img className="star-13" alt="Star" src="/img/star-17.svg" />
                      <p className="text-wrapper-81">
                        В работе с клиентами я использую IFS-терапию с элементами EMDR — это психотерапия, приводящая к
                        трансформационным изменениям путем реконсолидации памяти.
                      </p>
                    </div>
                  )}

                  <div
                    className="view-23"
                    style={{
                      height:
                        screenWidth >= 1280
                          ? "56px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "686px"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "603px"
                          : undefined,
                      left:
                        screenWidth >= 1280
                          ? "2px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "22px"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "38px"
                          : undefined,
                      top:
                        screenWidth >= 1280
                          ? "144px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "207px"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "0"
                          : undefined,
                      width:
                        screenWidth >= 1280
                          ? "1087px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "451px"
                          : screenWidth >= 480 && screenWidth < 768
                          ? "405px"
                          : undefined,
                    }}
                  >
                    {screenWidth >= 1280 && (
                      <>
                        {isAlchemyOpen ? <AlchemySchemeOpen /> : <AlchemySchemeClosed />}
                      </>
                    )}

                    {((screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)) && (
                      <div
                        className="overlap-20"
                        style={{
                          height:
                            screenWidth >= 960 && screenWidth < 1280
                              ? "686px"
                              : screenWidth >= 480 && screenWidth < 768
                              ? "603px"
                              : undefined,
                        }}
                      >
                        <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                          <img
                            className="button-8"
                            style={{
                              height:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "68px"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "64px"
                                  : undefined,
                              left:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "14px"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "44px"
                                  : undefined,
                              top:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "575px"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "502px"
                                  : undefined,
                              width:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "412px"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "311px"
                                  : undefined,
                            }}
                            alt="Button"
                            src={
                              screenWidth >= 960 && screenWidth < 1280
                                ? "/img/button-11.svg"
                                : screenWidth >= 480 && screenWidth < 768
                                ? "/img/button-17.svg"
                                : undefined
                            }
                          />
                        </a>
                        <div
                          className="content-3"
                          style={{
                            gap:
                              screenWidth >= 960 && screenWidth < 1280
                                ? "30px"
                                : screenWidth >= 480 && screenWidth < 768
                                ? "20px"
                                : undefined,
                            left:
                              screenWidth >= 960 && screenWidth < 1280
                                ? "22px"
                                : screenWidth >= 480 && screenWidth < 768
                                ? "32px"
                                : undefined,
                            top:
                              screenWidth >= 960 && screenWidth < 1280
                                ? "173px"
                                : screenWidth >= 480 && screenWidth < 768
                                ? "112px"
                                : undefined,
                            width:
                              screenWidth >= 960 && screenWidth < 1280
                                ? "412px"
                                : screenWidth >= 480 && screenWidth < 768
                                ? "340px"
                                : undefined,
                          }}
                        >
                          <div
                            className="div-13"
                            style={{
                              fontFamily:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-h4-font-family)"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "'Lora', Helvetica"
                                  : undefined,
                              fontSize:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-h4-font-size)"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "20px"
                                  : undefined,
                              fontStyle:
                                screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-h4-font-style)" : undefined,
                              fontWeight:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-h4-font-weight)"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "600"
                                  : undefined,
                              letterSpacing:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-h4-letter-spacing)"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "0"
                                  : undefined,
                              lineHeight:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-h4-line-height)"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "24.0px"
                                  : undefined,
                            }}
                          >
                            {screenWidth >= 960 && screenWidth < 1280 && <>Для клиентов</>}

                            {screenWidth >= 480 && screenWidth < 768 && <>Для психологов</>}
                          </div>
                          <div className="frame-40">
                            <div
                              className="frame-49"
                              style={{
                                gap:
                                  screenWidth >= 480 && screenWidth < 768
                                    ? "12px"
                                    : screenWidth >= 960 && screenWidth < 1280
                                    ? "16px"
                                    : undefined,
                              }}
                            >
                              <div className="star-wrapper">
                                <img
                                  className="star-16"
                                  style={{
                                    height:
                                      screenWidth >= 480 && screenWidth < 768
                                        ? "12px"
                                        : screenWidth >= 960 && screenWidth < 1280
                                        ? "14px"
                                        : undefined,
                                    width:
                                      screenWidth >= 480 && screenWidth < 768
                                        ? "12px"
                                        : screenWidth >= 960 && screenWidth < 1280
                                        ? "14px"
                                        : undefined,
                                  }}
                                  alt="Star"
                                  src={
                                    screenWidth >= 480 && screenWidth < 768
                                      ? "/img/star-22-27.svg"
                                      : screenWidth >= 960 && screenWidth < 1280
                                      ? "/img/star-22-18.svg"
                                      : undefined
                                  }
                                />
                              </div>
                              <div
                                className="div-14"
                                style={{
                                  fontFamily:
                                    screenWidth >= 480 && screenWidth < 768
                                      ? "'DM Sans', Helvetica"
                                      : screenWidth >= 960 && screenWidth < 1280
                                      ? "var(--1280-text-font-family)"
                                      : undefined,
                                  fontSize:
                                    screenWidth >= 480 && screenWidth < 768
                                      ? "16px"
                                      : screenWidth >= 960 && screenWidth < 1280
                                      ? "var(--1280-text-font-size)"
                                      : undefined,
                                  fontStyle:
                                    screenWidth >= 960 && screenWidth < 1280
                                      ? "var(--1280-text-font-style)"
                                      : undefined,
                                  fontWeight:
                                    screenWidth >= 480 && screenWidth < 768
                                      ? "400"
                                      : screenWidth >= 960 && screenWidth < 1280
                                      ? "var(--1280-text-font-weight)"
                                      : undefined,
                                  letterSpacing:
                                    screenWidth >= 480 && screenWidth < 768
                                      ? "0"
                                      : screenWidth >= 960 && screenWidth < 1280
                                      ? "var(--1280-text-letter-spacing)"
                                      : undefined,
                                  lineHeight:
                                    screenWidth >= 480 && screenWidth < 768
                                      ? "19.2px"
                                      : screenWidth >= 960 && screenWidth < 1280
                                      ? "var(--1280-text-line-height)"
                                      : undefined,
                                }}
                              >
                                {screenWidth >= 480 && screenWidth < 768 && (
                                  <p className="text-wrapper-164">
                                    я становлюсь вашим супервизором-наставником на пути развития как психотерапевта
                                  </p>
                                )}

                                {screenWidth >= 960 && screenWidth < 1280 && (
                                  <p className="text-wrapper-164">вы найдёте ключи к своей внутренней системе</p>
                                )}
                              </div>
                            </div>
                            <div
                              className="frame-50"
                              style={{
                                gap:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "16px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "12px"
                                    : undefined,
                              }}
                            >
                              <div className="star-wrapper">
                                <img
                                  className="star-17"
                                  style={{
                                    height:
                                      screenWidth >= 960 && screenWidth < 1280
                                        ? "14px"
                                        : screenWidth >= 480 && screenWidth < 768
                                        ? "12px"
                                        : undefined,
                                    width:
                                      screenWidth >= 960 && screenWidth < 1280
                                        ? "14px"
                                        : screenWidth >= 480 && screenWidth < 768
                                        ? "12px"
                                        : undefined,
                                  }}
                                  alt="Star"
                                  src={
                                    screenWidth >= 960 && screenWidth < 1280
                                      ? "/img/star-22-18.svg"
                                      : screenWidth >= 480 && screenWidth < 768
                                      ? "/img/star-22-27.svg"
                                      : undefined
                                  }
                                />
                              </div>
                              <div
                                className="div-15"
                                style={{
                                  fontFamily:
                                    screenWidth >= 960 && screenWidth < 1280
                                      ? "var(--1280-text-font-family)"
                                      : screenWidth >= 480 && screenWidth < 768
                                      ? "'DM Sans', Helvetica"
                                      : undefined,
                                  fontSize:
                                    screenWidth >= 960 && screenWidth < 1280
                                      ? "var(--1280-text-font-size)"
                                      : screenWidth >= 480 && screenWidth < 768
                                      ? "16px"
                                      : undefined,
                                  fontStyle:
                                    screenWidth >= 960 && screenWidth < 1280
                                      ? "var(--1280-text-font-style)"
                                      : undefined,
                                  fontWeight:
                                    screenWidth >= 960 && screenWidth < 1280
                                      ? "var(--1280-text-font-weight)"
                                      : screenWidth >= 480 && screenWidth < 768
                                      ? "400"
                                      : undefined,
                                  letterSpacing:
                                    screenWidth >= 960 && screenWidth < 1280
                                      ? "var(--1280-text-letter-spacing)"
                                      : screenWidth >= 480 && screenWidth < 768
                                      ? "0"
                                      : undefined,
                                  lineHeight:
                                    screenWidth >= 960 && screenWidth < 1280
                                      ? "var(--1280-text-line-height)"
                                      : screenWidth >= 480 && screenWidth < 768
                                      ? "19.2px"
                                      : undefined,
                                }}
                              >
                                {screenWidth >= 960 && screenWidth < 1280 && (
                                  <p className="text-wrapper-164">
                                    ослабите блоки, мешающие раскрытию потенциала, заложенного при рождении
                                  </p>
                                )}

                                {screenWidth >= 480 && screenWidth < 768 && (
                                  <p className="text-wrapper-164">
                                    вы проанализируете разные грани вашей работы с клиентами и выведете частную практику
                                    на новый уровень
                                  </p>
                                )}
                              </div>
                            </div>
                            {screenWidth >= 960 && screenWidth < 1280 && (
                              <>
                                <div className="frame-41">
                                  <div className="star-wrapper">
                                    <img className="star-9" alt="Star" src="/img/star-22-18.svg" />
                                  </div>
                                  <p className="text-wrapper-70">
                                    научитесь понимать, что происходит у вас внутри, и как эффективно и бережно
                                    возвращаться в ресурсное состояние
                                  </p>
                                </div>
                                <div className="frame-41">
                                  <div className="star-wrapper">
                                    <img className="star-9" alt="Star" src="/img/star-22-18.svg" />
                                  </div>
                                  <p className="text-wrapper-70">
                                    получите навыки, которые позволят вам стать психотерапевтом самому себе
                                  </p>
                                </div>
                              </>
                            )}

                            {screenWidth >= 480 && screenWidth < 768 && (
                              <div className="frame-51">
                                <div className="frame-52">
                                  <div className="star-wrapper">
                                    <img className="star-18" alt="Star" src="/img/star-22-33.svg" />
                                  </div>
                                  <div className="text-wrapper-84">расширите терапевтическое мышление</div>
                                </div>
                                <div className="frame-52">
                                  <div className="star-wrapper">
                                    <img className="star-18" alt="Star" src="/img/star-22-33.svg" />
                                  </div>
                                  <div className="text-wrapper-84">повысите уровень компетенций</div>
                                </div>
                                <div className="frame-52">
                                  <div className="star-wrapper">
                                    <img className="star-18" alt="Star" src="/img/star-22-33.svg" />
                                  </div>
                                  <p className="text-wrapper-84">выявите слепые пятна и зоны роста</p>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="div-16"
                            style={{
                              alignSelf: screenWidth >= 960 && screenWidth < 1280 ? "stretch" : undefined,
                              fontFamily:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-family)"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "'DM Sans', Helvetica"
                                  : undefined,
                              fontSize:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-size)"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "16px"
                                  : undefined,
                              fontStyle:
                                screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                              fontWeight:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-weight)"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "400"
                                  : undefined,
                              letterSpacing:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-letter-spacing)"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "0"
                                  : undefined,
                              lineHeight:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-line-height)"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "19.2px"
                                  : undefined,
                              width: screenWidth >= 480 && screenWidth < 768 ? "292px" : undefined,
                            }}
                          >
                            {screenWidth >= 960 && screenWidth < 1280 && (
                              <p className="text-wrapper-164">8000 ₽ / 50 минут</p>
                            )}

                            {screenWidth >= 480 && screenWidth < 768 && (
                              <p className="text-wrapper-164">6000 ₽ / 50 минут</p>
                            )}
                          </div>
                        </div>
                        <div
                          className="arc-3"
                          style={{
                            height:
                              screenWidth >= 960 && screenWidth < 1280
                                ? "686px"
                                : screenWidth >= 480 && screenWidth < 768
                                ? "603px"
                                : undefined,
                            width:
                              screenWidth >= 960 && screenWidth < 1280
                                ? "451px"
                                : screenWidth >= 480 && screenWidth < 768
                                ? "405px"
                                : undefined,
                          }}
                        >
                          <div
                            className="overlap-group-17"
                            style={{
                              height:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "686px"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "603px"
                                  : undefined,
                            }}
                          >
                            <img
                              className="rectangle-46"
                              style={{
                                height:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "682px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "597px"
                                    : undefined,
                                width:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "224px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "201px"
                                    : undefined,
                              }}
                              alt="Rectangle"
                              src={
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "/img/rectangle-44-7.svg"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "/img/rectangle-44-11.svg"
                                  : undefined
                              }
                            />
                            <img
                              className="rectangle-47"
                              style={{
                                height:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "682px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "597px"
                                    : undefined,
                                left:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "225px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "202px"
                                    : undefined,
                                width:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "224px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "201px"
                                    : undefined,
                              }}
                              alt="Rectangle"
                              src={
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "/img/rectangle-56-7.svg"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "/img/rectangle-56-11.svg"
                                  : undefined
                              }
                            />
                            <img
                              className="line-17"
                              style={{
                                top:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "682px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "599px"
                                    : undefined,
                                width:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "445px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "395px"
                                    : undefined,
                              }}
                              alt="Line"
                              src={
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "/img/line-9-7.svg"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "/img/line-9-11.svg"
                                  : undefined
                              }
                            />
                            <div
                              className="ellipse-29"
                              style={{
                                left:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "222px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "199px"
                                    : undefined,
                              }}
                            />
                            <div
                              className="ellipse-30"
                              style={{
                                top:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "680px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "597px"
                                    : undefined,
                              }}
                            />
                            <div
                              className="ellipse-31"
                              style={{
                                left:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "445px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "399px"
                                    : undefined,
                                top:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "680px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "597px"
                                    : undefined,
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="eye-3"
                          style={{
                            height:
                              screenWidth >= 960 && screenWidth < 1280
                                ? "63px"
                                : screenWidth >= 480 && screenWidth < 768
                                ? "39px"
                                : undefined,
                            left:
                              screenWidth >= 960 && screenWidth < 1280
                                ? "161px"
                                : screenWidth >= 480 && screenWidth < 768
                                ? "162px"
                                : undefined,
                            top:
                              screenWidth >= 960 && screenWidth < 1280
                                ? "80px"
                                : screenWidth >= 480 && screenWidth < 768
                                ? "43px"
                                : undefined,
                            width:
                              screenWidth >= 960 && screenWidth < 1280
                                ? "128px"
                                : screenWidth >= 480 && screenWidth < 768
                                ? "80px"
                                : undefined,
                          }}
                        >
                          <div
                            className="overlap-21"
                            style={{
                              height:
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "63px"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "39px"
                                  : undefined,
                            }}
                          >
                            <img
                              className="subtract-14"
                              style={{
                                height:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "58px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "36px"
                                    : undefined,
                                width:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "128px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "80px"
                                    : undefined,
                              }}
                              alt="Subtract"
                              src={
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "/img/subtract-5.svg"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "/img/subtract-29.svg"
                                  : undefined
                              }
                            />
                            <img
                              className="subtract-15"
                              style={{
                                height:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "59px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "36px"
                                    : undefined,
                                left:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "31px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "19px"
                                    : undefined,
                                width:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "66px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "41px"
                                    : undefined,
                              }}
                              alt="Subtract"
                              src={
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "/img/subtract-3.svg"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "/img/subtract-16.svg"
                                  : undefined
                              }
                            />
                            <img
                              className="rectangle-48"
                              style={{
                                height:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "25px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "15px"
                                    : undefined,
                                left:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "55px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "34px"
                                    : undefined,
                                top:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "39px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "24px"
                                    : undefined,
                                width:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "18px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "11px"
                                    : undefined,
                              }}
                              alt="Rectangle"
                              src={
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "/img/rectangle-63.svg"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "/img/rectangle-63-2.svg"
                                  : undefined
                              }
                            />
                            <img
                              className="subtract-16"
                              style={{
                                height:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "21px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "13px"
                                    : undefined,
                                left:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "53px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "33px"
                                    : undefined,
                                top:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "21px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "13px"
                                    : undefined,
                                width:
                                  screenWidth >= 960 && screenWidth < 1280
                                    ? "21px"
                                    : screenWidth >= 480 && screenWidth < 768
                                    ? "13px"
                                    : undefined,
                              }}
                              alt="Subtract"
                              src={
                                screenWidth >= 960 && screenWidth < 1280
                                  ? "/img/subtract-4.svg"
                                  : screenWidth >= 480 && screenWidth < 768
                                  ? "/img/subtract-14.svg"
                                  : undefined
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {screenWidth >= 1280 && isAlchemyOpen && (
                    <p className="text-wrapper-85">
                      Реконсолидация памяти - форма нейропластичности, которая изменяет существующую эмоциональную
                      память на синаптическом уровне (Ecker, 2012). Используя техники работы со вниманием, мы переводим
                      то, что записано у нас в бессознательной памяти, в открытое для редактирования состояние. Далее мы
                      подгружаем update: новый опыт или информацию, которая опровергает хранящиеся там убеждения. После
                      обновления нейронная сеть памяти вновь возвращается в исходное состояние.
                    </p>
                  )}

                  {((screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)) && (
                    <div
                      className="tarif-3"
                      style={{
                        height:
                          screenWidth >= 960 && screenWidth < 1280
                            ? "686px"
                            : screenWidth >= 480 && screenWidth < 768
                            ? "68px"
                            : undefined,
                        left:
                          screenWidth >= 960 && screenWidth < 1280
                            ? "490px"
                            : screenWidth >= 480 && screenWidth < 768
                            ? "20px"
                            : undefined,
                        top:
                          screenWidth >= 960 && screenWidth < 1280
                            ? "72px"
                            : screenWidth >= 480 && screenWidth < 768
                            ? "640px"
                            : undefined,
                        width:
                          screenWidth >= 960 && screenWidth < 1280
                            ? "453px"
                            : screenWidth >= 480 && screenWidth < 768
                            ? "442px"
                            : undefined,
                      }}
                    >
                      {screenWidth >= 960 && screenWidth < 1280 && (
                        <div className="overlap-group-15">
                          <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                            <img className="button-9" alt="Button" src="/img/button-12.svg" />
                          </a>
                          <div className="content-4">
                            <div className="text-wrapper-69">Для психологов</div>
                            <div className="frame-40">
                              <div className="frame-41">
                                <div className="star-wrapper">
                                  <img className="star-9" alt="Star" src="/img/star-22.svg" />
                                </div>
                                <p className="text-wrapper-70">
                                  я становлюсь вашим супервизором-наставником на пути развития как психотерапевта
                                </p>
                              </div>
                              <div className="frame-41">
                                <div className="star-wrapper">
                                  <img className="star-9" alt="Star" src="/img/star-22.svg" />
                                </div>
                                <p className="text-wrapper-70">
                                  вы проанализируете разные грани вашей работы с клиентами и выведете частную практику
                                  на новый уровень
                                </p>
                              </div>
                              <div className="frame-43">
                                <div className="frame-41">
                                  <div className="star-wrapper">
                                    <img className="star-9" alt="Star" src="/img/star-22-6.svg" />
                                  </div>
                                  <div className="text-wrapper-70">расширите терапевтическое мышление</div>
                                </div>
                                <div className="frame-41">
                                  <div className="star-wrapper">
                                    <img className="star-9" alt="Star" src="/img/star-22-6.svg" />
                                  </div>
                                  <div className="text-wrapper-70">повысите уровень компетенций</div>
                                </div>
                                <div className="frame-41">
                                  <div className="star-wrapper">
                                    <img className="star-9" alt="Star" src="/img/star-22-6.svg" />
                                  </div>
                                  <p className="text-wrapper-70">выявите слепые пятна и зоны роста</p>
                                </div>
                              </div>
                            </div>
                            <p className="div-8">6000 ₽ / 50 минут</p>
                          </div>
                          <div className="arc-4">
                            <div className="overlap-group-15">
                              <img className="rectangle-49" alt="Rectangle" src="/img/rectangle-44-8.svg" />
                              <img className="rectangle-50" alt="Rectangle" src="/img/rectangle-56-8.svg" />
                              <img className="line-14" alt="Line" src="/img/line-9-8.svg" />
                              <div className="ellipse-32" />
                              <div className="ellipse-27" />
                              <div className="ellipse-33" />
                            </div>
                          </div>
                          <div className="eye-4">
                            <div className="overlap-18">
                              <img className="subtract-11" alt="Subtract" src="/img/subtract-5.svg" />
                              <img className="subtract-12" alt="Subtract" src="/img/subtract-3.svg" />
                              <img className="rectangle-42" alt="Rectangle" src="/img/rectangle-63.svg" />
                              <img className="subtract-13" alt="Subtract" src="/img/subtract-4.svg" />
                            </div>
                          </div>
                        </div>
                      )}

                      {screenWidth >= 480 && screenWidth < 768 && (
                        <>
                          <p className="text-wrapper-86">
                            Моя главная цель - сделать так, чтобы я вам больше не понадобилась.
                          </p>
                          <div className="quote-7">
                            <img className="union-15" alt="Union" src="/img/union-16.svg" />
                            <img className="union-16" alt="Union" src="/img/union-17.svg" />
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  {screenWidth >= 960 && screenWidth < 1280 && (
                    <div className="group-13">
                      <p className="text-wrapper-73">
                        Моя главная цель - сделать так, чтобы я вам больше не понадобилась.
                      </p>
                      <div className="quote-2">
                        <img className="union-4" alt="Union" src="/img/union-24.svg" />
                        <img className="union-5" alt="Union" src="/img/union-24.svg" />
                      </div>
                    </div>
                  )}
                </div>
                {screenWidth >= 1280 && (
                  <div className="view-24" style={{
                    alignItems:'flex-start',
                    display:'flex',
                    flexDirection:'column',
                    gap:'40px',
                    left:0,
                    position:'absolute',
                    top: isAlchemyOpen ? '468px' : '523px',
                    width:'1180px',
                  }}>
                    <div className="view-8">
                      <div className="text-wrapper-87">Узнать подробнее</div>
                      <img className="frame-44" alt="Frame" src={isAlchemyOpen ? "/img/frame-83.svg" : "/img/dropdownArrow.svg"} onClick={toggleAlchemyDropdown}/>
                    </div>
                    {isAlchemyOpen ? <>
                      <div className="flexcontainer-4">
                        <p className="div-8">
                          <span className="text-wrapper-88">
                            Мы склонны считать, что сознательно решаем, как нам справляться с различными ситуациями и
                            проблемами, возникающими в повседневной жизни. Однако во многом наше поведение обусловлено
                            научением, хранящимся в бессознательной памяти. Когда вы едете на велосипеде, то вам не нужно
                            сознательно думать о том, как держать равновесие. Это происходит автоматически, благодаря
                            бессознательной памяти. В действительности, большинство способов, которыми мы реагируем на
                            вещи в течение дня, не являются когнитивными или сознательными. <br />
                          </span>
                        </p>
                        <p className="div-8">
                          <span className="text-wrapper-88">
                            Некоторые виды психотерапии в основном работают с сознательной памятью, взаимодействуя с
                            рациональным мышлением. Если вы когда-либо были разочарованы тем, что терапевтический процесс,
                            казалось бы, ходит по кругу — вы говорите о проблемах, вместо того чтобы на самом деле что-то
                            менять, то возможно, ваша терапия осталась на более сознательном уровне обработки. Многие из
                            этих видов психотерапии (например, КПТ-терапия) пытаются помочь вам контролировать
                            бессознательное научение и использовать логику и рациональность для выбора нового поведения,
                            противоречащего тому, что для вас привычно. <br />
                          </span>
                        </p>
                        <p className="div-8">
                          <span className="text-wrapper-88">
                            IFS-терапия отличается — она предлагает способ взаимодействия с бессознательными паттернами, к
                            которым у нас обычно нет доступа. Здесь мы фактически реорганизуем память, поэтому люди часто
                            удивляются, насколько трансформационными могут быть IFS-сессии. Трудности или симптомы нередко
                            исчезают без необходимости постоянно поддерживать эти изменения, так как травма исцеляется на
                            глубинном уровне. Это может стать переломным моментом в жизни, особенно если вы потратили годы
                            на терапию, которая не приносила желаемых результатов.
                          </span>
                        </p>
                      </div>
                      <Bullet
                        className="bullet-instance"
                        line="/img/line-8-1.svg"
                        text="Ecker, B., Bridges, S.K."
                        text1="How the Science of Memory Reconsolidation Advances the Effectiveness and Unification of Psychotherapy"
                        text2="2020 г."
                        text3="Clin Soc Work J 48, 287–300"
                      />
                    </> :
                    <img className="line-5" alt="Line" src={"/img/line-8.svg"} />}
                    {/* <a href="https://wakeuptoyourlife.notion.site/IFS-d3f77f552b90424682260ce59d4fc392" target="_blank" rel="noopener noreferrer">
                      <HierarchySecondary className="instance-node-2" text="ИНФОРМАЦИЯ ПРО IFS" />
                    </a> */}
                  </div>
                )}

                {screenWidth >= 480 && screenWidth < 768 && (
                  <div className="overlap-22">
                    <img className="vector-23" alt="Vector" src="/img/vector-23-3.svg" />
                    <div className="text-wrapper-89">
                      ФОРМАТЫ <br />
                      ВЗАИМОДЕЙСТВИЯ
                    </div>
                    <div className="tarif-4">
                      <div className="overlap-group-18">
                        <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                          <img className="button-10" alt="Button" src="/img/button-16.svg" />
                        </a>
                        <div className="content-5">
                          <div className="text-wrapper-90">Для клиентов</div>
                          <div className="frame-40">
                            <div className="frame-52">
                              <div className="star-wrapper">
                                <img className="star-18" alt="Star" src="/img/star-22-27.svg" />
                              </div>
                              <p className="text-wrapper-84">вы найдёте ключи к своей внутренней системе</p>
                            </div>
                            <div className="frame-52">
                              <div className="star-wrapper">
                                <img className="star-18" alt="Star" src="/img/star-22-27.svg" />
                              </div>
                              <p className="text-wrapper-84">
                                ослабите блоки, мешающие раскрытию потенциала, заложенного при рождении
                              </p>
                            </div>
                            <div className="frame-52">
                              <div className="star-wrapper">
                                <img className="star-18" alt="Star" src="/img/star-22-27.svg" />
                              </div>
                              <p className="text-wrapper-84">
                                научитесь понимать, что происходит у вас внутри, и как эффективно и бережно возвращаться
                                в ресурсное состояние
                              </p>
                            </div>
                            <div className="frame-52">
                              <div className="star-wrapper">
                                <img className="star-18" alt="Star" src="/img/star-22-27.svg" />
                              </div>
                              <p className="text-wrapper-84">
                                получите навыки, которые позволят вам стать психотерапевтом самому себе
                              </p>
                            </div>
                          </div>
                          <p className="text-wrapper-91">8000 ₽ / 50 минут</p>
                        </div>
                        <div className="arc-5">
                          <div className="overlap-group-18">
                            <img className="rectangle-51" alt="Rectangle" src="/img/rectangle-44-10.svg" />
                            <img className="rectangle-52" alt="Rectangle" src="/img/rectangle-56-10.svg" />
                            <img className="line-18" alt="Line" src="/img/line-9-10.svg" />
                            <div className="ellipse-34" />
                            <div className="ellipse-35" />
                            <div className="ellipse-36" />
                          </div>
                        </div>
                        <div className="eye-5">
                          <div className="overlap-23">
                            <img className="subtract-17" alt="Subtract" src="/img/subtract-12.svg" />
                            <img className="subtract-18" alt="Subtract" src="/img/subtract-13.svg" />
                            <img className="rectangle-53" alt="Rectangle" src="/img/rectangle-63-2.svg" />
                            <img className="subtract-19" alt="Subtract" src="/img/subtract-14.svg" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {((screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)) && (
                <>
                  <div
                    className="view-25"
                    style={{
                      height:
                        screenWidth >= 480 && screenWidth < 768
                          ? "580px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "179px"
                          : undefined,
                      left:
                        screenWidth >= 480 && screenWidth < 768
                          ? "43px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "1025px"
                          : undefined,
                      top:
                        screenWidth >= 480 && screenWidth < 768
                          ? (isAlchemyOpen ? "3150px" : "2300px")
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "2527px"
                          : undefined,
                      transform: screenWidth >= 960 && screenWidth < 1280 ? "rotate(-20.11deg)" : undefined,
                      width:
                        screenWidth >= 480 && screenWidth < 768
                          ? "444px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "179px"
                          : undefined,
                    }}
                  >
                    {screenWidth >= 480 && screenWidth < 768 && (
                      <>
                        <div className="frame-wrapper">
                          <div className="frame-40">
                            <div className="frame-53">
                              <img className="star-18" alt="Star" src="/img/star-14-33.svg" />
                              <p className="text-wrapper-92">
                                формула квантовых изменений, которая избавит вас от хождения по кругу в постоянной
                                тревоге и неудовлетворённости жизнью
                              </p>
                            </div>
                            <div className="frame-53">
                              <img className="star-18" alt="Star" src="/img/star-14-34.svg" />
                              <p className="text-wrapper-92">
                                главный секрет лёгкого и комфортного роста без надрыва, достигаторства и откатов
                              </p>
                            </div>
                            <div className="frame-53">
                              <img className="star-18" alt="Star" src="/img/star-14-33.svg" />
                              <p className="text-wrapper-92">
                                моя технология выхода на новый уровень через трансформацию архитектуры своей внутренней
                                системы
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="video-2">
                          <ReactPlayer 
                            light
                            url='https://youtu.be/5440yZcZnEg'
                            width='100%'
                            height='100%'
                            playing
                          />
                        </div>
                        <div className="text-wrapper-93">ПОГРУЗИТЬСЯ ГЛУБЖЕ</div>
                        <p className="text-wrapper-94">Как расти в легкости через работу с подсознанием?</p>
                      </>
                    )}

                    {screenWidth >= 960 && screenWidth < 1280 && (
                      <img className="union-17" alt="Union" src="/img/union-32.svg" />
                    )}
                  </div>
                  <div
                    className="pattern-6"
                    style={{
                      height:
                        screenWidth >= 480 && screenWidth < 768
                          ? "120px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "859px"
                          : undefined,
                      left:
                        screenWidth >= 480 && screenWidth < 768
                          ? "442px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "177px"
                          : undefined,
                      top:
                        screenWidth >= 480 && screenWidth < 768
                          ? "3149px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? (isAlchemyOpen ? "2614px" : "1950px")
                          : undefined,
                      transform: screenWidth >= 480 && screenWidth < 768 ? "rotate(-15.00deg)" : undefined,
                      width:
                        screenWidth >= 480 && screenWidth < 768
                          ? "120px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "922px"
                          : undefined,
                    }}
                  >
                    {screenWidth >= 480 && screenWidth < 768 && (
                      <img className="union-18" alt="Union" src="/img/union-41.svg" />
                    )}

                    {screenWidth >= 960 && screenWidth < 1280 && (
                      <>
                        <div className="text-5">
                          <div className="frame-54">
                            <div className="frame-38">
                              <img className="star-2" alt="Star" src="/img/star-14.svg" />
                              <p className="text-wrapper-14">
                                формула квантовых изменений, которая избавит вас от хождения по кругу в постоянной
                                тревоге и неудовлетворённости жизнью
                              </p>
                            </div>
                            <div className="frame-38">
                              <img className="star-2" alt="Star" src="/img/star-14.svg" />
                              <p className="text-wrapper-14">
                                главный секрет лёгкого и комфортного роста без надрыва, достигаторства и откатов
                              </p>
                            </div>
                            <div className="frame-38">
                              <img className="star-2" alt="Star" src="/img/star-14.svg" />
                              <p className="text-wrapper-14">
                                моя технология выхода на новый уровень через трансформацию архитектуры своей внутренней
                                системы
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="video-3">
                          <ReactPlayer 
                            light
                            url='https://youtu.be/5440yZcZnEg'
                            width='100%'
                            height='100%'
                            playing
                          />
                        </div>
                        <div className="text-wrapper-50">ПОГРУЗИТЬСЯ ГЛУБЖЕ</div>
                        <p className="text-wrapper-95">Как расти в легкости через работу с подсознанием?</p>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            <div
              id="education"  
              className="view-26"
              style={{
                alignItems: screenWidth >= 1280 ? "flex-start" : undefined,
                display: screenWidth >= 1280 ? "flex" : undefined,
                flexDirection: screenWidth >= 1280 ? "column" : undefined,
                gap: screenWidth >= 1280 ? "40px" : undefined,
                height:
                  screenWidth >= 480 && screenWidth < 768
                    ? "642px"
                    : screenWidth >= 960 && screenWidth < 1280
                    ? "714px"
                    : undefined,
                left:
                  (screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)
                    ? "22px"
                    : screenWidth >= 1280
                    ? "50px"
                    : undefined,
                top:
                  screenWidth >= 480 && screenWidth < 768
                    ? (isDetailsOpen && isAlchemyOpen ? "8715px" : !isDetailsOpen && isAlchemyOpen ? "7480px" : isDetailsOpen && !isAlchemyOpen ? "7880px" : "6620px")
                    : screenWidth >= 960 && screenWidth < 1280
                    ? (isDetailsOpen && isAlchemyOpen ? "8948px" : isDetailsOpen && !isAlchemyOpen ? "8248px" : !isDetailsOpen && isAlchemyOpen ? "7231px" : "6561px")
                    : screenWidth >= 1280
                    ? "2160px"
                    : undefined,
                width:
                  screenWidth >= 480 && screenWidth < 768
                    ? "440px"
                    : screenWidth >= 960 && screenWidth < 1280
                    ? "920px"
                    : screenWidth >= 1280
                    ? "1180px"
                    : undefined,
              }}
            >
              {((screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)) && (
                <>
                  <div
                    className="text-wrapper-96"
                    style={{
                      fontFamily:
                        screenWidth >= 480 && screenWidth < 768
                          ? "'Lora', Helvetica"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h2-medium-font-family)"
                          : undefined,
                      fontSize:
                        screenWidth >= 480 && screenWidth < 768
                          ? "32px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h2-medium-font-size)"
                          : undefined,
                      fontStyle:
                        screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-h2-medium-font-style)" : undefined,
                      fontWeight:
                        screenWidth >= 480 && screenWidth < 768
                          ? "500"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h2-medium-font-weight)"
                          : undefined,
                      letterSpacing:
                        screenWidth >= 480 && screenWidth < 768
                          ? "0"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h2-medium-letter-spacing)"
                          : undefined,
                      lineHeight:
                        screenWidth >= 480 && screenWidth < 768
                          ? "38.4px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h2-medium-line-height)"
                          : undefined,
                    }}
                  >
                    МОИ СТАТЬИ
                  </div>
                  <div
                    className="view-27"
                    style={{
                      gap:
                        screenWidth >= 480 && screenWidth < 768
                          ? "20px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "40px"
                          : undefined,
                      top:
                        screenWidth >= 480 && screenWidth < 768
                          ? "58px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "100px"
                          : undefined,
                      width:
                        screenWidth >= 480 && screenWidth < 768
                          ? "438px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "918px"
                          : undefined,
                    }}
                  >
                    <div
                      className="frame-55"
                      style={{
                        gap:
                          screenWidth >= 480 && screenWidth < 768
                            ? "20px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "30px"
                            : undefined,
                      }}
                    >
                      <div className="frame-31">
                        {screenWidth >= 480 && screenWidth < 768 && (
                          <>
                            <div className="text-wrapper-97">Придираться к себе</div>
                            <a href="https://teletype.in/@clara.zhikhareva/lFm-z3jy0cI" target="_blank" rel="noopener noreferrer">
                              <button className="btn">
                                <div className="frame-56">
                                  <div className="text-wrapper-98">ЧИТАТЬ СТАТЬЮ</div>
                                </div>
                                <img className="frame-57" alt="Frame" src="/img/frame-84-10.svg" />
                              </button>
                            </a>
                          </>
                        )}

                        {screenWidth >= 960 && screenWidth < 1280 && (
                          <>
                            <div className="text-wrapper-51">Придираться к себе</div>
                            <a href="https://teletype.in/@clara.zhikhareva/lFm-z3jy0cI" target="_blank" rel="noopener noreferrer">
                              <HierarchyTertiary
                                className="instance-node-2"
                                frame="/img/frame-84-1.svg"
                                text="ЧИТАТЬ СТАТЬЮ"
                              />
                            </a>
                          </>
                        )}
                      </div>
                      <div className="flexcontainer-5">
                        <p
                          className="text-6"
                          style={{
                            fontFamily:
                              screenWidth >= 480 && screenWidth < 768
                                ? "'DM Sans', Helvetica"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-family)"
                                : undefined,
                            fontSize:
                              screenWidth >= 480 && screenWidth < 768
                                ? "16px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-size)"
                                : undefined,
                            fontStyle:
                              screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                            fontWeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "400"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-weight)"
                                : undefined,
                            letterSpacing:
                              screenWidth >= 480 && screenWidth < 768
                                ? "0"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-letter-spacing)"
                                : undefined,
                            lineHeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "19.2px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-line-height)"
                                : undefined,
                          }}
                        >
                          <span
                            className="text-wrapper-99"
                            style={{
                              fontFamily:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "'DM Sans', Helvetica"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-family)"
                                  : undefined,
                              fontSize:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "16px"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-size)"
                                  : undefined,
                              fontStyle:
                                screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                              fontWeight:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "400"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-weight)"
                                  : undefined,
                              letterSpacing:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "0"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-letter-spacing)"
                                  : undefined,
                              lineHeight:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "19.2px"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-line-height)"
                                  : undefined,
                            }}
                          >
                            «Лучшая версия себя» — это личная эволюция или способ оправдывать чужие ожидания?
                            <br />
                          </span>
                        </p>
                        <p
                          className="text-7"
                          style={{
                            fontFamily:
                              screenWidth >= 480 && screenWidth < 768
                                ? "'DM Sans', Helvetica"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-family)"
                                : undefined,
                            fontSize:
                              screenWidth >= 480 && screenWidth < 768
                                ? "16px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-size)"
                                : undefined,
                            fontStyle:
                              screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                            fontWeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "400"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-weight)"
                                : undefined,
                            letterSpacing:
                              screenWidth >= 480 && screenWidth < 768
                                ? "0"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-letter-spacing)"
                                : undefined,
                            lineHeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "19.2px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-line-height)"
                                : undefined,
                          }}
                        >
                          <span
                            className="text-wrapper-100"
                            style={{
                              fontFamily:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "'DM Sans', Helvetica"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-family)"
                                  : undefined,
                              fontSize:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "16px"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-size)"
                                  : undefined,
                              fontStyle:
                                screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                              fontWeight:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "400"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-weight)"
                                  : undefined,
                              letterSpacing:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "0"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-letter-spacing)"
                                  : undefined,
                              lineHeight:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "19.2px"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-line-height)"
                                  : undefined,
                            }}
                          >
                            Поделилась своим видением того, как мы ломаем сами себе жизнь с помощью освоенного
                            в совершенстве навыка придираться к себе.
                          </span>
                        </p>
                      </div>
                    </div>
                    <img
                      className="line-11"
                      alt="Line"
                      src={
                        screenWidth >= 480 && screenWidth < 768
                          ? "/img/line-52-3.svg"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "/img/line-52-2.svg"
                          : undefined
                      }
                    />
                    <div
                      className="frame-58"
                      style={{
                        gap:
                          screenWidth >= 480 && screenWidth < 768
                            ? "20px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "30px"
                            : undefined,
                      }}
                    >
                      <div className="frame-31">
                        {screenWidth >= 480 && screenWidth < 768 && (
                          <>
                            <div className="text-wrapper-97">Отложенная жизнь</div>
                            <a href="https://teletype.in/@clara.zhikhareva/2KjbRVfpOMk" target="_blank" rel="noopener noreferrer">
                              <button className="btn">
                                <div className="frame-56">
                                  <div className="text-wrapper-98">ЧИТАТЬ СТАТЬЮ</div>
                                </div>
                                <img className="frame-57" alt="Frame" src="/img/frame-84-10.svg" />
                              </button>
                            </a>
                          </>
                        )}

                        {screenWidth >= 960 && screenWidth < 1280 && (
                          <>
                            <div className="text-wrapper-51">Отложенная жизнь</div>
                            <a href="https://teletype.in/@clara.zhikhareva/2KjbRVfpOMk" target="_blank" rel="noopener noreferrer">
                              <HierarchyTertiary
                                className="instance-node-2"
                                frame="/img/frame-84-1.svg"
                                text="ЧИТАТЬ СТАТЬЮ"
                              />
                            </a>
                          </>
                        )}
                      </div>
                      <div className="flexcontainer-6">
                        <p
                          className="text-8"
                          style={{
                            fontFamily:
                              screenWidth >= 480 && screenWidth < 768
                                ? "'DM Sans', Helvetica"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-family)"
                                : undefined,
                            fontSize:
                              screenWidth >= 480 && screenWidth < 768
                                ? "16px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-size)"
                                : undefined,
                            fontStyle:
                              screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                            fontWeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "400"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-weight)"
                                : undefined,
                            letterSpacing:
                              screenWidth >= 480 && screenWidth < 768
                                ? "0"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-letter-spacing)"
                                : undefined,
                            lineHeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "19.2px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-line-height)"
                                : undefined,
                          }}
                        >
                          <span
                            className="text-wrapper-101"
                            style={{
                              fontFamily:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "'DM Sans', Helvetica"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-family)"
                                  : undefined,
                              fontSize:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "16px"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-size)"
                                  : undefined,
                              fontStyle:
                                screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                              fontWeight:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "400"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-weight)"
                                  : undefined,
                              letterSpacing:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "0"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-letter-spacing)"
                                  : undefined,
                              lineHeight:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "19.2px"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-line-height)"
                                  : undefined,
                            }}
                          >
                            Как двигаются по жизни по-настоящему счастливые люди? <br />
                          </span>
                        </p>
                        <p
                          className="text-9"
                          style={{
                            fontFamily:
                              screenWidth >= 480 && screenWidth < 768
                                ? "'DM Sans', Helvetica"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-family)"
                                : undefined,
                            fontSize:
                              screenWidth >= 480 && screenWidth < 768
                                ? "16px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-size)"
                                : undefined,
                            fontStyle:
                              screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                            fontWeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "400"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-font-weight)"
                                : undefined,
                            letterSpacing:
                              screenWidth >= 480 && screenWidth < 768
                                ? "0"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-letter-spacing)"
                                : undefined,
                            lineHeight:
                              screenWidth >= 480 && screenWidth < 768
                                ? "19.2px"
                                : screenWidth >= 960 && screenWidth < 1280
                                ? "var(--1280-text-line-height)"
                                : undefined,
                          }}
                        >
                          <span
                            className="text-wrapper-102"
                            style={{
                              fontFamily:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "'DM Sans', Helvetica"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-family)"
                                  : undefined,
                              fontSize:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "16px"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-size)"
                                  : undefined,
                              fontStyle:
                                screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                              fontWeight:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "400"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-font-weight)"
                                  : undefined,
                              letterSpacing:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "0"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-letter-spacing)"
                                  : undefined,
                              lineHeight:
                                screenWidth >= 480 && screenWidth < 768
                                  ? "19.2px"
                                  : screenWidth >= 960 && screenWidth < 1280
                                  ? "var(--1280-text-line-height)"
                                  : undefined,
                            }}
                          >
                            Стремятся ли они поскорее попасть на конечную станцию, не замечая ничего вокруг? Летят ли
                            вперед в погоне за иллюзорными целями? Или же наслаждаются опытом проживания истинной живой
                            Жизни, видя красоту в каждом моменте?
                          </span>
                        </p>
                      </div>
                    </div>
                    <img
                      className="line-11"
                      alt="Line"
                      src={
                        screenWidth >= 480 && screenWidth < 768
                          ? "/img/line-52-3.svg"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "/img/line-52-2.svg"
                          : undefined
                      }
                    />
                    <div
                      className="frame-59"
                      style={{
                        gap:
                          screenWidth >= 480 && screenWidth < 768
                            ? "20px"
                            : screenWidth >= 960 && screenWidth < 1280
                            ? "30px"
                            : undefined,
                      }}
                    >
                      <div className="frame-31">
                        {screenWidth >= 480 && screenWidth < 768 && (
                          <>
                            <p className="text-wrapper-97">Мир людей, у которых горят глаза</p>
                            <a href="https://teletype.in/@clara.zhikhareva/RBdi14WjP1z" target="_blank" rel="noopener noreferrer">
                              <button className="btn">
                                <div className="frame-56">
                                  <div className="text-wrapper-98">ЧИТАТЬ СТАТЬЮ</div>
                                </div>
                                <img className="frame-57" alt="Frame" src="/img/frame-84-10.svg" />
                              </button>
                            </a>
                          </>
                        )}

                        {screenWidth >= 960 && screenWidth < 1280 && (
                          <>
                            <p className="text-wrapper-52">Мир людей, у которых горят глаза</p>
                            <a href="https://teletype.in/@clara.zhikhareva/RBdi14WjP1z" target="_blank" rel="noopener noreferrer">
                              <HierarchyTertiary
                                className="instance-node-2"
                                frame="/img/frame-84-1.svg"
                                text="ЧИТАТЬ СТАТЬЮ"
                              />
                            </a>
                          </>
                        )}
                      </div>
                      <p
                        className="text-wrapper-103"
                        style={{
                          fontFamily:
                            screenWidth >= 480 && screenWidth < 768
                              ? "'DM Sans', Helvetica"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-font-family)"
                              : undefined,
                          fontSize:
                            screenWidth >= 480 && screenWidth < 768
                              ? "16px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-font-size)"
                              : undefined,
                          fontStyle:
                            screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-text-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "400"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-font-weight)"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 480 && screenWidth < 768
                              ? "0"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-letter-spacing)"
                              : undefined,
                          lineHeight:
                            screenWidth >= 480 && screenWidth < 768
                              ? "19.2px"
                              : screenWidth >= 960 && screenWidth < 1280
                              ? "var(--1280-text-line-height)"
                              : undefined,
                        }}
                      >
                        Здесь о мире, в котором я хочу жить. И о том, какие качество отличает Живых людей от биороботов,
                        механически двигающихся от задачи к задаче по чужим рельсам.
                      </p>
                    </div>
                  </div>
                </>
              )}

              {screenWidth >= 1280 && (
                <>
                  <div className="frame-30">
                    <div className="text-wrapper-104">ОБРАЗОВАНИЕ И ОПЫТ</div>
                    <div className="text">
                      <p className="text-wrapper-105">
                        Окончила МГУ и Вышку по специальностям нейрофизиология и психология, а так же несколько
                        многолетних программ по современным подходам психотерапии. Провела более 5000 консультаций, имею
                        за спиной около 200 часов личной терапии и регулярно работаю с супервизором.
                      </p>
                      <p className="text-wrapper-106">
                        Преподаватель в онлайн-школе психологических профессий Psychodemia (при Институте психологии
                        РАН), автор лекций на базе исследований в сферах физиологии, нейронаук, эволюционной психологии
                        и майндфулнесс. Program Assistant на сертификационном обучении IFS-терапевтов. Супервизор и
                        ментор для психологов.
                      </p>
                    </div>
                  </div>
                  <img className="line-9" alt="Line" src="/img/line-8-1.svg" />
                  <div className="view-8">
                    <div className="text-wrapper-87">Подробнее</div>
                    <img className="frame-44" alt="Frame" src={isDetailsOpen ? "/img/frame-83.svg" : "/img/dropdownArrow.svg"} onClick={toggleDetailsDropdown} />
                  </div>
                  {isDetailsOpen && <div className="frame-30">
                    <Bullet
                      className="bullet-instance"
                      line="/img/line-8.svg"
                      text="МГУ им. М.В. Ломоносова"
                      text1="Нейрофизиология"
                      text2="бакалавр, 2013–2017 гг."
                      text3="красный диплом"
                    />
                    <Bullet
                      className="bullet-instance"
                      line="/img/line-8.svg"
                      text="НИУ ВШЭ"
                      text1="Психоанализ и психоаналитическая психотерапия"
                      text2="магистр, 2017–2019 гг."
                      text3="красный диплом"
                    />
                    <Bullet
                      className="bullet-instance"
                      line="/img/line-8.svg"
                      text="МГИ"
                      text1="Базовая программа по гештальт-терапии"
                      text2="2016–2020 гг."
                      text3="600 часов"
                    />
                    <Bullet
                      className="bullet-instance"
                      line="/img/line-8.svg"
                      text="ИПКиРТ"
                      text1="Психодрама"
                      text2="2016–2019 гг."
                      text3="900 часов"
                    />
                    <Bullet
                      className="bullet-instance"
                      line="/img/line-8.svg"
                      text="Heril Formations"
                      text1="французская программа «Сексотерапия и терапия пар»"
                      text2="2019&nbsp;&nbsp;г."
                      text3=" 75 часов"
                    />
                    <Bullet
                      className="bullet-instance"
                      divClassName="frame-29"
                      line="/img/line-8-7.svg"
                      text="The International Focusing Institute"
                      text1="Focusing 1−3"
                      text2="2021–2022 гг."
                      text3="52 часа"
                    />
                    <Bullet
                      className="bullet-instance"
                      line="/img/line-8-7.svg"
                      text="Психодемия"
                      text1="Терапия внутренних семейных систем (IFS)"
                      text2="2022-2023 гг."
                      text3="78 часов"
                    />
                    <Bullet
                      className="bullet-instance"
                      line="/img/line-8-7.svg"
                      text="Психодемия"
                      text1="Эмоционально-фокусированная терапия в работе с парами"
                      text2="2023 г."
                      text3="162 часа"
                    />
                    <Bullet
                      className="bullet-instance"
                      line="/img/line-8-7.svg"
                      text="Психодемия"
                      text1="Обучение супервизии"
                      text2="2024 г."
                      text3="190 часов"
                    />
                    <Bullet
                      className="bullet-instance"
                      line="/img/line-8-7.svg"
                      text="IFS Institute"
                      text1="IFS Level 1 Training"
                      text2="2023-2024 гг."
                      text3="90 часов"
                    />
                    <Bullet
                      className="bullet-instance"
                      line="/img/line-8-7.svg"
                      text="Loch Kelly"
                      text1="продвинутые семинары в Effortless Mindfulness и IFS по темам «Effortless Mindfulness and Self-Leadership», «How To Shift Out of Ego Into Awake Self»"
                      text2="2023-2024 гг."
                      text3="90 часов"
                    />
                    <Bullet
                      className="bullet-instance"
                      line="/img/line-8-13.svg"
                      text="Bruce Hersey"
                      text1="IFS-informed EMDR 1−3"
                      text2="2024 г."
                      text3=" 6 часов"
                    />
                  </div>
                  }
                  <div className="frame-60">
                    <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                      <img className="button-11" alt="Button" src="/img/button-3.svg" />
                    </a>
                  </div>
                </>
              )}
            </div>
            <div
              id="about"
              className="view-28"
              style={{
                height:
                  screenWidth >= 480 && screenWidth < 768
                    ? "244px"
                    : screenWidth >= 960 && screenWidth < 1280
                    ? "168px"
                    : screenWidth >= 1280
                    ? "697px"
                    : undefined,
                left:
                  (screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)
                    ? "22px"
                    : screenWidth >= 1280
                    ? "0"
                    : undefined,
                top:
                  screenWidth >= 480 && screenWidth < 768
                    ? (isDetailsOpen && isAlchemyOpen ? "9397px" : !isDetailsOpen && isAlchemyOpen ? "8160px" : isDetailsOpen && !isAlchemyOpen ? "8560px" : "7300px")
                    : screenWidth >= 960 && screenWidth < 1280
                    ? (isDetailsOpen && isAlchemyOpen ? "9732px" : isDetailsOpen && !isAlchemyOpen ? "9032px" : !isDetailsOpen && isAlchemyOpen ? "8005px" : "7315px")
                    : screenWidth >= 1280
                    ? "0"
                    : undefined,
                width:
                  screenWidth >= 480 && screenWidth < 768
                    ? "438px"
                    : screenWidth >= 960 && screenWidth < 1280
                    ? "844px"
                    : screenWidth >= 1280
                    ? "1280px"
                    : undefined,
              }}
            >
              <div
                className="view-29"
                style={{
                  height:
                    screenWidth >= 1280
                      ? "669px"
                      : screenWidth >= 480 && screenWidth < 768
                      ? "76px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "60px"
                      : undefined,
                  left:
                    screenWidth >= 1280
                      ? "50px"
                      : (screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)
                      ? "0"
                      : undefined,
                  top:
                    screenWidth >= 1280
                      ? "28px"
                      : (screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)
                      ? "0"
                      : undefined,
                  width:
                    screenWidth >= 1280
                      ? "1111px"
                      : screenWidth >= 480 && screenWidth < 768
                      ? "440px"
                      : screenWidth >= 960 && screenWidth < 1280
                      ? "753px"
                      : undefined,
                }}
              >
                {screenWidth >= 1280 && (
                  <>
                    <div className="overlap-24">
                      <div className="pattern-7" />
                      <div className="photo-3">
                        <div className="overlap-25">
                          <div className="group-16" />
                          <div className="arc-6">
                            <div className="overlap-group-19">
                              <img className="rectangle-54" alt="Rectangle" src="/img/rectangle-44-2.svg" />
                              <img className="line-19" alt="Line" src="/img/line-9-2.svg" />
                              <img className="star-19" alt="Star" src="/img/star-15-1.svg" />
                              <img className="rectangle-55" alt="Rectangle" src="/img/rectangle-56-2.svg" />
                              <img className="star-20" alt="Star" src="/img/star-15-2.svg" />
                              <img className="star-21" alt="Star" src="/img/star-15-3.svg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-wrapper-107">КЛАРА ЖИХАРЕВА</div>
                    <div className="frame-61">
                      <div className="frame-16">
                        <img className="star-2" alt="Star" src="/img/star-14.svg" />
                        <div className="text-wrapper-108">психолог, нейрофизиолог</div>
                      </div>
                      <div className="frame-16">
                        <img className="star-2" alt="Star" src="/img/star-14.svg" />
                        <div className="text-wrapper-108">сертифицированный IFS-терапевт</div>
                      </div>
                      <div className="frame-14">
                        <img className="star-2" alt="Star" src="/img/star-14.svg" />
                        <p className="text-wrapper-108">супервизор и ментор для психологов</p>
                      </div>
                      <div className="frame-14">
                        <img className="star-2" alt="Star" src="/img/star-14.svg" />
                        <p className="text-wrapper-109">преподаватель Psychodemia (при Институте психологии РАН)</p>
                      </div>
                      <div className="frame-14">
                        <img className="star-2" alt="Star" src="/img/star-14.svg" />
                        <p className="text-wrapper-109">клиенты из списка Forbes, Yandex, Tinkoff, Facebook</p>
                      </div>
                    </div>
                    <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                      <img className="button-12" alt="Button" src="/img/button-4.svg"/>
                    </a>
                  </>
                )}

                {((screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)) && (
                  <div
                    className="text-wrapper-110"
                    style={{
                      fontFamily:
                        screenWidth >= 480 && screenWidth < 768
                          ? "'Lora', Helvetica"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h2-medium-font-family)"
                          : undefined,
                      fontSize:
                        screenWidth >= 480 && screenWidth < 768
                          ? "32px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h2-medium-font-size)"
                          : undefined,
                      fontStyle:
                        screenWidth >= 960 && screenWidth < 1280 ? "var(--1280-h2-medium-font-style)" : undefined,
                      fontWeight:
                        screenWidth >= 480 && screenWidth < 768
                          ? "500"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h2-medium-font-weight)"
                          : undefined,
                      letterSpacing:
                        screenWidth >= 480 && screenWidth < 768
                          ? "0"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h2-medium-letter-spacing)"
                          : undefined,
                      lineHeight:
                        screenWidth >= 480 && screenWidth < 768
                          ? "38.4px"
                          : screenWidth >= 960 && screenWidth < 1280
                          ? "var(--1280-h2-medium-line-height)"
                          : undefined,
                      whiteSpace: screenWidth >= 960 && screenWidth < 1280 ? "nowrap" : undefined,
                      width: screenWidth >= 480 && screenWidth < 768 ? "438px" : undefined,
                    }}
                  >
                    ПОЗНАКОМИТЬСЯ ПОБЛИЖЕ
                  </div>
                )}
              </div>
              {((screenWidth >= 480 && screenWidth < 768) || (screenWidth >= 960 && screenWidth < 1280)) && (
                <div
                  className="btns-2"
                  style={{
                    display:
                      screenWidth >= 480 && screenWidth < 768
                        ? "flex"
                        : screenWidth >= 960 && screenWidth < 1280
                        ? "inline-flex"
                        : undefined,
                    flexDirection: screenWidth >= 480 && screenWidth < 768 ? "column" : undefined,
                    justifyContent: screenWidth >= 480 && screenWidth < 768 ? "center" : undefined,
                    top:
                      screenWidth >= 480 && screenWidth < 768
                        ? "96px"
                        : screenWidth >= 960 && screenWidth < 1280
                        ? "100px"
                        : undefined,
                    width: screenWidth >= 480 && screenWidth < 768 ? "438px" : undefined,
                  }}
                >
                  <a href="https://t.me/wakeuptoyourlife" target="_blank" rel="noopener noreferrer">
                    <HierarchySecondary
                      className={`${screenWidth >= 480 && screenWidth < 768 && "class-10"}`}
                      divClassName={`${screenWidth >= 480 && screenWidth < 768 && "class-5"}`}
                      text="МОЙ ТЕЛЕГРАМ-КАНАЛ"
                      element='arrow'
                      preElement='telega'
                    />
                  </a>
                  <a href="https://www.instagram.com/clara.zhikhareva/" target="_blank" rel="noopener noreferrer">
                    <HierarchySecondary
                      className={`${screenWidth >= 480 && screenWidth < 768 && "class-11"}`}
                      divClassName={`${screenWidth >= 480 && screenWidth < 768 && "class-5"}`}
                      text="МОЙ ИНСТАГРАМ"
                      element='arrow'
                      preElement='insta'
                    />
                  </a>
                </div>
              )}
            </div>
          </>
        )}

        {((screenWidth >= 768 && screenWidth < 960) || screenWidth < 480) && (
          <>
            <div
              className="view-30"
              style={{
                gap: screenWidth < 480 ? "20px" : screenWidth >= 768 && screenWidth < 960 ? "30px" : undefined,
                top: screenWidth < 480 ? "1504px" : screenWidth >= 768 && screenWidth < 960 ? "1571px" : undefined,
                width: screenWidth < 480 ? "320px" : screenWidth >= 768 && screenWidth < 960 ? "728px" : undefined,
              }}
            >
              <div
                className="frame-62"
                style={{
                  gap: screenWidth < 480 ? "16px" : screenWidth >= 768 && screenWidth < 960 ? "30px" : undefined,
                }}
              >
                <div
                  className="text-wrapper-111"
                  style={{
                    fontSize: screenWidth < 480 ? "24px" : screenWidth >= 768 && screenWidth < 960 ? "42px" : undefined,
                    lineHeight:
                      screenWidth < 480 ? "28.8px" : screenWidth >= 768 && screenWidth < 960 ? "50.4px" : undefined,
                  }}
                >
                  ОБРАЗОВАНИЕ И ОПЫТ
                </div>
                <div
                  className="text-10"
                  style={{
                    gap: screenWidth < 480 ? "16px" : screenWidth >= 768 && screenWidth < 960 ? "20px" : undefined,
                  }}
                >
                  <div
                    className="div-17"
                    style={{
                      fontSize:
                        screenWidth < 480 ? "14px" : screenWidth >= 768 && screenWidth < 960 ? "16px" : undefined,
                      lineHeight:
                        screenWidth < 480 ? "16.8px" : screenWidth >= 768 && screenWidth < 960 ? "19.2px" : undefined,
                    }}
                  >
                    {screenWidth < 480 && (
                      <p className="text-wrapper-164">
                        Окончила МГУ и Вышку по специальностям нейрофизиология и психология, а так же несколько
                        многолетних программ по современным подходам психотерапии. Провела более 5000 консультаций, имею
                        за спиной около 200 часов личной терапии и регулярно работаю с супервизором.
                      </p>
                    )}

                    {screenWidth >= 768 && screenWidth < 960 && (
                      <p className="text-wrapper-164">
                        Окончила МГУ и Вышку по специальностям нейрофизиология и психология, а так же несколько
                        многолетних программ по современным подходам психотерапии. Провела более 5000 консультаций, имею
                        за спиной около 200 часов личной терапии и регулярно работаю с супервизором.
                      </p>
                    )}
                  </div>
                  <p
                    className="text-wrapper-112"
                    style={{
                      fontSize:
                        screenWidth < 480 ? "14px" : screenWidth >= 768 && screenWidth < 960 ? "16px" : undefined,
                      lineHeight:
                        screenWidth < 480 ? "16.8px" : screenWidth >= 768 && screenWidth < 960 ? "19.2px" : undefined,
                    }}
                  >
                    Преподаватель в онлайн-школе психологических профессий Psychodemia (при Институте психологии РАН),
                    автор лекций на базе исследований в сферах физиологии, нейронаук, эволюционной психологии и
                    майндфулнесс. Program Assistant на сертификационном обучении IFS-терапевтов. Супервизор и ментор для
                    психологов.
                  </p>
                </div>
              </div>
              <img
                className="line-9"
                alt="Line"
                src={
                  screenWidth < 480
                    ? "/img/line-6-1.svg"
                    : screenWidth >= 768 && screenWidth < 960
                    ? "/img/line-6-4.svg"
                    : undefined
                }
              />
              <div className="view-8">
                <div
                  className="text-wrapper-113"
                  style={{
                    fontSize: screenWidth < 480 ? "20px" : screenWidth >= 768 && screenWidth < 960 ? "32px" : undefined,
                    lineHeight:
                      screenWidth < 480 ? "24.0px" : screenWidth >= 768 && screenWidth < 960 ? "38.4px" : undefined,
                  }}
                >
                  Подробнее
                </div>
                <img
                  className="frame-63"
                  style={{
                    height: screenWidth < 480 ? "24px" : screenWidth >= 768 && screenWidth < 960 ? "32px" : undefined,
                    width: screenWidth < 480 ? "24px" : screenWidth >= 768 && screenWidth < 960 ? "32px" : undefined,
                  }}
                  alt="Frame"
                  src={isDetailsOpen ? "/img/frame-83.svg" : "/img/dropdownArrow.svg"}
                  onClick={toggleDetailsDropdown}
                />
              </div>
              <div
                className="frame-64"
                style={{
                  gap: screenWidth < 480 ? "16px" : screenWidth >= 768 && screenWidth < 960 ? "30px" : undefined,
                }}
              >
                {screenWidth < 480 && isDetailsOpen && (
                  <>
                    <EducationCell
                      className="instance-node-2"
                      text="НИУ ВШЭ"
                      text1="Психоанализ и психоаналитическая психотерапия"
                      text2={
                        <>
                          магистр, <br />
                          2017–2019 гг.
                        </>
                      }
                    />
                    <EducationCell
                      className="instance-node-2"
                      text="МГИ"
                      text1="Базовая программа по гештальт-терапии"
                      text2="2016–2020 гг."
                      text3="600 часов"
                    />
                    <EducationCell
                      className="instance-node-2"
                      text="ИПКиРТ"
                      text1="Психодрама"
                      text2="2016–2019 гг."
                      text3="900 часов"
                    />
                    <EducationCell
                      className="instance-node-2"
                      text="Heril Formations"
                      text1="Французская программа «Сексотерапия и терапия пар»"
                      text2="2019&nbsp;&nbsp;г."
                      text3=" 75 часов"
                    />
                    <EducationCell
                      className="instance-node-2"
                      text="The International Focusing Institute"
                      text1="Focusing 1−3"
                      text2="2021–2022 гг."
                      text3="52 часа"
                    />
                    <EducationCell
                      className="instance-node-2"
                      text="Психодемия"
                      text1="Терапия внутренних семейных систем (IFS)"
                      text2="2022-2023 гг."
                      text3="78 часов"
                    />
                    <EducationCell
                      className="instance-node-2"
                      text="Психодемия"
                      text1="Эмоционально-фокусированная терапия в работе с парами"
                      text2="2023 г."
                      text3="162 часа"
                    />
                    <EducationCell
                      className="instance-node-2"
                      text="Психодемия"
                      text1="Обучение супервизии"
                      text2="2024 г."
                      text3="190 часов"
                    />
                    <EducationCell
                      className="instance-node-2"
                      text="IFS Institute"
                      text1="IFS Level 1 Training"
                      text2="2023-2024 гг."
                      text3="90 часов"
                    />
                    <EducationCell
                      className="instance-node-2"
                      text="Loch Kelly"
                      text1="продвинутые семинары в Effortless Mindfulness и IFS по темам «Effortless Mindfulness and Self-Leadership», «How To Shift Out of Ego Into Awake Self»"
                      text2="2023-2024 гг."
                      text3="90 часов"
                    />
                    <EducationCell
                      className="instance-node-2"
                      text="Bruce Hersey"
                      text1="IFS-informed EMDR 1−3"
                      text2="2024 г."
                      text3=" 6 часов"
                    />
                  </>
                )}

                {screenWidth >= 768 && screenWidth < 960 && isDetailsOpen && (
                  <>
                    <Bullet
                      className="bullet-instance"
                      divClassName="frame-65"
                      divClassName1="instance-node-3"
                      divClassName2="frame-66"
                      divClassNameOverride="instance-node-3"
                      line="/img/line-8-55.svg"
                    />
                    <Bullet
                      className="bullet-instance"
                      divClassName="frame-65"
                      divClassName1="instance-node-3"
                      divClassName2="frame-66"
                      divClassNameOverride="instance-node-3"
                      line="/img/line-8-55.svg"
                      text="НИУ ВШЭ"
                      text1="Психоанализ и психоаналитическая психотерапия"
                      text2="магистр, 2017–2019 гг."
                    />
                    <Bullet
                      className="bullet-instance"
                      divClassName="frame-65"
                      divClassName1="instance-node-3"
                      divClassName2="frame-66"
                      divClassNameOverride="instance-node-3"
                      line="/img/line-8-55.svg"
                      text="МГИ"
                      text1="Базовая программа по гештальт-терапии"
                      text2="2016–2020 гг."
                      text3="600 часов"
                    />
                    <Bullet
                      className="bullet-instance"
                      divClassName="frame-65"
                      divClassName1="instance-node-3"
                      divClassName2="frame-66"
                      divClassNameOverride="instance-node-3"
                      line="/img/line-8-58.svg"
                      text="ИПКиРТ"
                      text1="Психодрама"
                      text2="2016–2019 гг."
                      text3="900 часов"
                    />
                    <Bullet
                      className="bullet-instance"
                      divClassName="frame-65"
                      divClassName1="instance-node-3"
                      divClassName2="frame-66"
                      divClassNameOverride="instance-node-3"
                      line="/img/line-8-55.svg"
                      text="Heril Formations"
                      text1="французская программа «Сексотерапия и терапия пар»"
                      text2="2019&nbsp;&nbsp;г."
                      text3=" 75 часов"
                    />
                    <Bullet
                      className="bullet-instance"
                      divClassName="frame-67"
                      divClassName1="instance-node-3"
                      divClassName2="frame-66"
                      divClassNameOverride="instance-node-3"
                      line="/img/line-8-60.svg"
                      text="The International Focusing Institute"
                      text1="Focusing 1−3"
                      text2="2021–2022 гг."
                      text3="52 часа"
                    />
                    <Bullet
                      className="bullet-instance"
                      divClassName="frame-65"
                      divClassName1="instance-node-3"
                      divClassName2="frame-66"
                      divClassNameOverride="instance-node-3"
                      line="/img/line-8-60.svg"
                      text="Психодемия"
                      text1="Терапия внутренних семейных систем (IFS)"
                      text2="2022-2023 гг."
                      text3="78 часов"
                    />
                    <Bullet
                      className="bullet-instance"
                      divClassName="frame-65"
                      divClassName1="instance-node-3"
                      divClassName2="frame-66"
                      divClassNameOverride="instance-node-3"
                      line="/img/line-8-60.svg"
                      text="Психодемия"
                      text1="Эмоционально-фокусированная терапия в работе с парами"
                      text2="2023 г."
                      text3="162 часа"
                    />
                    <Bullet
                      className="bullet-instance"
                      divClassName="frame-65"
                      divClassName1="instance-node-3"
                      divClassName2="frame-66"
                      divClassNameOverride="instance-node-3"
                      line="/img/line-8-60.svg"
                      text="Психодемия"
                      text1="Обучение супервизии"
                      text2="2024 г."
                      text3="190 часов"
                    />
                    <Bullet
                      className="bullet-instance"
                      divClassName="frame-65"
                      divClassName1="instance-node-3"
                      divClassName2="frame-66"
                      divClassNameOverride="instance-node-3"
                      line="/img/line-8-60.svg"
                      text="IFS Institute"
                      text1="IFS Level 1 Training"
                      text2="2023-2024 гг."
                      text3="90 часов"
                    />
                    <div className="frame-30">
                      <div className="frame-31">
                        <div className="frame-32">
                          <div className="text-wrapper-114">Loch Kelly</div>
                          <p className="text-wrapper-115">
                            продвинутые семинары в Effortless Mindfulness и IFS по темам «Effortless Mindfulness and
                            Self-Leadership», «How To Shift Out of Ego Into Awake Self»
                          </p>
                        </div>
                        <div className="frame-68">
                          <div className="text-wrapper-116">2023-2024 гг.</div>
                          <div className="text-wrapper-117">90 часов</div>
                        </div>
                      </div>
                      <img className="line-10" alt="Line" src="/img/line-8-60.svg" />
                    </div>
                    <Bullet
                      className="bullet-instance"
                      divClassName="frame-65"
                      divClassName1="instance-node-3"
                      divClassName2="frame-66"
                      divClassNameOverride="instance-node-3"
                      line="/img/line-8-66.svg"
                      text="Bruce Hersey"
                      text1="IFS-informed EMDR 1−3"
                      text2="2024 г."
                      text3=" 6 часов"
                    />
                  </>
                )}
              </div>
              <div
                className="frame-69"
                style={{
                  alignItems:
                    screenWidth < 480 ? "center" : screenWidth >= 768 && screenWidth < 960 ? "flex-start" : undefined,
                  justifyContent: screenWidth < 480 ? "center" : undefined,
                }}
              >
                <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                  <img
                    className="button-13"
                    style={{
                      flex: screenWidth >= 768 && screenWidth < 960 ? "0 0 auto" : undefined,
                      width: screenWidth < 480 ? "319px" : undefined,
                    }}
                    alt="Button"
                    src={
                      screenWidth < 480
                        ? "/img/button-6.svg"
                        : screenWidth >= 768 && screenWidth < 960
                        ? "/img/button-23.svg"
                        : undefined
                    }
                  />
                </a>
              </div>
            </div>
            <div
              className="overlap-26"
              style={{
                height: screenWidth >= 768 && screenWidth < 960 ? "1137px" : screenWidth < 480 ? "1156px" : undefined,
                top: screenWidth >= 768 && screenWidth < 960 & isDetailsOpen 
                  ? "3604px" : screenWidth >= 768 && screenWidth < 960 
                  ? "2106px" : screenWidth < 480 && isDetailsOpen && isAlchemyOpen 
                  ? "3180px" : screenWidth < 480 && !isDetailsOpen && isAlchemyOpen 
                  ? "2020px" : screenWidth < 480 && isDetailsOpen && !isAlchemyOpen
                  ? "3160px" : "2020px",
                width: screenWidth >= 768 && screenWidth < 960 ? "748px" : screenWidth < 480 ? "340px" : "340px",
              }}
            >
              <div
                className="view-31"
                style={{
                  height: screenWidth >= 768 && screenWidth < 960 ? "1137px" : screenWidth < 480 ? "1156px" : undefined,
                  width: screenWidth >= 768 && screenWidth < 960 ? "732px" : screenWidth < 480 ? "322px" : undefined,
                }}
              >
                {screenWidth >= 768 && screenWidth < 960 && (
                  <div className="text-wrapper-118">
                    НАЙДЁМ ВЫХОД <br />
                    ИЗ ЛАБИРИНТА СТРАДАНИЙ
                  </div>
                )}

                {screenWidth < 480 && (
                  <div className="overlap-27">
                    <div className="text-wrapper-119">
                      НАЙДЁМ ВЫХОД <br />
                      ИЗ ЛАБИРИНТА СТРАДАНИЙ
                    </div>
                    <div className="pattern-8" />
                  </div>
                )}
                <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                  <img
                    className="button-14"
                    style={{
                      height: screenWidth >= 768 && screenWidth < 960 ? "64px" : screenWidth < 480 ? "54px" : undefined,
                      left: screenWidth >= 768 && screenWidth < 960 ? "354px" : screenWidth < 480 ? "0" : undefined,
                      top: screenWidth >= 768 && screenWidth < 960 ? "1073px" : screenWidth < 480 ? "1102px" : undefined,
                      width: screenWidth >= 768 && screenWidth < 960 ? "363px" : screenWidth < 480 ? "320px" : undefined,
                    }}
                    alt="Button"
                    src={
                      screenWidth >= 768 && screenWidth < 960
                        ? "/img/button-24.svg"
                        : screenWidth < 480
                        ? "/img/button-8.svg"
                        : undefined
                    }
                  />
                </a>
                <div
                  className="pattern-9"
                  style={{
                    height: screenWidth >= 768 && screenWidth < 960 ? "147px" : screenWidth < 480 ? "102px" : undefined,
                    left: screenWidth >= 768 && screenWidth < 960 ? "32px" : screenWidth < 480 ? "110px" : undefined,
                    top: screenWidth >= 768 && screenWidth < 960 ? "722px" : screenWidth < 480 ? "820px" : undefined,
                    width: screenWidth >= 768 && screenWidth < 960 ? "144px" : screenWidth < 480 ? "100px" : undefined,
                  }}
                >
                  <div
                    className="overlap-group-20"
                    style={{
                      height:
                        screenWidth >= 768 && screenWidth < 960 ? "147px" : screenWidth < 480 ? "102px" : undefined,
                      left: screenWidth >= 768 && screenWidth < 960 ? "10px" : screenWidth < 480 ? "7px" : undefined,
                      width: screenWidth >= 768 && screenWidth < 960 ? "125px" : screenWidth < 480 ? "87px" : undefined,
                    }}
                  >
                    <img
                      className="polygon-12"
                      style={{
                        height:
                          screenWidth >= 768 && screenWidth < 960 ? "110px" : screenWidth < 480 ? "76px" : undefined,
                        width:
                          screenWidth >= 768 && screenWidth < 960 ? "125px" : screenWidth < 480 ? "87px" : undefined,
                      }}
                      alt="Polygon"
                      src={
                        screenWidth >= 768 && screenWidth < 960
                          ? "/img/polygon-3-2.svg"
                          : screenWidth < 480
                          ? "/img/polygon-3-1.svg"
                          : undefined
                      }
                    />
                    <img
                      className="polygon-13"
                      style={{
                        height:
                          screenWidth >= 768 && screenWidth < 960 ? "55px" : screenWidth < 480 ? "38px" : undefined,
                        left: screenWidth >= 768 && screenWidth < 960 ? "31px" : screenWidth < 480 ? "22px" : undefined,
                        top: screenWidth >= 768 && screenWidth < 960 ? "37px" : screenWidth < 480 ? "26px" : undefined,
                        width:
                          screenWidth >= 768 && screenWidth < 960 ? "62px" : screenWidth < 480 ? "43px" : undefined,
                      }}
                      alt="Polygon"
                      src={
                        screenWidth >= 768 && screenWidth < 960
                          ? "/img/polygon-5-2.svg"
                          : screenWidth < 480
                          ? "/img/polygon-5-1.svg"
                          : undefined
                      }
                    />
                    <img
                      className="polygon-14"
                      style={{
                        height:
                          screenWidth >= 768 && screenWidth < 960 ? "110px" : screenWidth < 480 ? "76px" : undefined,
                        top: screenWidth >= 768 && screenWidth < 960 ? "37px" : screenWidth < 480 ? "26px" : undefined,
                        width:
                          screenWidth >= 768 && screenWidth < 960 ? "125px" : screenWidth < 480 ? "87px" : undefined,
                      }}
                      alt="Polygon"
                      src={
                        screenWidth >= 768 && screenWidth < 960
                          ? "/img/polygon-4-2.svg"
                          : screenWidth < 480
                          ? "/img/polygon-4-1.svg"
                          : undefined
                      }
                    />
                    <img
                      className="vector-24"
                      style={{
                        height:
                          screenWidth >= 768 && screenWidth < 960 ? "72px" : screenWidth < 480 ? "50px" : undefined,
                        left: screenWidth >= 768 && screenWidth < 960 ? "62px" : screenWidth < 480 ? "43px" : undefined,
                      }}
                      alt="Vector"
                      src={
                        screenWidth >= 768 && screenWidth < 960
                          ? "/img/vector-32-2.svg"
                          : screenWidth < 480
                          ? "/img/vector-32-1.svg"
                          : undefined
                      }
                    />
                    <img
                      className="vector-25"
                      style={{
                        height:
                          screenWidth >= 768 && screenWidth < 960 ? "37px" : screenWidth < 480 ? "26px" : undefined,
                        left: screenWidth >= 768 && screenWidth < 960 ? "62px" : screenWidth < 480 ? "43px" : undefined,
                        top: screenWidth >= 768 && screenWidth < 960 ? "73px" : screenWidth < 480 ? "50px" : undefined,
                        width:
                          screenWidth >= 768 && screenWidth < 960 ? "62px" : screenWidth < 480 ? "43px" : undefined,
                      }}
                      alt="Vector"
                      src={
                        screenWidth >= 768 && screenWidth < 960
                          ? "/img/vector-33-2.svg"
                          : screenWidth < 480
                          ? "/img/vector-33-1.svg"
                          : undefined
                      }
                    />
                    <img
                      className="vector-26"
                      style={{
                        height:
                          screenWidth >= 768 && screenWidth < 960 ? "37px" : screenWidth < 480 ? "26px" : undefined,
                        left: screenWidth >= 768 && screenWidth < 960 ? "1px" : screenWidth < 480 ? "0" : undefined,
                        top: screenWidth >= 768 && screenWidth < 960 ? "73px" : screenWidth < 480 ? "50px" : undefined,
                        width:
                          screenWidth >= 768 && screenWidth < 960 ? "62px" : screenWidth < 480 ? "43px" : undefined,
                      }}
                      alt="Vector"
                      src={
                        screenWidth >= 768 && screenWidth < 960
                          ? "/img/vector-34-2.svg"
                          : screenWidth < 480
                          ? "/img/vector-34-1.svg"
                          : undefined
                      }
                    />
                  </div>
                </div>
                {screenWidth < 480 && (
                  <>
                    <div className="group-17">
                      <p className="text-wrapper-120">
                        Я сопровождаю вас в процессе ваших глубинных изменений.&nbsp;&nbsp;Вместе мы идем в исследование
                        механизмов психики и слой за слоем снимаем пелену иллюзий, отделяющую вас от себя настоящего.
                      </p>
                      <div className="quote-8">
                        <img className="union-19" alt="Union" src="/img/union-19.svg" />
                        <img className="union-20" alt="Union" src="/img/union-20.svg" />
                      </div>
                    </div>
                    <div className="view-32">
                      <div className="frame-31">
                        <div className="text-wrapper-121">Построение отношений</div>
                        <img className="star-22" alt="Star" src="/img/star-16-1.svg" />
                      </div>
                      <p className="text-wrapper-122">
                        Вы научитесь создавать гармоничные отношения (в том числе сексуальные), выходить из
                        эмоциональной зависимости и экологично проживать кризисы и расставания.
                      </p>
                    </div>
                    <div className="view-33">
                      <div className="frame-31">
                        <div className="text-wrapper-121">Глубинное самоисследование</div>
                        <img className="star-22" alt="Star" src="/img/star-16-1.svg" />
                      </div>
                      <p className="text-wrapper-122">
                        Вы сможете встать на свой путь в жизни, найти ответ на вопрос «Кто Я?», выйти из кризиса
                        смыслов, глубже познакомиться с собой истинным и ощутить подлинную связь с миром.
                      </p>
                    </div>
                    <div className="view-34">
                      <div className="frame-31">
                        <div className="text-wrapper-121">Эмоциональная регуляция</div>
                        <img className="star-22" alt="Star" src="/img/star-16-1.svg" />
                      </div>
                      <p className="text-wrapper-122">
                        Вы постепенно выстроите экологичные отношения со своими эмоциями: исцелите депрессию, апатию,
                        страхи или фоновую тревогу и вернёте себе душевный покой.
                      </p>
                    </div>
                    <div className="view-35">
                      <div className="frame-31">
                        <div className="text-wrapper-123">Самоценность</div>
                        <img className="star-22" alt="Star" src="/img/star-16-1.svg" />
                      </div>
                      <p className="text-wrapper-122">
                        Вы попрощаетесь с синдромом самозванца, исцелите чувства вины и стыда, одиночества и ненужности,
                        приведёте в баланс самооценку и выстроите целостное ощущение себя в этом мире.
                      </p>
                    </div>
                  </>
                )}

                {screenWidth >= 768 && screenWidth < 960 && (
                  <>
                    <p className="text-wrapper-124">
                      Я сопровождаю вас в процессе ваших глубинных изменений.&nbsp;&nbsp;Вместе мы идем в исследование
                      механизмов психики и слой за слоем снимаем пелену иллюзий, отделяющую вас от себя настоящего.
                    </p>
                    <div className="quote-9">
                      <img className="union-21" alt="Union" src="/img/union-48.svg" />
                      <img className="union-22" alt="Union" src="/img/union-49.svg" />
                    </div>
                    <div className="view-36">
                      <div className="frame-31">
                        <div className="text-wrapper-125">
                          Построение <br />
                          отношений
                        </div>
                        <img className="star-13" alt="Star" src="/img/star-17.svg" />
                      </div>
                      <p className="text-wrapper-126">
                        Вы научитесь создавать гармоничные отношения (в том числе сексуальные), выходить из
                        эмоциональной зависимости и экологично проживать кризисы и расставания.
                      </p>
                    </div>
                    <div className="view-37">
                      <div className="frame-31">
                        <div className="text-wrapper-127">Глубинное самоисследование</div>
                        <img className="star-13" alt="Star" src="/img/star-17.svg" />
                      </div>
                      <p className="text-wrapper-126">
                        Вы сможете встать на свой путь в жизни, найти ответ на вопрос «Кто Я?», выйти из кризиса
                        смыслов, глубже познакомиться с собой истинным и ощутить подлинную связь с миром.
                      </p>
                    </div>
                    <div className="view-38">
                      <div className="frame-31">
                        <div className="text-wrapper-128">Эмоциональная регуляция</div>
                        <img className="star-13" alt="Star" src="/img/star-17.svg" />
                      </div>
                      <p className="text-wrapper-126">
                        Вы постепенно выстроите экологичные отношения со своими эмоциями: исцелите депрессию, апатию,
                        страхи или фоновую тревогу и вернёте себе душевный покой.
                      </p>
                    </div>
                    <div className="view-39">
                      <div className="frame-31">
                        <div className="text-wrapper-129">Самоценность</div>
                        <img className="star-23" alt="Star" src="/img/star-17.svg" />
                      </div>
                      <p className="text-wrapper-126">
                        Вы попрощаетесь с синдромом самозванца, исцелите чувства вины и стыда, одиночества и ненужности,
                        приведёте в баланс самооценку и выстроите целостное ощущение себя в этом мире.
                      </p>
                    </div>
                    <div className="pattern-10" />
                  </>
                )}
              </div>
              <img
                className="vector-27"
                style={{
                  height: screenWidth >= 768 && screenWidth < 960 ? "995px" : screenWidth < 480 ? "270px" : undefined,
                  left: screenWidth >= 768 && screenWidth < 960 ? "580px" : screenWidth < 480 ? "282px" : undefined,
                  top: screenWidth >= 768 && screenWidth < 960 ? "66px" : screenWidth < 480 ? "824px" : undefined,
                  width: screenWidth >= 768 && screenWidth < 960 ? "168px" : screenWidth < 480 ? "58px" : undefined,
                }}
                alt="Vector"
                src={
                  screenWidth >= 768 && screenWidth < 960
                    ? "/img/vector-25-4.svg"
                    : screenWidth < 480
                    ? "/img/vector-25-1.svg"
                    : undefined
                }
              />
            </div>
            <div
              className="view-40"
              style={{
                height: screenWidth >= 768 && screenWidth < 960 ? "1290px" : screenWidth < 480 ? "1731px" : undefined,
                top: screenWidth >= 768 && screenWidth < 960 && isDetailsOpen 
                ? "4811px" : screenWidth >= 768 && screenWidth < 960 
                ? "3313px" : screenWidth < 480 && isDetailsOpen && isAlchemyOpen
                ? "4363px" : screenWidth < 480 && !isDetailsOpen && isAlchemyOpen
                ? "3200px" : screenWidth < 480 && isDetailsOpen && !isAlchemyOpen
                ? "4350px" : "3200px",
                width: screenWidth >= 768 && screenWidth < 960 ? "734px" : screenWidth < 480 ? "322px" : undefined,
              }}
            >
              <div
                className="text-wrapper-130"
                style={{
                  fontSize: screenWidth >= 768 && screenWidth < 960 ? "42px" : screenWidth < 480 ? "24px" : undefined,
                  lineHeight:
                    screenWidth >= 768 && screenWidth < 960 ? "50.4px" : screenWidth < 480 ? "28.8px" : undefined,
                  whiteSpace: screenWidth >= 768 && screenWidth < 960 ? "nowrap" : undefined,
                  width: screenWidth < 480 ? "320px" : undefined,
                }}
              >
                АЛХИМИЯ ТРАНСФОРМАЦИИ
              </div>
              <div
                className="view-41"
                style={{
                  height: screenWidth >= 768 && screenWidth < 960 ? "422px" : screenWidth < 480 ? "555px" : undefined,
                  top: screenWidth >= 768 && screenWidth < 960 ? "80px" : screenWidth < 480 ? "78px" : undefined,
                  width: screenWidth >= 768 && screenWidth < 960 ? "730px" : screenWidth < 480 ? "322px" : undefined,
                }}
              >
                <div
                  className="frame-70"
                  style={{
                    alignItems:
                      screenWidth >= 768 && screenWidth < 960 ? "center" : screenWidth < 480 ? "flex-start" : undefined,
                    gap: screenWidth >= 768 && screenWidth < 960 ? "34px" : screenWidth < 480 ? "16px" : undefined,
                    padding:
                      screenWidth >= 768 && screenWidth < 960
                        ? "24px 34px 24px 40px"
                        : screenWidth < 480
                        ? "16px"
                        : undefined,
                    width: screenWidth >= 768 && screenWidth < 960 ? "728px" : screenWidth < 480 ? "320px" : undefined,
                  }}
                >
                  <img
                    className="star-24"
                    style={{
                      height:
                        screenWidth >= 768 && screenWidth < 960 ? "22.81px" : screenWidth < 480 ? "15.21px" : undefined,
                      width:
                        screenWidth >= 768 && screenWidth < 960 ? "23.4px" : screenWidth < 480 ? "15.6px" : undefined,
                    }}
                    alt="Star"
                    src={
                      screenWidth >= 768 && screenWidth < 960
                        ? "/img/star-17.svg"
                        : screenWidth < 480
                        ? "/img/star-16-1.svg"
                        : undefined
                    }
                  />
                  <div
                    className="IFS-EMDR-2"
                    style={{
                      fontSize:
                        screenWidth >= 768 && screenWidth < 960 ? "20px" : screenWidth < 480 ? "17px" : undefined,
                      lineHeight:
                        screenWidth >= 768 && screenWidth < 960 ? "24.0px" : screenWidth < 480 ? "20.4px" : undefined,
                    }}
                  >
                    {screenWidth >= 768 && screenWidth < 960 && (
                      <p className="text-wrapper-164">
                        В работе с клиентами я использую IFS-терапию с элементами EMDR — это психотерапия, приводящая
                        к трансформационным изменениям путем реконсолидации памяти.
                      </p>
                    )}

                    {screenWidth < 480 && (
                      <p className="text-wrapper-164">
                        В работе с клиентами я использую IFS-терапию с элементами EMDR — это психотерапия, приводящая
                        к трансформационным изменениям путем реконсолидации памяти.
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className="view-42"
                  style={{
                    color: screenWidth < 480 ? "var(--variable-collection-white-50)" : undefined,
                    fontFamily: screenWidth < 480 ? "'DM Sans', Helvetica" : undefined,
                    fontSize: screenWidth < 480 ? "14px" : undefined,
                    fontStyle: screenWidth < 480 ? "italic" : undefined,
                    fontWeight: screenWidth < 480 ? "400" : undefined,
                    height: screenWidth >= 768 && screenWidth < 960 ? "104px" : undefined,
                    letterSpacing: screenWidth < 480 ? "0" : undefined,
                    lineHeight: screenWidth < 480 ? "16.8px" : undefined,
                    top: screenWidth >= 768 && screenWidth < 960 ? "174px" : screenWidth < 480 ? "334px" : undefined,
                    width: screenWidth >= 768 && screenWidth < 960 ? "728px" : screenWidth < 480 ? "320px" : undefined,
                  }}
                >
                  {screenWidth >= 768 && screenWidth < 960 && (
                    <div className="frame-71">
                      <div className="div-9">
                        <img className="img-2" alt="Img" src="/img/3.svg" />
                        <div className="text-wrapper-131">Ре-активация памяти</div>
                      </div>
                      <img className="line-20" alt="Line" src="/img/line-66-2.svg" />
                      <div className="div-9">
                        <div className="frame-72">
                          <div className="overlap-23">
                            <img className="rectangle-56" alt="Rectangle" src="/img/rectangle-74-4.svg" />
                            <img className="vector-12" alt="Vector" src="/img/vector-37-4.svg" />
                            <div className="group-18">
                              <div className="overlap-group-14">
                                <img className="vector-13" alt="Vector" src="/img/vector-27-4.svg" />
                                <div className="rectangle-57" />
                              </div>
                              <div className="rectangle-58" />
                            </div>
                            <img className="vector-28" alt="Vector" src="/img/vector-4.svg" />
                          </div>
                        </div>
                        <div className="text-wrapper-131">Не-подтверждение данных</div>
                      </div>
                      <img className="line-20" alt="Line" src="/img/line-66-2.svg" />
                      <div className="div-9">
                        <img className="img-2" alt="Img" src="/img/3-2.svg" />
                        <div className="text-wrapper-132">Ре-консолидация памяти</div>
                      </div>
                    </div>
                  )}

                  {screenWidth < 480 && (
                    <p className="text-wrapper-164">
                      Реконсолидация памяти - форма нейропластичности, которая изменяет существующую эмоциональную
                      память на синаптическом уровне (Ecker, 2012). Используя техники работы со вниманием, мы переводим
                      то, что записано у нас в бессознательной памяти, в открытое для редактирования состояние. Далее мы
                      подгружаем update: новый опыт или информацию, которая опровергает хранящиеся там убеждения. После
                      обновления нейронная сеть памяти вновь возвращается в исходное состояние.
                    </p>
                  )}
                </div>
                <div
                  className="ecker-update"
                  style={{
                    color: screenWidth >= 768 && screenWidth < 960 ? "var(--variable-collection-white-50)" : undefined,
                    fontFamily: screenWidth >= 768 && screenWidth < 960 ? "'DM Sans', Helvetica" : undefined,
                    fontSize: screenWidth >= 768 && screenWidth < 960 ? "16px" : undefined,
                    fontStyle: screenWidth >= 768 && screenWidth < 960 ? "italic" : undefined,
                    fontWeight: screenWidth >= 768 && screenWidth < 960 ? "400" : undefined,
                    height: screenWidth < 480 ? "112px" : undefined,
                    letterSpacing: screenWidth >= 768 && screenWidth < 960 ? "0" : undefined,
                    lineHeight: screenWidth >= 768 && screenWidth < 960 ? "19.2px" : undefined,
                    top: screenWidth >= 768 && screenWidth < 960 ? "308px" : screenWidth < 480 ? "202px" : undefined,
                    width: screenWidth >= 768 && screenWidth < 960 ? "728px" : screenWidth < 480 ? "313px" : undefined,
                  }}
                >
                  {screenWidth >= 768 && screenWidth < 960 && (
                    <p className="text-wrapper-164">
                      Реконсолидация памяти - форма нейропластичности, которая изменяет существующую эмоциональную
                      память на синаптическом уровне (Ecker, 2012). Используя техники работы со вниманием, мы переводим
                      то, что записано у нас в бессознательной памяти, в открытое для редактирования состояние. Далее мы
                      подгружаем update: новый опыт или информацию, которая опровергает хранящиеся там убеждения. После
                      обновления нейронная сеть памяти вновь возвращается в исходное состояние.
                    </p>
                  )}

                  {screenWidth < 480 && (
                    <>
                      <div className="div-10">
                        <img className="img-4" alt="Img" src="/img/img-2.svg" />
                        <div className="text-wrapper-133">Ре-активация памяти</div>
                      </div>
                      <img className="vector-29" alt="Vector" src="/img/vector-43.svg" />
                      <div className="div-18">
                        <div className="img-4">
                          <div className="overlap-group-21">
                            <img className="rectangle-59" alt="Rectangle" src="/img/rectangle-74-1.svg" />
                            <img className="vector-30" alt="Vector" src="/img/vector-37-1.svg" />
                            <div className="group-19">
                              <img className="vector-31" alt="Vector" src="/img/vector-27-1.svg" />
                              <img className="rectangle-60" alt="Rectangle" src="/img/rectangle-66.svg" />
                              <div className="rectangle-61" />
                            </div>
                            <img className="vector-32" alt="Vector" src="/img/vector-1.svg" />
                          </div>
                        </div>
                        <div className="text-wrapper-133">Не-подтверждение данных</div>
                      </div>
                      <img className="vector-33" alt="Vector" src="/img/vector-43.svg" />
                      <div className="div-19">
                        <img className="img-4" alt="Img" src="/img/img-3.svg" />
                        <div className="text-wrapper-133">Ре-консолидация памяти</div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div
                className="view-43"
                style={{
                  gap: screenWidth >= 768 && screenWidth < 960 ? "30px" : screenWidth < 480 ? "20px" : undefined,
                  top: screenWidth >= 768 && screenWidth < 960 ? "532px" : screenWidth < 480 ? "653px" : undefined,
                  width: screenWidth >= 768 && screenWidth < 960 ? "732px" : screenWidth < 480 ? "320px" : undefined,
                }}
              >
                <div
                  className="view-44"
                  style={{
                    width: screenWidth >= 768 && screenWidth < 960 ? "728px" : screenWidth < 480 ? "320px" : undefined,
                  }}
                >
                  <div
                    className="text-wrapper-134"
                    style={{
                      fontSize:
                        screenWidth >= 768 && screenWidth < 960 ? "32px" : screenWidth < 480 ? "22px" : undefined,
                      lineHeight:
                        screenWidth >= 768 && screenWidth < 960 ? "38.4px" : screenWidth < 480 ? "26.4px" : undefined,
                    }}
                  >
                    Узнать подробнее
                  </div>
                  <img
                    className="frame-73"
                    style={{
                      height: screenWidth >= 768 && screenWidth < 960 ? "32px" : screenWidth < 480 ? "24px" : undefined,
                      width: screenWidth >= 768 && screenWidth < 960 ? "32px" : screenWidth < 480 ? "24px" : undefined,
                    }}
                    alt="Frame"
                    src={isAlchemyOpen ? "/img/frame-83.svg" : "/img/dropdownArrow.svg"}
                    onClick={toggleAlchemyDropdown}
                  />
                </div>
                {isAlchemyOpen && <>
                  <div
                    className="flexcontainer-7"
                    style={{
                      width: screenWidth >= 768 && screenWidth < 960 ? "728px" : screenWidth < 480 ? "320px" : undefined,
                    }}
                  >
                    <p
                      className="text-11"
                      style={{
                        fontSize:
                          screenWidth >= 768 && screenWidth < 960 ? "16px" : screenWidth < 480 ? "14px" : undefined,
                        lineHeight:
                          screenWidth >= 768 && screenWidth < 960 ? "19.2px" : screenWidth < 480 ? "16.8px" : undefined,
                      }}
                    >
                      <span
                        className="text-wrapper-135"
                        style={{
                          fontSize:
                            screenWidth >= 768 && screenWidth < 960 ? "16px" : screenWidth < 480 ? "14px" : undefined,
                          lineHeight:
                            screenWidth >= 768 && screenWidth < 960 ? "19.2px" : screenWidth < 480 ? "16.8px" : undefined,
                        }}
                      >
                        Мы склонны считать, что сознательно решаем, как нам справляться с различными ситуациями и
                        проблемами, возникающими в повседневной жизни. Однако во многом наше поведение обусловлено
                        научением, хранящимся в бессознательной памяти. Когда вы едете на велосипеде, то вам не нужно
                        сознательно думать о том, как держать равновесие. Это происходит автоматически, благодаря
                        бессознательной памяти. В действительности, большинство способов, которыми мы реагируем на вещи в
                        течение дня, не являются когнитивными или сознательными. <br />
                      </span>
                    </p>
                    <div
                      className="text-12"
                      style={{
                        fontSize:
                          screenWidth >= 768 && screenWidth < 960 ? "16px" : screenWidth < 480 ? "14px" : undefined,
                        lineHeight:
                          screenWidth >= 768 && screenWidth < 960 ? "19.2px" : screenWidth < 480 ? "16.8px" : undefined,
                      }}
                    >
                      <span
                        className="span-4"
                        style={{
                          fontSize:
                            screenWidth >= 768 && screenWidth < 960 ? "16px" : screenWidth < 480 ? "14px" : undefined,
                          lineHeight:
                            screenWidth >= 768 && screenWidth < 960 ? "19.2px" : screenWidth < 480 ? "16.8px" : undefined,
                        }}
                      >
                        {screenWidth >= 768 && screenWidth < 960 && (
                          <>
                            Некоторые виды психотерапии в основном работают с сознательной памятью, взаимодействуя
                            с рациональным мышлением. Если вы когда-либо были разочарованы тем, что терапевтический
                            процесс, казалось бы, ходит по кругу — вы говорите о проблемах, вместо того чтобы на самом
                            деле что-то менять, то возможно, ваша терапия осталась на более сознательном уровне обработки.
                            Многие из этих видов психотерапии (например, КПТ-терапия) пытаются помочь вам контролировать
                            бессознательное научение и использовать логику и рациональность для выбора нового поведения,
                            противоречащего тому, что для вас привычно. <br />
                          </>
                        )}

                        {screenWidth < 480 && (
                          <>
                            Некоторые виды психотерапии в основном работают с сознательной памятью, взаимодействуя
                            с рациональным мышлением. Если вы когда-либо были разочарованы тем, что терапевтический
                            процесс, казалось бы, ходит по кругу — вы говорите о проблемах, вместо того чтобы на самом
                            деле что-то менять, то возможно, ваша терапия осталась на более сознательном уровне обработки.
                            Многие из этих видов психотерапии (например, КПТ-терапия) пытаются помочь вам контролировать
                            бессознательное научение и использовать логику и рациональность для выбора нового поведения,
                            противоречащего тому, что для вас привычно. <br />
                          </>
                        )}
                      </span>
                    </div>
                    <p
                      className="text-13"
                      style={{
                        fontSize:
                          screenWidth >= 768 && screenWidth < 960 ? "16px" : screenWidth < 480 ? "14px" : undefined,
                        lineHeight:
                          screenWidth >= 768 && screenWidth < 960 ? "19.2px" : screenWidth < 480 ? "16.8px" : undefined,
                      }}
                    >
                      <span
                        className="text-wrapper-136"
                        style={{
                          fontSize:
                            screenWidth >= 768 && screenWidth < 960 ? "16px" : screenWidth < 480 ? "14px" : undefined,
                          lineHeight:
                            screenWidth >= 768 && screenWidth < 960 ? "19.2px" : screenWidth < 480 ? "16.8px" : undefined,
                        }}
                      >
                        IFS-терапия отличается — она предлагает способ взаимодействия с бессознательными паттернами,
                        к которым у нас обычно нет доступа. Здесь мы фактически реорганизуем память, поэтому люди часто
                        удивляются, насколько трансформационными могут быть IFS-сессии. Трудности или симптомы нередко
                        исчезают без необходимости постоянно поддерживать эти изменения, так как травма исцеляется на
                        глубинном уровне. Это может стать переломным моментом в жизни, особенно если вы потратили годы на
                        терапию, которая не приносила желаемых результатов.
                      </span>
                    </p>
                  </div>
                  <div
                    className="frame-74"
                    style={{
                      gap: screenWidth >= 768 && screenWidth < 960 ? "30px" : screenWidth < 480 ? "20px" : undefined,
                      width: screenWidth >= 768 && screenWidth < 960 ? "728px" : screenWidth < 480 ? "320px" : undefined,
                    }}
                  >
                    <div className="frame-31">
                      <div
                        className="frame-75"
                        style={{
                          width:
                            screenWidth >= 768 && screenWidth < 960 ? "479px" : screenWidth < 480 ? "173px" : undefined,
                        }}
                      >
                        <div
                          className="text-wrapper-137"
                          style={{
                            fontSize:
                              screenWidth >= 768 && screenWidth < 960 ? "22px" : screenWidth < 480 ? "15px" : undefined,
                            lineHeight:
                              screenWidth >= 768 && screenWidth < 960
                                ? "26.4px"
                                : screenWidth < 480
                                ? "18.0px"
                                : undefined,
                          }}
                        >
                          Ecker, B., Bridges, S.K.
                        </div>
                        <p
                          className="text-wrapper-138"
                          style={{
                            fontSize:
                              screenWidth >= 768 && screenWidth < 960 ? "16px" : screenWidth < 480 ? "14px" : undefined,
                            lineHeight:
                              screenWidth >= 768 && screenWidth < 960
                                ? "19.2px"
                                : screenWidth < 480
                                ? "16.8px"
                                : undefined,
                          }}
                        >
                          How the Science of Memory Reconsolidation Advances the Effectiveness and Unification
                          of Psychotherapy
                        </p>
                      </div>
                      <div
                        className="frame-76"
                        style={{
                          width:
                            screenWidth >= 768 && screenWidth < 960 ? "203px" : screenWidth < 480 ? "138px" : undefined,
                        }}
                      >
                        <div
                          className="text-wrapper-139"
                          style={{
                            fontSize:
                              screenWidth >= 768 && screenWidth < 960 ? "16px" : screenWidth < 480 ? "14px" : undefined,
                            lineHeight:
                              screenWidth >= 768 && screenWidth < 960
                                ? "19.2px"
                                : screenWidth < 480
                                ? "16.8px"
                                : undefined,
                          }}
                        >
                          2020 г.
                        </div>
                        <p
                          className="clin-soc-work-j-2"
                          style={{
                            fontSize:
                              screenWidth >= 768 && screenWidth < 960 ? "16px" : screenWidth < 480 ? "14px" : undefined,
                            lineHeight:
                              screenWidth >= 768 && screenWidth < 960
                                ? "19.2px"
                                : screenWidth < 480
                                ? "16.8px"
                                : undefined,
                          }}
                        >
                          Clin Soc Work J 48, <br />
                          287–300
                        </p>
                      </div>
                    </div>
                    <img
                      className="line-10"
                      alt="Line"
                      src={
                        screenWidth >= 768 && screenWidth < 960
                          ? "/img/line-6-4.svg"
                          : screenWidth < 480
                          ? "/img/line-6-1.svg"
                          : undefined
                      }
                    />
                  </div>
                </>}
                  {/* <HierarchySecondary
                    className={`${screenWidth >= 768 && screenWidth < 960 && "instance-node-2"} ${
                      screenWidth < 480 && "class-12"
                    }`}
                    divClassName={`${screenWidth < 480 && "class-6"}`}
                    text="ИНФОРМАЦИЯ ПРО IFS"
                    visible={screenWidth < 480 ? false : undefined}
                  /> */}
                </div>
            </div>
            <div
              className="overlap-28"
              style={{
                height: screenWidth >= 768 && screenWidth < 960 ? "1694px" : screenWidth < 480 ? "1869px" : undefined,
                left: screenWidth >= 768 && screenWidth < 960 ? "-150px" : screenWidth < 480 ? "-31px" : undefined,
                top: screenWidth >= 768 && screenWidth < 960 && isDetailsOpen  && isAlchemyOpen 
                ? "5970px" : screenWidth >= 768 && screenWidth < 960 && !isDetailsOpen  && isAlchemyOpen 
                ? "4471px" : screenWidth >= 768 && screenWidth < 960 && isDetailsOpen  && !isAlchemyOpen
                ? "5401px" : screenWidth >= 768 && screenWidth < 960 
                ? "3901px" : screenWidth < 480 && isDetailsOpen && isAlchemyOpen 
                ? "5980px" : screenWidth < 480 && !isDetailsOpen && isAlchemyOpen 
                ? "4800px" : screenWidth < 480 && isDetailsOpen && !isAlchemyOpen 
                ? "5040px" : "3900px",
                width: screenWidth >= 768 && screenWidth < 960 ? "1158px" : screenWidth < 480 ? "472px" : undefined,
              }}
            >
              <div
                className="view-45"
                style={{
                  height: screenWidth >= 768 && screenWidth < 960 ? "985px" : screenWidth < 480 ? "1418px" : undefined,
                  width: screenWidth >= 768 && screenWidth < 960 ? "1158px" : screenWidth < 480 ? "450px" : undefined,
                }}
              >
                <div
                  className="overlap-31"
                  style={{
                    height: screenWidth < 480 ? "659px" : screenWidth >= 768 && screenWidth < 960 ? "919px" : undefined,
                    left: screenWidth < 480 ? "31px" : screenWidth >= 768 && screenWidth < 960 ? "150px" : undefined,
                    position:
                      screenWidth < 480 ? "absolute" : screenWidth >= 768 && screenWidth < 960 ? "relative" : undefined,
                    top: screenWidth < 480 ? "689px" : screenWidth >= 768 && screenWidth < 960 ? "66px" : undefined,
                    width: screenWidth < 480 ? "360px" : screenWidth >= 768 && screenWidth < 960 ? "768px" : undefined,
                  }}
                >
                  <img
                    className="union-23"
                    style={{
                      height:
                        screenWidth >= 768 && screenWidth < 960 ? "476px" : screenWidth < 480 ? "177px" : undefined,
                      top: screenWidth >= 768 && screenWidth < 960 ? "443px" : screenWidth < 480 ? "483px" : undefined,
                      width:
                        screenWidth >= 768 && screenWidth < 960 ? "768px" : screenWidth < 480 ? "360px" : undefined,
                    }}
                    alt="Union"
                    src={
                      screenWidth >= 768 && screenWidth < 960
                        ? "/img/union-44.svg"
                        : screenWidth < 480
                        ? "/img/vector-26-1.svg"
                        : undefined
                    }
                  />
                  {screenWidth >= 768 && screenWidth < 960 && (
                    <>
                      <img className="vector-34" alt="Vector" src="/img/vector-23-4.svg" />
                      <div className="text-wrapper-140">
                        ФОРМАТЫ <br />
                        ВЗАИМОДЕЙСТВИЯ
                      </div>
                    </>
                  )}

                  <div
                    className="tarif-5"
                    style={{
                      height:
                        screenWidth < 480 ? "541px" : screenWidth >= 768 && screenWidth < 960 ? "599px" : undefined,
                      top: screenWidth < 480 ? "0" : screenWidth >= 768 && screenWidth < 960 ? "256px" : undefined,
                      width:
                        screenWidth < 480 ? "325px" : screenWidth >= 768 && screenWidth < 960 ? "360px" : undefined,
                    }}
                  >
                    <div
                      className="overlap-29"
                      style={{
                        height:
                          screenWidth < 480 ? "541px" : screenWidth >= 768 && screenWidth < 960 ? "599px" : undefined,
                      }}
                    >
                      <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                        <img
                          className="button-15"
                          style={{
                            height:
                              screenWidth < 480 ? "54px" : screenWidth >= 768 && screenWidth < 960 ? "64px" : undefined,
                            left:
                              screenWidth < 480 ? "15px" : screenWidth >= 768 && screenWidth < 960 ? "19px" : undefined,
                            top:
                              screenWidth < 480 ? "454px" : screenWidth >= 768 && screenWidth < 960 ? "502px" : undefined,
                            width:
                              screenWidth < 480 ? "288px" : screenWidth >= 768 && screenWidth < 960 ? "318px" : undefined,
                          }}
                          alt="Button"
                          src={
                            screenWidth < 480
                              ? "/img/button-7.svg"
                              : screenWidth >= 768 && screenWidth < 960
                              ? "/img/button-21.svg"
                              : undefined
                          }
                        />
                      </a>
                      <div
                        className="content-6"
                        style={{
                          left:
                            screenWidth < 480 ? "23px" : screenWidth >= 768 && screenWidth < 960 ? "27px" : undefined,
                          top:
                            screenWidth < 480 ? "92px" : screenWidth >= 768 && screenWidth < 960 ? "102px" : undefined,
                          width:
                            screenWidth < 480 ? "280px" : screenWidth >= 768 && screenWidth < 960 ? "310px" : undefined,
                        }}
                      >
                        <div
                          className="div-20"
                          style={{
                            fontSize:
                              screenWidth < 480 ? "16px" : screenWidth >= 768 && screenWidth < 960 ? "22px" : undefined,
                            lineHeight:
                              screenWidth < 480
                                ? "19.2px"
                                : screenWidth >= 768 && screenWidth < 960
                                ? "26.4px"
                                : undefined,
                          }}
                        >
                          {screenWidth < 480 && <>Для психологов</>}

                          {screenWidth >= 768 && screenWidth < 960 && <>Для клиентов</>}
                        </div>
                        <div className="frame-40">
                          <div className="frame-52">
                            <div className="star-wrapper">
                              <img
                                className="star-25"
                                style={{
                                  height:
                                    screenWidth >= 768 && screenWidth < 960
                                      ? "12px"
                                      : screenWidth < 480
                                      ? "10px"
                                      : undefined,
                                  width:
                                    screenWidth >= 768 && screenWidth < 960
                                      ? "12px"
                                      : screenWidth < 480
                                      ? "10px"
                                      : undefined,
                                }}
                                alt="Star"
                                src={
                                  screenWidth >= 768 && screenWidth < 960
                                    ? "/img/star-22-36.svg"
                                    : screenWidth < 480
                                    ? "/img/star-22-9.svg"
                                    : undefined
                                }
                              />
                            </div>
                            <div
                              className="div-21"
                              style={{
                                fontSize:
                                  screenWidth >= 768 && screenWidth < 960
                                    ? "16px"
                                    : screenWidth < 480
                                    ? "14px"
                                    : undefined,
                                lineHeight:
                                  screenWidth >= 768 && screenWidth < 960
                                    ? "19.2px"
                                    : screenWidth < 480
                                    ? "16.8px"
                                    : undefined,
                              }}
                            >
                              {screenWidth >= 768 && screenWidth < 960 && (
                                <p className="text-wrapper-164">вы найдёте ключи к своей внутренней системе</p>
                              )}

                              {screenWidth < 480 && (
                                <p className="text-wrapper-164">
                                  я становлюсь вашим супервизором-наставником на пути развития как психотерапевта
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="frame-52">
                            <div className="star-wrapper">
                              <img
                                className="star-26"
                                style={{
                                  height:
                                    screenWidth < 480
                                      ? "10px"
                                      : screenWidth >= 768 && screenWidth < 960
                                      ? "12px"
                                      : undefined,
                                  width:
                                    screenWidth < 480
                                      ? "10px"
                                      : screenWidth >= 768 && screenWidth < 960
                                      ? "12px"
                                      : undefined,
                                }}
                                alt="Star"
                                src={
                                  screenWidth < 480
                                    ? "/img/star-22-9.svg"
                                    : screenWidth >= 768 && screenWidth < 960
                                    ? "/img/star-22-36.svg"
                                    : undefined
                                }
                              />
                            </div>
                            <div
                              className="div-22"
                              style={{
                                fontSize:
                                  screenWidth < 480
                                    ? "14px"
                                    : screenWidth >= 768 && screenWidth < 960
                                    ? "16px"
                                    : undefined,
                                lineHeight:
                                  screenWidth < 480
                                    ? "16.8px"
                                    : screenWidth >= 768 && screenWidth < 960
                                    ? "19.2px"
                                    : undefined,
                              }}
                            >
                              {screenWidth < 480 && (
                                <p className="text-wrapper-164">
                                  вы проанализируете разные грани вашей работы с клиентами и выведете частную практику
                                  на новый уровень
                                </p>
                              )}

                              {screenWidth >= 768 && screenWidth < 960 && (
                                <p className="text-wrapper-164">
                                  ослабите блоки, мешающие раскрытию потенциала, заложенного при рождении
                                </p>
                              )}
                            </div>
                          </div>
                          {screenWidth < 480 && (
                            <div className="frame-51">
                              <div className="frame-52">
                                <div className="star-wrapper">
                                  <img className="star-27" alt="Star" src="/img/star-22-15.svg" />
                                </div>
                                <div className="text-wrapper-141">расширите терапевтическое мышление</div>
                              </div>
                              <div className="frame-52">
                                <div className="star-wrapper">
                                  <img className="star-27" alt="Star" src="/img/star-22-15.svg" />
                                </div>
                                <div className="text-wrapper-141">повысите уровень компетенций</div>
                              </div>
                              <div className="frame-52">
                                <div className="star-wrapper">
                                  <img className="star-27" alt="Star" src="/img/star-22-15.svg" />
                                </div>
                                <p className="text-wrapper-141">выявите слепые пятна и зоны роста</p>
                              </div>
                            </div>
                          )}

                          {screenWidth >= 768 && screenWidth < 960 && (
                            <>
                              <div className="frame-52">
                                <div className="star-wrapper">
                                  <img className="star-18" alt="Star" src="/img/star-22-36.svg" />
                                </div>
                                <p className="text-wrapper-84">
                                  научитесь понимать, что происходит у вас внутри, и как эффективно и бережно
                                  возвращаться в ресурсное состояние
                                </p>
                              </div>
                              <div className="frame-52">
                                <div className="star-wrapper">
                                  <img className="star-18" alt="Star" src="/img/star-22-36.svg" />
                                </div>
                                <p className="text-wrapper-84">
                                  получите навыки, которые позволят вам стать психотерапевтом самому себе
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          className="div-23"
                          style={{
                            fontSize:
                              screenWidth < 480 ? "14px" : screenWidth >= 768 && screenWidth < 960 ? "16px" : undefined,
                            lineHeight:
                              screenWidth < 480
                                ? "16.8px"
                                : screenWidth >= 768 && screenWidth < 960
                                ? "19.2px"
                                : undefined,
                            marginRight: screenWidth < 480 ? "-12.00px" : undefined,
                          }}
                        >
                          {screenWidth < 480 && <p className="text-wrapper-164">6000 ₽ / 50 минут</p>}

                          {screenWidth >= 768 && screenWidth < 960 && (
                            <p className="text-wrapper-164">8000 ₽ / 50 минут</p>
                          )}
                        </div>
                      </div>
                      <div
                        className="arc-7"
                        style={{
                          height:
                            screenWidth < 480 ? "541px" : screenWidth >= 768 && screenWidth < 960 ? "599px" : undefined,
                          width:
                            screenWidth < 480 ? "325px" : screenWidth >= 768 && screenWidth < 960 ? "360px" : undefined,
                        }}
                      >
                        <div
                          className="overlap-group-22"
                          style={{
                            height:
                              screenWidth < 480
                                ? "541px"
                                : screenWidth >= 768 && screenWidth < 960
                                ? "599px"
                                : undefined,
                          }}
                        >
                          <img
                            className="rectangle-62"
                            style={{
                              height:
                                screenWidth < 480
                                  ? "535px"
                                  : screenWidth >= 768 && screenWidth < 960
                                  ? "593px"
                                  : undefined,
                              left:
                                screenWidth < 480 ? "2px" : screenWidth >= 768 && screenWidth < 960 ? "3px" : undefined,
                              width:
                                screenWidth < 480
                                  ? "161px"
                                  : screenWidth >= 768 && screenWidth < 960
                                  ? "180px"
                                  : undefined,
                            }}
                            alt="Rectangle"
                            src={
                              screenWidth < 480
                                ? "/img/rectangle-44-5.svg"
                                : screenWidth >= 768 && screenWidth < 960
                                ? "/img/rectangle-44-13.svg"
                                : undefined
                            }
                          />
                          <img
                            className="rectangle-63"
                            style={{
                              height:
                                screenWidth < 480
                                  ? "535px"
                                  : screenWidth >= 768 && screenWidth < 960
                                  ? "593px"
                                  : undefined,
                              left:
                                screenWidth < 480
                                  ? "163px"
                                  : screenWidth >= 768 && screenWidth < 960
                                  ? "182px"
                                  : undefined,
                              width:
                                screenWidth < 480
                                  ? "161px"
                                  : screenWidth >= 768 && screenWidth < 960
                                  ? "176px"
                                  : undefined,
                            }}
                            alt="Rectangle"
                            src={
                              screenWidth < 480
                                ? "/img/rectangle-56-5.svg"
                                : screenWidth >= 768 && screenWidth < 960
                                ? "/img/rectangle-56-13.svg"
                                : undefined
                            }
                          />
                          <img
                            className="line-21"
                            style={{
                              left:
                                screenWidth < 480 ? "3px" : screenWidth >= 768 && screenWidth < 960 ? "7px" : undefined,
                              top:
                                screenWidth < 480
                                  ? "537px"
                                  : screenWidth >= 768 && screenWidth < 960
                                  ? "595px"
                                  : undefined,
                              width:
                                screenWidth < 480
                                  ? "320px"
                                  : screenWidth >= 768 && screenWidth < 960
                                  ? "350px"
                                  : undefined,
                            }}
                            alt="Line"
                            src={
                              screenWidth < 480
                                ? "/img/line-9-5.svg"
                                : screenWidth >= 768 && screenWidth < 960
                                ? "/img/line-9-13.svg"
                                : undefined
                            }
                          />
                          <div
                            className="ellipse-37"
                            style={{
                              left:
                                screenWidth < 480
                                  ? "159px"
                                  : screenWidth >= 768 && screenWidth < 960
                                  ? "178px"
                                  : undefined,
                            }}
                          />
                          <div
                            className="ellipse-38"
                            style={{
                              top:
                                screenWidth < 480
                                  ? "535px"
                                  : screenWidth >= 768 && screenWidth < 960
                                  ? "593px"
                                  : undefined,
                            }}
                          />
                          <div
                            className="ellipse-39"
                            style={{
                              left:
                                screenWidth < 480
                                  ? "319px"
                                  : screenWidth >= 768 && screenWidth < 960
                                  ? "354px"
                                  : undefined,
                              top:
                                screenWidth < 480
                                  ? "535px"
                                  : screenWidth >= 768 && screenWidth < 960
                                  ? "593px"
                                  : undefined,
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="eye-6"
                        style={{
                          left:
                            screenWidth < 480 ? "122px" : screenWidth >= 768 && screenWidth < 960 ? "140px" : undefined,
                          top:
                            screenWidth < 480 ? "33px" : screenWidth >= 768 && screenWidth < 960 ? "43px" : undefined,
                        }}
                      >
                        <div className="overlap-23">
                          <img
                            className="subtract-17"
                            alt="Subtract"
                            src={
                              screenWidth < 480
                                ? "/img/subtract-15.svg"
                                : screenWidth >= 768 && screenWidth < 960
                                ? "/img/subtract-34.svg"
                                : undefined
                            }
                          />
                          <img
                            className="subtract-18"
                            alt="Subtract"
                            src={
                              screenWidth < 480
                                ? "/img/subtract-16.svg"
                                : screenWidth >= 768 && screenWidth < 960
                                ? "/img/subtract-35.svg"
                                : undefined
                            }
                          />
                          <img
                            className="rectangle-53"
                            alt="Rectangle"
                            src={
                              screenWidth < 480
                                ? "/img/rectangle-63-2.svg"
                                : screenWidth >= 768 && screenWidth < 960
                                ? "/img/rectangle-63-8.svg"
                                : undefined
                            }
                          />
                          <img
                            className="subtract-19"
                            alt="Subtract"
                            src={
                              screenWidth < 480
                                ? "/img/subtract-14.svg"
                                : screenWidth >= 768 && screenWidth < 960
                                ? "/img/subtract-36.svg"
                                : undefined
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="group-20"
                    style={{
                      height:
                        screenWidth < 480 ? "68px" : screenWidth >= 768 && screenWidth < 960 ? "585px" : undefined,
                      left: screenWidth < 480 ? "20px" : screenWidth >= 768 && screenWidth < 960 ? "394px" : undefined,
                      top: screenWidth < 480 ? "561px" : screenWidth >= 768 && screenWidth < 960 ? "120px" : undefined,
                      width:
                        screenWidth < 480 ? "322px" : screenWidth >= 768 && screenWidth < 960 ? "357px" : undefined,
                    }}
                  >
                    {screenWidth < 480 && (
                      <>
                        <p className="text-wrapper-142">
                          Моя главная цель - сделать так, чтобы я вам больше не понадобилась.
                        </p>
                        <div className="quote-7">
                          <img className="union-15" alt="Union" src="/img/union-16.svg" />
                          <img className="union-16" alt="Union" src="/img/union-17.svg" />
                        </div>
                      </>
                    )}

                    {screenWidth >= 768 && screenWidth < 960 && (
                      <div className="overlap-group-23">
                        <a href="https://calendly.com/clara-zhikhareva/therapy" target="_blank" rel="noopener noreferrer">
                          <img className="button-16" alt="Button" src="/img/button-22.svg" />
                        </a>
                        <div className="content-7">
                          <div className="text-wrapper-143">Для психологов</div>
                          <div className="frame-40">
                            <div className="frame-52">
                              <div className="star-wrapper">
                                <img className="star-18" alt="Star" src="/img/star-22-36.svg" />
                              </div>
                              <p className="text-wrapper-84">
                                я становлюсь вашим супервизором-наставником на пути развития как психотерапевта
                              </p>
                            </div>
                            <div className="frame-52">
                              <div className="star-wrapper">
                                <img className="star-18" alt="Star" src="/img/star-22-36.svg" />
                              </div>
                              <p className="text-wrapper-84">
                                вы проанализируете разные грани вашей работы с клиентами и выведете частную практику на
                                новый уровень
                              </p>
                            </div>
                            <div className="frame-51">
                              <div className="frame-52">
                                <div className="star-wrapper">
                                  <img className="star-18" alt="Star" src="/img/star-22-42.svg" />
                                </div>
                                <div className="text-wrapper-84">расширите терапевтическое мышление</div>
                              </div>
                              <div className="frame-52">
                                <div className="star-wrapper">
                                  <img className="star-18" alt="Star" src="/img/star-22-42.svg" />
                                </div>
                                <div className="text-wrapper-84">повысите уровень компетенций</div>
                              </div>
                              <div className="frame-52">
                                <div className="star-wrapper">
                                  <img className="star-18" alt="Star" src="/img/star-22-42.svg" />
                                </div>
                                <p className="text-wrapper-84">выявите слепые пятна и зоны роста</p>
                              </div>
                            </div>
                          </div>
                          <p className="text-wrapper-91">6000 ₽ / 50 минут</p>
                        </div>
                        <div className="arc-8">
                          <div className="overlap-group-23">
                            <img className="rectangle-64" alt="Rectangle" src="/img/rectangle-44-14.svg" />
                            <img className="rectangle-65" alt="Rectangle" src="/img/rectangle-56-14.svg" />
                            <img className="line-22" alt="Line" src="/img/line-9-14.svg" />
                            <div className="ellipse-40" />
                            <div className="ellipse-41" />
                            <div className="ellipse-42" />
                          </div>
                        </div>
                        <div className="eye-7">
                          <div className="overlap-23">
                            <img className="subtract-17" alt="Subtract" src="/img/subtract-37.svg" />
                            <img className="subtract-18" alt="Subtract" src="/img/subtract-40.svg" />
                            <img className="rectangle-53" alt="Rectangle" src="/img/rectangle-63-8.svg" />
                            <img className="subtract-19" alt="Subtract" src="/img/subtract-36.svg" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {screenWidth >= 768 && screenWidth < 960 && (
                    <div className="group-21">
                      <p className="text-wrapper-144">
                        Моя главная цель - сделать так, чтобы я вам больше не понадобилась.
                      </p>
                      <div className="quote-2">
                        <img className="union-4" alt="Union" src="/img/union-3.svg" />
                        <img className="union-5" alt="Union" src="/img/union-3.svg" />
                      </div>
                    </div>
                  )}
                </div>
                {screenWidth < 480 && (
                  <div className="overlap-30">
                    <img className="vector-35" alt="Vector" src="/img/vector-23-1.svg" />
                    <div className="text-wrapper-145">
                      ФОРМАТЫ <br />
                      ВЗАИМОДЕЙСТВИЯ
                    </div>
                    <div className="tarif-6">
                      <div className="overlap-group-24">
                        <img className="button-17" alt="Button" src="/img/button-9.svg" />
                        <div className="content-8">
                          <div className="text-wrapper-146">Для клиентов</div>
                          <div className="frame-40">
                            <div className="frame-52">
                              <div className="star-wrapper">
                                <img className="star-27" alt="Star" src="/img/star-22-9.svg" />
                              </div>
                              <p className="text-wrapper-141">вы найдёте ключи к своей внутренней системе</p>
                            </div>
                            <div className="frame-52">
                              <div className="star-wrapper">
                                <img className="star-27" alt="Star" src="/img/star-22-9.svg" />
                              </div>
                              <p className="text-wrapper-141">
                                ослабите блоки, мешающие раскрытию потенциала, заложенного при рождении
                              </p>
                            </div>
                            <div className="frame-52">
                              <div className="star-wrapper">
                                <img className="star-27" alt="Star" src="/img/star-22-9.svg" />
                              </div>
                              <p className="text-wrapper-141">
                                научитесь понимать, что происходит у вас внутри, и как эффективно и бережно возвращаться
                                в ресурсное состояние
                              </p>
                            </div>
                            <div className="frame-52">
                              <div className="star-wrapper">
                                <img className="star-27" alt="Star" src="/img/star-22-9.svg" />
                              </div>
                              <p className="text-wrapper-141">
                                получите навыки, которые позволят вам стать психотерапевтом самому себе
                              </p>
                            </div>
                          </div>
                          <p className="text-wrapper-147">8000 ₽ / 50 минут</p>
                        </div>
                        <div className="arc-9">
                          <div className="overlap-group-24">
                            <img className="rectangle-66" alt="Rectangle" src="/img/rectangle-44-4.svg" />
                            <img className="rectangle-67" alt="Rectangle" src="/img/rectangle-56-4.svg" />
                            <img className="line-23" alt="Line" src="/img/line-9-4.svg" />
                            <div className="ellipse-43" />
                            <div className="ellipse-44" />
                            <div className="ellipse-45" />
                          </div>
                        </div>
                        <div className="eye-8">
                          <div className="overlap-23">
                            <img className="subtract-17" alt="Subtract" src="/img/subtract-12.svg" />
                            <img className="subtract-18" alt="Subtract" src="/img/subtract-13.svg" />
                            <img className="rectangle-53" alt="Rectangle" src="/img/rectangle-63-2.svg" />
                            <img className="subtract-19" alt="Subtract" src="/img/subtract-14.svg" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="pattern-11"
                style={{
                  height: screenWidth >= 768 && screenWidth < 960 ? "150px" : screenWidth < 480 ? "511px" : undefined,
                  left: screenWidth >= 768 && screenWidth < 960 ? "842px" : screenWidth < 480 ? "51px" : undefined,
                  top: screenWidth >= 768 && screenWidth < 960 ? "957px" : screenWidth < 480 ? "1358px" : undefined,
                  transform: screenWidth >= 768 && screenWidth < 960 ? "rotate(-15.00deg)" : undefined,
                  width: screenWidth >= 768 && screenWidth < 960 ? "150px" : screenWidth < 480 ? "324px" : undefined,
                }}
              >
                {screenWidth >= 768 && screenWidth < 960 && (
                  <img className="union-24" alt="Union" src="/img/union-52.svg" />
                )}

                {screenWidth < 480 && (
                  <>
                    <div className="text-14">
                      <div className="frame-40">
                        <div className="frame-77">
                          <img className="star-27" alt="Star" src="/img/star-14-15.svg" />
                          <p className="text-wrapper-148">
                            формула квантовых изменений, которая избавит вас от хождения по кругу в постоянной тревоге
                            и неудовлетворённости жизнью
                          </p>
                        </div>
                        <div className="frame-77">
                          <img className="star-27" alt="Star" src="/img/star-14-16.svg" />
                          <p className="text-wrapper-148">
                            главный секрет лёгкого и комфортного роста без надрыва, достигаторства и откатов
                          </p>
                        </div>
                        <div className="frame-77">
                          <img className="star-27" alt="Star" src="/img/star-14-16.svg" />
                          <p className="text-wrapper-148">
                            моя технология выхода на новый уровень через трансформацию архитектуры своей внутренней
                            системы
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="video-4">
                      <ReactPlayer 
                          light
                          url='https://youtu.be/5440yZcZnEg'
                          width='100%'
                          height='100%'
                          playing
                        />
                    </div>
                    <div className="text-wrapper-149">ПОГРУЗИТЬСЯ ГЛУБЖЕ</div>
                    <p className="text-wrapper-150">Как расти в легкости через работу с подсознанием?</p>
                  </>
                )}
              </div>
              <div
                className="view-46"
                style={{
                  height: screenWidth >= 768 && screenWidth < 960 ? "706px" : screenWidth < 480 ? "101px" : undefined,
                  left: screenWidth >= 768 && screenWidth < 960 ? "170px" : screenWidth < 480 ? "360px" : undefined,
                  top: screenWidth >= 768 && screenWidth < 960 ? "988px" : screenWidth < 480 ? "1388px" : undefined,
                  transform: screenWidth < 480 ? "rotate(-15.00deg)" : undefined,
                  width: screenWidth >= 768 && screenWidth < 960 ? "732px" : screenWidth < 480 ? "100px" : undefined,
                }}
              >
                {screenWidth >= 768 && screenWidth < 960 && (
                  <>
                    <div className="text-15">
                      <div className="frame-40">
                        <div className="frame-53">
                          <img className="star-18" alt="Star" src="/img/star-14-34.svg" />
                          <p className="text-wrapper-92">
                            формула квантовых изменений, которая избавит вас от хождения по кругу в постоянной тревоге
                            и неудовлетворённости жизнью
                          </p>
                        </div>
                        <div className="frame-53">
                          <img className="star-18" alt="Star" src="/img/star-14-33.svg" />
                          <p className="text-wrapper-92">
                            главный секрет лёгкого и комфортного роста без надрыва, достигаторства и откатов
                          </p>
                        </div>
                        <div className="frame-53">
                          <img className="star-18" alt="Star" src="/img/star-14-34.svg" />
                          <p className="text-wrapper-92">
                            моя технология выхода на новый уровень через трансформацию архитектуры своей внутренней
                            системы
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="video-5">
                      <ReactPlayer 
                          light
                          url='https://youtu.be/5440yZcZnEg'
                          width='100%'
                          height='100%'
                          playing
                        />
                    </div>
                    <div className="text-wrapper-151">ПОГРУЗИТЬСЯ ГЛУБЖЕ</div>
                    <p className="text-wrapper-152">Как расти в легкости через работу с подсознанием?</p>
                  </>
                )}

                {screenWidth < 480 && <img className="union-25" alt="Union" src="/img/union-18.svg" />}
              </div>
            </div>
            <div
              className="view-47"
              style={{
                left: screenWidth < 480 ? "20px" : screenWidth >= 768 && screenWidth < 960 ? "22px" : undefined,
                top: screenWidth < 480 && isDetailsOpen && isAlchemyOpen 
                ? "7900px" : screenWidth < 480 && !isDetailsOpen && isAlchemyOpen
                ? "6700px" : screenWidth < 480 && isDetailsOpen && !isAlchemyOpen
                ? "6930px" : screenWidth < 480 && !isDetailsOpen && !isAlchemyOpen
                ? "5780px"
                : screenWidth >= 768 && screenWidth < 960 && isDetailsOpen && isAlchemyOpen ? "7725px" 
                : screenWidth >= 768 && screenWidth < 960 && !isDetailsOpen && isAlchemyOpen ? "6225px"
                : screenWidth >= 768 && screenWidth < 960 && isDetailsOpen && !isAlchemyOpen ? "7125px"
                : "5620px", 
                width: screenWidth < 480 ? "322px" : screenWidth >= 768 && screenWidth < 960 ? "728px" : undefined,
              }}
            >
              <div
                className="text-wrapper-153"
                style={{
                  fontSize: screenWidth < 480 ? "24px" : screenWidth >= 768 && screenWidth < 960 ? "42px" : undefined,
                  lineHeight:
                    screenWidth < 480 ? "28.8px" : screenWidth >= 768 && screenWidth < 960 ? "50.4px" : undefined,
                  whiteSpace: screenWidth >= 768 && screenWidth < 960 ? "nowrap" : undefined,
                  width: screenWidth < 480 ? "162px" : undefined,
                }}
              >
                МОИ СТАТЬИ
              </div>
              <div
                className="view-48"
                style={{
                  gap: screenWidth < 480 ? "12px" : screenWidth >= 768 && screenWidth < 960 ? "30px" : undefined,
                  top: screenWidth < 480 ? "49px" : screenWidth >= 768 && screenWidth < 960 ? "80px" : undefined,
                  width: screenWidth < 480 ? "320px" : screenWidth >= 768 && screenWidth < 960 ? "726px" : undefined,
                }}
              >
                <div
                  className="frame-78"
                  style={{
                    gap: screenWidth < 480 ? "10px" : screenWidth >= 768 && screenWidth < 960 ? "20px" : undefined,
                  }}
                >
                  <div className="frame-31">
                    <div
                      className="text-wrapper-154"
                      style={{
                        fontSize:
                          screenWidth >= 768 && screenWidth < 960 ? "22px" : screenWidth < 480 ? "16px" : undefined,
                        lineHeight:
                          screenWidth >= 768 && screenWidth < 960 ? "26.4px" : screenWidth < 480 ? "19.2px" : undefined,
                        width:
                          screenWidth >= 768 && screenWidth < 960 ? "280px" : screenWidth < 480 ? "180px" : undefined,
                      }}
                    >
                      Придираться к себе
                    </div>
                    {screenWidth < 480 && (
                      <a href="https://teletype.in/@clara.zhikhareva/lFm-z3jy0cI" target="_blank" rel="noopener noreferrer">
                        <button className="btn">
                          <div className="frame-56">
                            <div className="text-wrapper-155">ЧИТАТЬ СТАТЬЮ</div>
                          </div>
                          <img className="frame-79" alt="Frame" src="/img/frame-84-4.svg" />
                        </button>
                      </a>
                    )}

                    {screenWidth >= 768 && screenWidth < 960 && (
                      <a href="https://teletype.in/@clara.zhikhareva/lFm-z3jy0cI" target="_blank" rel="noopener noreferrer">
                        <HierarchyTertiary
                          className="instance-node-2"
                          divClassName="instance-node-3"
                          frame="/img/frame-84-13.svg"
                          frameClassName="hierarchy-tertiary-state-default"
                          text="ЧИТАТЬ СТАТЬЮ"
                        />
                      </a>
                    )}
                  </div>
                  <div
                    className="flexcontainer-8"
                    style={{
                      gap: screenWidth < 480 ? "10px" : screenWidth >= 768 && screenWidth < 960 ? "20px" : undefined,
                    }}
                  >
                    <p
                      className="text-16"
                      style={{
                        fontSize:
                          screenWidth < 480 ? "14px" : screenWidth >= 768 && screenWidth < 960 ? "16px" : undefined,
                        lineHeight:
                          screenWidth < 480 ? "16.8px" : screenWidth >= 768 && screenWidth < 960 ? "19.2px" : undefined,
                      }}
                    >
                      <span
                        className="text-wrapper-156"
                        style={{
                          fontSize:
                            screenWidth < 480 ? "14px" : screenWidth >= 768 && screenWidth < 960 ? "16px" : undefined,
                          lineHeight:
                            screenWidth < 480
                              ? "16.8px"
                              : screenWidth >= 768 && screenWidth < 960
                              ? "19.2px"
                              : undefined,
                        }}
                      >
                        «Лучшая версия себя» — это личная эволюция или способ оправдывать чужие ожидания?
                        <br />
                      </span>
                    </p>
                    <p
                      className="text-17"
                      style={{
                        fontSize:
                          screenWidth < 480 ? "14px" : screenWidth >= 768 && screenWidth < 960 ? "16px" : undefined,
                        lineHeight:
                          screenWidth < 480 ? "16.8px" : screenWidth >= 768 && screenWidth < 960 ? "19.2px" : undefined,
                      }}
                    >
                      <span
                        className="text-wrapper-157"
                        style={{
                          fontSize:
                            screenWidth < 480 ? "14px" : screenWidth >= 768 && screenWidth < 960 ? "16px" : undefined,
                          lineHeight:
                            screenWidth < 480
                              ? "16.8px"
                              : screenWidth >= 768 && screenWidth < 960
                              ? "19.2px"
                              : undefined,
                        }}
                      >
                        Поделилась своим видением того, как мы ломаем сами себе жизнь с помощью освоенного
                        в совершенстве навыка придираться к себе.
                      </span>
                    </p>
                  </div>
                </div>
                <img
                  className="line-11"
                  alt="Line"
                  src={
                    screenWidth < 480
                      ? "/img/line-52-1.svg"
                      : screenWidth >= 768 && screenWidth < 960
                      ? "/img/line-52-4.svg"
                      : undefined
                  }
                />
                <div
                  className="frame-80"
                  style={{
                    gap: screenWidth < 480 ? "10px" : screenWidth >= 768 && screenWidth < 960 ? "20px" : undefined,
                  }}
                >
                  <div className="frame-31">
                    <div
                      className="text-wrapper-158"
                      style={{
                        fontSize:
                          screenWidth >= 768 && screenWidth < 960 ? "22px" : screenWidth < 480 ? "16px" : undefined,
                        lineHeight:
                          screenWidth >= 768 && screenWidth < 960 ? "26.4px" : screenWidth < 480 ? "19.2px" : undefined,
                        width:
                          screenWidth >= 768 && screenWidth < 960 ? "280px" : screenWidth < 480 ? "180px" : undefined,
                      }}
                    >
                      Отложенная жизнь
                    </div>
                    {screenWidth < 480 && (
                      <a href="https://teletype.in/@clara.zhikhareva/2KjbRVfpOMk" target="_blank" rel="noopener noreferrer">
                        <button className="btn">
                          <div className="frame-56">
                            <div className="text-wrapper-155">ЧИТАТЬ СТАТЬЮ</div>
                          </div>
                          <img className="frame-79" alt="Frame" src="/img/frame-84-4.svg" />
                        </button>
                      </a>
                    )}

                    {screenWidth >= 768 && screenWidth < 960 && (
                      <a href="https://teletype.in/@clara.zhikhareva/2KjbRVfpOMk" target="_blank" rel="noopener noreferrer">
                        <HierarchyTertiary
                          className="instance-node-2"
                          divClassName="instance-node-3"
                          frame="/img/frame-84-13.svg"
                          frameClassName="hierarchy-tertiary-state-default"
                          text="ЧИТАТЬ СТАТЬЮ"
                        />
                      </a>
                    )}
                  </div>
                  <div
                    className="flexcontainer-9"
                    style={{
                      gap: screenWidth < 480 ? "10px" : screenWidth >= 768 && screenWidth < 960 ? "20px" : undefined,
                    }}
                  >
                    <p
                      className="text-18"
                      style={{
                        fontSize:
                          screenWidth < 480 ? "14px" : screenWidth >= 768 && screenWidth < 960 ? "16px" : undefined,
                        lineHeight:
                          screenWidth < 480 ? "16.8px" : screenWidth >= 768 && screenWidth < 960 ? "19.2px" : undefined,
                      }}
                    >
                      <span
                        className="text-wrapper-159"
                        style={{
                          fontSize:
                            screenWidth < 480 ? "14px" : screenWidth >= 768 && screenWidth < 960 ? "16px" : undefined,
                          lineHeight:
                            screenWidth < 480
                              ? "16.8px"
                              : screenWidth >= 768 && screenWidth < 960
                              ? "19.2px"
                              : undefined,
                        }}
                      >
                        Как двигаются по жизни по-настоящему счастливые люди? <br />
                      </span>
                    </p>
                    <p
                      className="text-19"
                      style={{
                        fontSize:
                          screenWidth < 480 ? "14px" : screenWidth >= 768 && screenWidth < 960 ? "16px" : undefined,
                        lineHeight:
                          screenWidth < 480 ? "16.8px" : screenWidth >= 768 && screenWidth < 960 ? "19.2px" : undefined,
                      }}
                    >
                      <span
                        className="text-wrapper-160"
                        style={{
                          fontSize:
                            screenWidth < 480 ? "14px" : screenWidth >= 768 && screenWidth < 960 ? "16px" : undefined,
                          lineHeight:
                            screenWidth < 480
                              ? "16.8px"
                              : screenWidth >= 768 && screenWidth < 960
                              ? "19.2px"
                              : undefined,
                        }}
                      >
                        Стремятся ли они поскорее попасть на конечную станцию, не замечая ничего вокруг? Летят ли вперед
                        в погоне за иллюзорными целями? Или же наслаждаются опытом проживания истинной живой Жизни, видя
                        красоту в каждом моменте?
                      </span>
                    </p>
                  </div>
                </div>
                <img
                  className="line-11"
                  alt="Line"
                  src={
                    screenWidth < 480
                      ? "/img/line-52-1.svg"
                      : screenWidth >= 768 && screenWidth < 960
                      ? "/img/line-52-4.svg"
                      : undefined
                  }
                />
                <div
                  className="frame-81"
                  style={{
                    gap: screenWidth < 480 ? "10px" : screenWidth >= 768 && screenWidth < 960 ? "20px" : undefined,
                  }}
                >
                  <div className="frame-31">
                    <p
                      className="text-wrapper-161"
                      style={{
                        fontSize:
                          screenWidth >= 768 && screenWidth < 960 ? "22px" : screenWidth < 480 ? "16px" : undefined,
                        lineHeight:
                          screenWidth >= 768 && screenWidth < 960 ? "26.4px" : screenWidth < 480 ? "19.2px" : undefined,
                        width:
                          screenWidth >= 768 && screenWidth < 960 ? "584px" : screenWidth < 480 ? "180px" : undefined,
                      }}
                    >
                      Мир людей, у которых горят глаза
                    </p>
                    {screenWidth < 480 && (
                      <a href="https://teletype.in/@clara.zhikhareva/RBdi14WjP1z" target="_blank" rel="noopener noreferrer">
                        <button className="btn">
                          <div className="frame-56">
                            <div className="text-wrapper-155">ЧИТАТЬ СТАТЬЮ</div>
                          </div>
                          <img className="frame-79" alt="Frame" src="/img/frame-84-4.svg" />
                        </button>
                      </a>
                    )}

                    {screenWidth >= 768 && screenWidth < 960 && (
                      <a href="https://teletype.in/@clara.zhikhareva/RBdi14WjP1z" target="_blank" rel="noopener noreferrer">
                        <HierarchyTertiary
                          className="hierarchy-tertiary-instance"
                          divClassName="instance-node-3"
                          frame="/img/frame-84-13.svg"
                          frameClassName="hierarchy-tertiary-state-default"
                          text="ЧИТАТЬ СТАТЬЮ"
                        />
                      </a>
                    )}
                  </div>
                  <p
                    className="text-wrapper-162"
                    style={{
                      fontSize:
                        screenWidth < 480 ? "14px" : screenWidth >= 768 && screenWidth < 960 ? "16px" : undefined,
                      lineHeight:
                        screenWidth < 480 ? "16.8px" : screenWidth >= 768 && screenWidth < 960 ? "19.2px" : undefined,
                    }}
                  >
                    Здесь о мире, в котором я хочу жить. И о том, какие качество отличает Живых людей от биороботов,
                    механически двигающихся от задачи к задаче по чужим рельсам.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="view-49"
              style={{
                height: screenWidth < 480 ? "206px" : screenWidth >= 768 && screenWidth < 960 ? "144px" : undefined,
                top: screenWidth < 480 && isDetailsOpen && isAlchemyOpen ? "8450px" :
                screenWidth < 480 && !isDetailsOpen && isAlchemyOpen ? "7250px" :
                screenWidth < 480 && isDetailsOpen && !isAlchemyOpen ? "7500px" :
                screenWidth < 480 && !isDetailsOpen && !isAlchemyOpen ? "6325px"
                : screenWidth >= 768 && screenWidth < 960 && isDetailsOpen && isAlchemyOpen ? "8347px"
                : screenWidth >= 768 && screenWidth < 960 && !isDetailsOpen && isAlchemyOpen ? "6847px"
                : screenWidth >= 768 && screenWidth < 960 && isDetailsOpen && !isAlchemyOpen ? "7727px"
                : "6210px",
                width: screenWidth < 480 ? "320px" : screenWidth >= 768 && screenWidth < 960 ? "726px" : undefined,
              }}
            >
              <div
                className="view-50"
                style={{
                  height: screenWidth < 480 ? "58px" : screenWidth >= 768 && screenWidth < 960 ? "50px" : undefined,
                  width: screenWidth < 480 ? "322px" : screenWidth >= 768 && screenWidth < 960 ? "633px" : undefined,
                }}
              >
                <div
                  className="text-wrapper-163"
                  style={{
                    fontSize: screenWidth < 480 ? "24px" : screenWidth >= 768 && screenWidth < 960 ? "42px" : undefined,
                    lineHeight:
                      screenWidth < 480 ? "28.8px" : screenWidth >= 768 && screenWidth < 960 ? "50.4px" : undefined,
                    whiteSpace: screenWidth >= 768 && screenWidth < 960 ? "nowrap" : undefined,
                    width: screenWidth < 480 ? "320px" : undefined,
                  }}
                >
                  ПОЗНАКОМИТЬСЯ ПОБЛИЖЕ
                </div>
              </div>
              <div
                className="btns-3"
                style={{
                  alignItems:
                    screenWidth < 480 ? "flex-start" : screenWidth >= 768 && screenWidth < 960 ? "center" : undefined,
                  flexDirection: screenWidth < 480 ? "column" : undefined,
                  gap: screenWidth < 480 ? "16px" : screenWidth >= 768 && screenWidth < 960 ? "20px" : undefined,
                  justifyContent: screenWidth < 480 ? "center" : undefined,
                  top: screenWidth < 480 ? "78px" : screenWidth >= 768 && screenWidth < 960 ? "80px" : undefined,
                  width: screenWidth < 480 ? "320px" : screenWidth >= 768 && screenWidth < 960 ? "726px" : undefined,
                }}
              >
                <a href="https://t.me/wakeuptoyourlife" target="_blank" rel="noopener noreferrer">
                  <HierarchySecondary
                    className={`${screenWidth < 480 && "class-14"} ${
                      screenWidth >= 768 && screenWidth < 960 && "class-15"
                    }`}
                    divClassName={`${screenWidth < 480 && "class-13"} ${
                      screenWidth >= 768 && screenWidth < 960 && "class-5"
                    }`}
                    text={
                      screenWidth < 480
                        ? "ТЕЛЕГРАМ"
                        : screenWidth >= 768 && screenWidth < 960
                        ? "ТЕЛЕГРАМ-КАНАЛ"
                        : undefined
                    }
                    visible={false}
                    preElement="telega"
                  />
                </a>
                <a href="https://www.instagram.com/clara.zhikhareva/" target="_blank" rel="noopener noreferrer">
                  <HierarchySecondary
                    className={`${screenWidth < 480 && "class-14"} ${
                      screenWidth >= 768 && screenWidth < 960 && "class-15"
                    }`}
                    divClassName={`${screenWidth < 480 && "class-13"} ${
                      screenWidth >= 768 && screenWidth < 960 && "class-5"
                    }`}
                    text="ИНСТАГРАМ"
                    visible={false}
                    preElement="insta"
                  />
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
