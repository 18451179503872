/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const HierarchyTertiary = ({
  className,
  frameClassName,
  divClassName,
  text = "НАЧАТЬ ИССЛЕДОВАНИЕ",
  frame = "/img/frame-84.svg",
}) => {
  return (
    <div className={`hierarchy-tertiary ${className}`}>
      <div className={`frame-11 ${frameClassName}`}>
        <div className={`text-wrapper-12 ${divClassName}`}>{text}</div>
      </div>
      <img className="frame-12" alt="Frame" src={frame} />
    </div>
  );
};

HierarchyTertiary.propTypes = {
  text: PropTypes.string,
  frame: PropTypes.string,
};
