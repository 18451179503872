// Generated from SVG to Code Figma Plugin
import React from "react";
    
export const Insta = (props) => (
<svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g clipPath="url(#clip0_118_1658)">
		<path d="M17.3333 0C19.1014 0 20.7971 0.702379 22.0474 1.95262C23.2976 3.20286 24 4.89856 24 6.66667V17.3333C24 19.1014 23.2976 20.7971 22.0474 22.0474C20.7971 23.2976 19.1014 24 17.3333 24H6.66667C4.89856 24 3.20286 23.2976 1.95262 22.0474C0.702379 20.7971 0 19.1014 0 17.3333V6.66667C0 4.89856 0.702379 3.20286 1.95262 1.95262C3.20286 0.702379 4.89856 0 6.66667 0H17.3333ZM17.3333 2.66667H6.66667C5.6058 2.66667 4.58839 3.08809 3.83824 3.83824C3.08809 4.58839 2.66667 5.6058 2.66667 6.66667V17.3333C2.66667 18.3942 3.08809 19.4116 3.83824 20.1618C4.58839 20.9119 5.6058 21.3333 6.66667 21.3333H17.3333C18.3942 21.3333 19.4116 20.9119 20.1618 20.1618C20.9119 19.4116 21.3333 18.3942 21.3333 17.3333V6.66667C21.3333 5.6058 20.9119 4.58839 20.1618 3.83824C19.4116 3.08809 18.3942 2.66667 17.3333 2.66667ZM12 6.66667C13.4145 6.66667 14.771 7.22857 15.7712 8.22876C16.7714 9.22896 17.3333 10.5855 17.3333 12C17.3333 13.4145 16.7714 14.771 15.7712 15.7712C14.771 16.7714 13.4145 17.3333 12 17.3333C10.5855 17.3333 9.22896 16.7714 8.22876 15.7712C7.22857 14.771 6.66667 13.4145 6.66667 12C6.66667 10.5855 7.22857 9.22896 8.22876 8.22876C9.22896 7.22857 10.5855 6.66667 12 6.66667ZM12 9.33333C11.2928 9.33333 10.6145 9.61429 10.1144 10.1144C9.61429 10.6145 9.33333 11.2928 9.33333 12C9.33333 12.7072 9.61429 13.3855 10.1144 13.8856C10.6145 14.3857 11.2928 14.6667 12 14.6667C12.7072 14.6667 13.3855 14.3857 13.8856 13.8856C14.3857 13.3855 14.6667 12.7072 14.6667 12C14.6667 11.2928 14.3857 10.6145 13.8856 10.1144C13.3855 9.61429 12.7072 9.33333 12 9.33333ZM18 4.66667C18.3536 4.66667 18.6928 4.80714 18.9428 5.05719C19.1929 5.30724 19.3333 5.64638 19.3333 6C19.3333 6.35362 19.1929 6.69276 18.9428 6.94281C18.6928 7.19286 18.3536 7.33333 18 7.33333C17.6464 7.33333 17.3072 7.19286 17.0572 6.94281C16.8071 6.69276 16.6667 6.35362 16.6667 6C16.6667 5.64638 16.8071 5.30724 17.0572 5.05719C17.3072 4.80714 17.6464 4.66667 18 4.66667Z" fill="white" fillRule="evenodd"/>
	</g>
	<defs>
		<clipPath id="clip0_118_1658">
			<rect height="24" width="24" fill="white"/>
		</clipPath>
	</defs>
</svg>
);