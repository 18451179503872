// Generated from SVG to Code Figma Plugin
import React from "react";
    
export const Telega = (props) => (
<svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g clipPath="url(#clip0_118_1814)">
		<path d="M23.9762 4.13292C24.0282 3.8092 23.9946 3.47709 23.8788 3.1712C23.763 2.86531 23.5692 2.59683 23.3176 2.39374C23.0661 2.19065 22.766 2.06037 22.4485 2.01649C22.1311 1.9726 21.808 2.01671 21.5129 2.14422L1.09495 10.9503C-0.328337 11.5643 -0.401173 13.6728 1.09495 14.3088C2.5764 14.9417 4.07945 15.5201 5.60122 16.0431C6.99585 16.5152 8.51704 16.9518 9.73615 17.0754C10.0693 17.4839 10.4884 17.8777 10.9159 18.2373C11.569 18.7877 12.3547 19.3539 13.1666 19.897C14.7929 20.9855 16.597 22.0263 17.8126 22.7039C19.2657 23.5111 21.0054 22.5816 21.2657 20.9586L23.9762 4.13292ZM3.3839 12.6173L21.4424 4.82885L18.9135 20.533C17.7206 19.8689 16.0024 18.8745 14.4717 17.8496C13.7662 17.3856 13.085 16.8839 12.4311 16.3464C12.2563 16.2002 12.0866 16.0476 11.9224 15.889L16.652 11.0457C16.876 10.8163 17.002 10.5052 17.0021 10.1808C17.0022 9.85635 16.8765 9.54515 16.6526 9.31565C16.4287 9.08616 16.125 8.95716 15.8082 8.95705C15.4915 8.95693 15.1877 9.08571 14.9636 9.31504L9.7851 14.6195C8.90869 14.5045 7.68839 14.1718 6.34868 13.7193C5.3513 13.379 4.36308 13.0111 3.3851 12.6161L3.3839 12.6173Z" fill="white" fillRule="evenodd"/>
	</g>
	<defs>
		<clipPath id="clip0_118_1814">
			<rect height="24" width="24" fill="white"/>
		</clipPath>
	</defs>
</svg>
);