import React from "react";
    
export const LittleWhiteKey = (props) => (
<svg height="28" width="37" fill="none" viewBox="0 0 37 28" xmlns="http://www.w3.org/2000/svg">
	<path d="M10.6133 21.1294C11.7381 20.0224 11.7288 18.2184 10.5925 17.1001C9.45626 15.9818 7.62334 15.9726 6.49853 17.0797C5.37373 18.1867 5.38301 19.9907 6.51927 21.109C7.65552 22.2273 9.48845 22.2365 10.6133 21.1294Z" stroke="white" strokeMiterlimit="10" strokeWidth="2"/>
	<path d="M14.7265 25.1802C15.8513 24.0731 15.8421 22.2692 14.7058 21.1509C13.5695 20.0325 11.7366 20.0234 10.6118 21.1304C9.48701 22.2375 9.49629 24.0415 10.6325 25.1598C11.7688 26.2781 13.6017 26.2872 14.7265 25.1802Z" stroke="white" strokeMiterlimit="10" strokeWidth="2"/>
	<path d="M9.14571 25.6024C9.98681 24.7746 9.97987 23.4256 9.1302 22.5893C8.28058 21.7531 6.90995 21.7463 6.06885 22.5741C5.22774 23.4019 5.23468 24.7509 6.0843 25.5871C6.93397 26.4234 8.3046 26.4302 9.14571 25.6024Z" stroke="white" strokeMiterlimit="10" strokeWidth="2"/>
	<path d="M10.7387 21.0033L30.4141 1.63867" stroke="white" strokeMiterlimit="10" strokeWidth="2"/>
	<path d="M25.7793 6.20508L28.7857 9.16402" stroke="white" strokeMiterlimit="10" strokeWidth="2"/>
	<path d="M23.0293 8.90918L27.0251 12.8419" stroke="white" strokeMiterlimit="10" strokeWidth="2"/>
	<path d="M28.5273 3.50195L32.5232 7.4347" stroke="white" strokeMiterlimit="10" strokeWidth="2"/>
	<path d="M13.4473 14.7168L17.1281 18.3395" stroke="white" strokeMiterlimit="10" strokeWidth="2"/>
</svg>
);