/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import { LittleWhiteKey } from "../../../static/img/LittleWhiteKey";
import { ArrowTopRight } from "../../../static/img/ArrowTopRight";
import { Telega } from "../../../static/img/Telega";
import { Insta } from "../../../static/img/Insta";

export const HierarchySecondary = ({
  className,
  divClassName,
  visible = true,
  text = "НАЧАТЬ ИССЛЕДОВАНИЕ",
  frameClassName,
  onClick = () => {},
  element = 'key',
  preElement
}) => {
  return (
    <div className={`hierarchy-secondary ${className}`} onClick={() => onClick('alchemy')}>
      <div className={`frame ${frameClassName}`}>
        {preElement === 'telega' && <Telega />}
        {preElement === 'insta' && <Insta />}
        <div className={`text-wrapper ${divClassName}`}>{text}</div>
      </div>
      {visible && element == 'key' ? <LittleWhiteKey className="arrow-instance" /> : <ArrowTopRight className="arrow-instance" />}
    </div>
  );
};

HierarchySecondary.propTypes = {
  visible: PropTypes.bool,
  text: PropTypes.string,
};
