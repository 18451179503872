/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const MenuIcon = ({ property1, className, rectangleClassName, rectangleClassNameOverride, divClassName }) => {
  return (
    <div className={`menu-icon ${className}`}>
      {property1 === "menu-burger" && (
        <>
          <div className={`rectangle ${rectangleClassName}`} />
          <div className={`div ${rectangleClassNameOverride}`} />
          <div className={`rectangle-2 ${divClassName}`} />
        </>
      )}

      {property1 === "close" && <img className="union" alt="Union" src="/img/union-12.svg" />}
    </div>
  );
};

MenuIcon.propTypes = {
  property1: PropTypes.oneOf(["menu-burger", "close"]),
};
