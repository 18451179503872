/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Bullet = ({
  className,
  divClassName,
  divClassNameOverride,
  divClassName1,
  divClassName2,
  line = "/img/line-8.svg",
  text = "МГУ им. М.В. Ломоносова",
  text1 = "Нейрофизиология",
  text2 = "бакалавр, 2013–2017 гг.",
  text3 = "красный диплом",
}) => {
  return (
    <div className={`bullet ${className}`}>
      <div className="frame-8">
        <div className="frame-9">
          <div className={`text-wrapper-9 ${divClassName}`}>{text}</div>
          <div className={`text-wrapper-10 ${divClassNameOverride}`}>{text1}</div>
        </div>
        <div className="frame-10">
          <div className={`element-5 ${divClassName1}`}>{text2}</div>
          <div className={`text-wrapper-11 ${divClassName2}`}>{text3}</div>
        </div>
      </div>
      <img className="line-5" alt="Line" src={line} />
    </div>
  );
};

Bullet.propTypes = {
  line: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
};
