// Generated from SVG to Code Figma Plugin
import React from "react";
    
export const ArrowTopRight = (props) => (
<svg height="24" width="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g clipPath="url(#clip0_118_1674)">
		<line stroke="white" strokeWidth="2" x1="6" x2="24" y1="1" y2="0.999998"/>
		<line stroke="white" strokeWidth="2" x1="23" x2="23" y1="18" y2="1"/>
		<line stroke="white" strokeWidth="2" x1="3.06199" x2="22.312" y1="19.5243" y2="1.27429"/>
	</g>
	<defs>
		<clipPath id="clip0_118_1674">
			<rect height="24" width="24" fill="white"/>
		</clipPath>
	</defs>
</svg>
);