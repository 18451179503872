/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const Header = ({ className, style, onTabClick }) => {
  return (
    <div className={`header ${className}`} style={{...style}}>
      <div className="tabs">
        <div className="text-wrapper-13" onClick={() => onTabClick('about')}>Обо мне</div>
        <div className="text-wrapper-13" onClick={() => onTabClick('education')}>Образование</div>
        <div className="text-wrapper-13" onClick={() => onTabClick('alchemy')}>Алхимия трансформации</div>
        <div className="text-wrapper-13" onClick={() => onTabClick('formats')}>Форматы взаимодействия</div>
        <div className="text-wrapper-13" onClick={() => onTabClick('materials')}>Материалы</div>
        <div className="text-wrapper-13" onClick={() => onTabClick('contacts')}>Контакты</div>
      </div>
    </div>
  );
};
