/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const EducationCellWrapper = ({
  className,
  text = "МГУ им. М.В. Ломоносова",
  text1 = "Нейрофизиология",
  text2 = "бакалавр, <br/>2013–2017гг.",
  text3 = "красный диплом",
}) => {
  return (
    <div className={`education-cell-wrapper ${className}`}>
      <div className="frame-5">
        <div className="frame-6">
          <div className="text-wrapper-6">{text}</div>
          <div className="text-wrapper-7">{text1}</div>
        </div>
        <div className="frame-7">
          <div className="element-4">{text2}</div>
          <div className="text-wrapper-8">{text3}</div>
        </div>
      </div>
      <img className="line-4" alt="Line" src="/img/line-8-41.svg" />
    </div>
  );
};

EducationCellWrapper.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
};
